export const estimatedDistanceChart = 'Estimated Distance Chart'
export const ciInterval = 'CI Interval'
export const polesCount = 'Poles Count'
export const avgDistance = 'AVG distance'
export const avgPoleHeight = 'AVG Pole Height'
export const polesWithStrands = 'Poles with Strands'
export const polesWithEquipment = 'Poles with Equipment'

export const chartTypes = {
  estimatedDistanceChart,
  ciInterval,
  polesCount,
  // avgDistance,
  avgPoleHeight,
  polesWithStrands,
  polesWithEquipment,
}

export const chartTypesArray = [
  estimatedDistanceChart,
  ciInterval,
  polesCount,
  // avgDistance,
  avgPoleHeight,
  polesWithStrands,
  polesWithEquipment,
]

export const realMapping = {
  [estimatedDistanceChart]: 'distanceChartData',
  [ciInterval]: 'ciChartData',
  [polesCount]: 'polesChartData',
  // [avgDistance]: '',
  [avgPoleHeight]: 'avgPoleHeightChartData',
  [polesWithStrands]: 'polesWithStrandsChartData',
  [polesWithEquipment]: 'polesWithEquipmentsChartData',
}
