import React from 'react'
import {
  Avatar,
  Box,
  Button,
  CircularProgress,
  FormControl,
  IconButton,
  InputAdornment,
  InputLabel,
  MenuItem,
  Select,
  Stack,
  TextField,
  Typography,
} from '@mui/material'
import NotificationIcon from '../assets/svgs/notification.svg'
import { ProtectedComponent } from '../components/protected_route'
import { authSelector, logout } from '../store/auth'
import { useNavigate } from 'react-router-dom'
import { useAppDispatch, useAppSelector } from '../store/hooks'
import { Country } from 'country-state-city'
import { Visibility, VisibilityOff } from '@mui/icons-material'
import { ReactComponent as EditIcon } from '../assets/svgs/edit_icon.svg'
import {
  useCurrentUserQuery,
  useUpdateUserMutation,
} from '../services/user_service'
import { errorToast, successToast, warningToast } from '../utils/toast'
import { type UpdateUser } from '../types/user'
import { isValidEmail } from '../utils/functions'
import { timeZones } from '../utils/timezone_list'

const Profile: React.FC = () => {
  const auth = useAppSelector(authSelector)
  const dispatch = useAppDispatch()
  const navigate = useNavigate()
  const [showPassword, setShowPassword] = React.useState(false)
  const [showNewPassword, setShowNewPassword] = React.useState(false)
  const [callUpdateUser, { isLoading: updating }] = useUpdateUserMutation()
  // eslint-disable-next-line
  const { data, isLoading, refetch } = useCurrentUserQuery({})
  const [fullName, setFullName] = React.useState(
    data != null ? `${data?.first_name} ${data?.last_name}` : ''
  )
  const [email, setEmail] = React.useState(data != null ? data?.email : '')
  const [phone, setPhone] = React.useState(
    data?.phone !== null ? data?.phone : ''
  )
  const [state, setState] = React.useState(
    data?.is_active === true ? 'active' : 'inactive'
  )
  const [timezone, setTimezone] = React.useState(
    data != null ? data?.timezone : ''
  )
  const [defaultLocation, setDefaultLocation] = React.useState(
    data != null ? data?.default_location : ''
  )
  const [password, setPassword] = React.useState('')
  const [newPassword, setNewPassword] = React.useState('')

  React.useEffect(() => {
    setFullName(data != null ? `${data?.first_name} ${data?.last_name}` : '')
    setEmail(data != null ? data?.email : '')
    setPhone(data?.phone !== null ? data?.phone : '')
    setState(data?.is_active === true ? 'active' : 'inactive')
    setTimezone(data != null ? data?.timezone : '')
    setDefaultLocation(data != null ? data?.default_location : '')
  }, [data])

  const updateProfile = () => {
    // check if profile or password is changed
    if (
      fullName !== `${data?.first_name} ${data?.last_name}` ||
      email !== data?.email ||
      phone !== data?.phone ||
      state !== data?.state ||
      timezone !== data?.timezone ||
      defaultLocation !== data?.default_location ||
      password !== ''
    ) {
      // check if password is changed it matches the required length and matches the confirm password
      if (password !== '' && password.length < 8) {
        warningToast('Password must be at least 8 characters')
        return
      } else if (newPassword === password) {
        warningToast('Nothings changes in password!')
        return
      }

      // check if email is changed and is valid
      if (email !== data?.email && !isValidEmail(email)) {
        warningToast('Invalid email address')
        return
      }

      // create UpdateUser object and only keep the fields that have been changed
      const updateUser: UpdateUser = {}
      if (fullName !== `${data?.first_name} ${data?.last_name}`) {
        updateUser.first_name = fullName.split(' ')[0]
        updateUser.last_name = fullName.split(' ')[1]
      }
      // if (email !== data?.email) {
      //   updateUser.email = email
      // }
      // if (phone !== '') {
      //   updateUser.phone = phone
      // }
      if (state !== data?.state) {
        updateUser.state = state
      }
      if (timezone !== data?.timezone) {
        updateUser.timezone = timezone
      }
      if (defaultLocation !== data?.default_location) {
        updateUser.default_location = defaultLocation
      }
      if (phone !== data?.phone) {
        updateUser.phone = phone
      }
      if (password !== '') {
        updateUser.old_password = password
        updateUser.new_password = newPassword
      }
      callUpdateUser(updateUser)
        .unwrap()
        .then((data) => {
          refetch()
            .then(() => {})
            .catch(() => {})
          successToast('Profile updated successfully')
        })
        .catch((err) => {
          const errMsg = err != null && 'data' in err ? err.data : {}
          const msg = errMsg?.detail ?? 'Some error occurred'

          errorToast(`${msg}`)
        })
    } else {
      warningToast('No changes made')
    }
  }

  if (isLoading) {
    return (
      <Box
        display="flex"
        justifyContent="center"
        alignItems="center"
        className="h-screen"
      >
        <CircularProgress />
      </Box>
    )
  }

  return (
    <Box
      display="flex"
      flexDirection="column"
      sx={{ overflow: 'hidden', height: '93vh' }}
    >
      <Box display="flex" flex={2}>
        <Box flex={6} display="flex" flexDirection="row" justifyContent="start">
          <Typography
            sx={{
              fontSize: '24px',
              fontWeight: 400,
              lineHeight: '33.4px',
              color: 'white.200',
            }}
          >
            Profile
          </Typography>
        </Box>
        <Box
          flex={3}
          display="flex"
          flexDirection="column"
          alignContent="stretch"
        ></Box>
        <Box flex={1.4}>
          <Button
            variant="contained"
            sx={{
              textTransform: 'none',
              borderRadius: '8px',
              color: 'textColor.300',
              bgcolor: 'bg.300',
            }}
            onClick={() => {
              dispatch(logout())
              navigate('/login')
            }}
          >
            Logout
          </Button>
        </Box>
        <Box
          flex={0.5}
          display="flex"
          flexDirection="column"
          justifyContent="start"
          alignItems="center"
        >
          <IconButton
            sx={{
              borderRadius: '8px',
              backgroundColor: 'bg.200',
            }}
          >
            <img src={NotificationIcon} />
          </IconButton>
        </Box>
      </Box>
      <Box
        flex={25}
        display="flex"
        flexDirection="row"
        sx={{
          overflowY: 'scroll',
          maxHeight: '90vh',
        }}
      >
        <Box flex={6}>
          <Stack
            bgcolor="bg.200"
            borderRadius="20px"
            direction="column"
            justifyContent="center"
            alignItems="stretch"
            spacing="20px"
            padding="24px 32px"
          >
            <Typography textAlign="start" pb="15px">
              General Info
            </Typography>
            <Box
              sx={{
                display: 'flex',
                flexDirection: 'row',
                alignContent: 'center',
                height: '103px',
              }}
            >
              <Box
                sx={{
                  width: '83px',
                  height: '83px',
                  border: '2px solid',
                  borderStyle: 'dashed',
                  borderColor: 'stroke.main',
                  strokeWidth: '5px',
                  borderRadius: '12px',
                  p: '1px',
                }}
              >
                <Box
                  sx={{
                    display: 'flex',
                    flexDirection: 'column',
                    justifyContent: 'center',
                    width: '78px',
                    height: '78px',
                    bgcolor: 'bg.300',
                    borderRadius: '12px',
                  }}
                >
                  <Typography
                    sx={{
                      textAlign: 'center',
                      fontSize: '18px',
                      fontWeight: 400,
                      lineHeight: '24.6px',
                      color: 'white.main',
                    }}
                  >
                    {auth.userData != null
                      ? `${auth.userData?.first_name.charAt(0)}${auth.userData?.last_name.charAt(0)}`
                      : 'NA'}
                  </Typography>
                </Box>
              </Box>
              <Box
                sx={{
                  // ml: '-10px',
                  // mt: '-100px',
                  display: 'flex',
                  flexDirection: 'row',
                  alignItems: 'end',
                }}
              >
                <Avatar
                  alt="edit"
                  sx={{
                    width: 40,
                    height: 40,
                    ml: '-20px',
                    bgcolor: 'bg.300',
                    border: '2px solid',
                    borderColor: 'stroke.main',
                  }}
                >
                  <EditIcon />
                </Avatar>
              </Box>
            </Box>
            <TextField
              label="Full name"
              variant="outlined"
              size="small"
              value={fullName}
              error={false}
              onChange={(e) => {
                setFullName(e.target.value)
              }}
            />
            <TextField
              label="Email"
              variant="outlined"
              size="small"
              value={email}
              error={false}
              onChange={(e) => {
                setEmail(e.target.value)
              }}
            />
            <TextField
              label="Phone"
              variant="outlined"
              size="small"
              value={phone}
              error={false}
              onChange={(e) => {
                setPhone(e.target.value)
              }}
            />
            <FormControl error={false} fullWidth size="small">
              <InputLabel id="state-label">State</InputLabel>
              <Select
                labelId="state-label"
                id="state-type"
                label="State"
                value={state}
                onChange={(event) => {
                  setState(event.target.value)
                }}
              >
                <MenuItem value={'active'}>Active</MenuItem>
                <MenuItem value={'inactive'}>Inactive</MenuItem>
              </Select>
            </FormControl>
            <FormControl error={false} fullWidth size="small">
              <InputLabel id="timezone-label">Time Zone</InputLabel>
              <Select
                labelId="timezone-label"
                id="timezone-type"
                label="Time Zone"
                value={timezone}
                onChange={(event) => {
                  setTimezone(event.target.value)
                }}
              >
                {timeZones.map((timezone) => (
                  <MenuItem key={timezone.abbr} value={timezone.abbr}>
                    {timezone.value}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
            <FormControl error={false} fullWidth size="small">
              <InputLabel id="default-loc-label">Default Location</InputLabel>
              <Select
                labelId="default-loc-label"
                id="default-loc"
                label="Default Location"
                value={defaultLocation}
                onChange={(event) => {
                  setDefaultLocation(event.target.value)
                }}
              >
                {Country.getAllCountries().map((country) => (
                  <MenuItem key={country.isoCode} value={country.isoCode}>
                    {country.name}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
            <Typography textAlign="start" pb="15px" mt="30px">
              Password
            </Typography>
            <TextField
              label="Current password"
              variant="outlined"
              size="small"
              placeholder="Current password"
              sx={{ mb: 2 }}
              value={password}
              error={false}
              helperText={
                showPassword ? 'Password must be at least 8 characters' : ''
              }
              onChange={(e) => {
                setPassword(e.target.value)
              }}
              type={showPassword ? 'text' : 'password'}
              InputProps={{
                endAdornment: (
                  <InputAdornment position="end">
                    <IconButton
                      aria-label="toggle password visibility"
                      onClick={() => {
                        setShowPassword((prev) => !prev)
                      }}
                      edge="end"
                    >
                      {showPassword ? (
                        <VisibilityOff
                          sx={{ color: 'textColor.main', fontSize: 15 }}
                        />
                      ) : (
                        <Visibility
                          sx={{ color: 'textColor.main', fontSize: 15 }}
                        />
                      )}
                    </IconButton>
                  </InputAdornment>
                ),
              }}
            />
            <TextField
              label="New password"
              variant="outlined"
              size="small"
              placeholder="Enter New password"
              value={newPassword}
              sx={{ mb: 2 }}
              error={false}
              helperText={
                showNewPassword ? 'Password must be at least 8 characters' : ''
              }
              onChange={(e) => {
                setNewPassword(e.target.value)
              }}
              type={showNewPassword ? 'text' : 'password'}
              InputProps={{
                endAdornment: (
                  <InputAdornment position="end">
                    <IconButton
                      aria-label="toggle password visibility"
                      onClick={() => {
                        setShowNewPassword((prev) => !prev)
                      }}
                      edge="end"
                    >
                      {showNewPassword ? (
                        <VisibilityOff
                          sx={{ color: 'textColor.main', fontSize: 15 }}
                        />
                      ) : (
                        <Visibility
                          sx={{ color: 'textColor.main', fontSize: 15 }}
                        />
                      )}
                    </IconButton>
                  </InputAdornment>
                ),
              }}
            />
            <Box
              display="flex"
              flexDirection="row"
              justifyContent="end"
              pt="10px"
              pb="20px"
            >
              <Button
                variant="contained"
                sx={{
                  bgcolor: 'bg.300',
                  textTransform: 'none',
                  borderRadius: '8px',
                  color: 'textColor.300',
                  minWidth: '109px',
                }}
                onClick={updateProfile}
              >
                {updating ? <CircularProgress /> : 'Update profile'}
              </Button>
            </Box>
          </Stack>
        </Box>
        <Box flex={4} />
      </Box>
    </Box>
  )
}

export const ProfileView: React.FC = () => {
  return (
    <ProtectedComponent showSidebarOptions>
      <Profile />
    </ProtectedComponent>
  )
}
