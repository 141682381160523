import React, { type FC } from 'react'
import { ProtectedComponent } from '../components/protected_route'
import {
  Box,
  Button,
  CircularProgress,
  IconButton,
  Typography,
} from '@mui/material'
import NotificationIcon from '../assets/svgs/notification.svg'
import OutputTab, { type OutputHandle } from '../components/output_tab'
import InputTab from '../components/input_tab'
// import { ReactComponent as DDIcon } from '../assets/svgs/dropdown_icon.svg'
import { useNavigate } from 'react-router-dom'
import { useAppDispatch, useAppSelector } from '../store/hooks'
import {
  newProjectSelector,
  setProjectId,
  setProjectName,
} from '../store/new_project'
import WestIcon from '@mui/icons-material/West'
import GenerateReportDialog from '../components/gen_report_dialog'
import {
  useGetProjectQuery,
  useTriggerFileMutation,
} from '../services/project_services'
import { errorToast, successToast } from '../utils/toast'
import { useQuery } from '../utils/parsers'
import { validValue } from '../utils/functions'
import { reset } from '../store/gen_report'

const RunningProject: React.FC = () => {
  const query = useQuery()
  const projectId = query.get('project')
  const navigate = useNavigate()
  const outputTab = React.useRef<OutputHandle>()
  const dispatch = useAppDispatch()
  const newProject = useAppSelector(newProjectSelector)
  const [isInput, setIsInput] = React.useState(!validValue(query.get('output')))
  const [openGenRep, setOpenGenRep] = React.useState(false)
  // eslint-disable-next-line
  const [isReportGenerated, setIsReportGenerated] = React.useState(false)
  const {
    data: projectInfo,
    // eslint-disable-next-line
    error,
    // eslint-disable-next-line
    isLoading,
    refetch,
  } = useGetProjectQuery(projectId?.toString() ?? '')
  const [triggerCall, { isLoading: triggerLoading }] = useTriggerFileMutation()

  React.useEffect(() => {
    if (projectId == null) {
      setTimeout(() => {
        navigate('/projects')
      }, 500)
    }
  }, [projectId])

  const onGenerateReport = () => {
    setOpenGenRep(false)
    outputTab.current?.downloadReport()
    // setIsReportGenerated(true)
  }

  // const onReportDownload = React.useCallback(() => {
  //   setIsReportGenerated(false)
  // }, [])

  const StartRun = async () => {
    await refetch()
    if (
      projectInfo?.files === undefined ||
      projectInfo?.files === null ||
      !validValue(projectInfo?.files[0]?.id?.toString())
    ) {
      errorToast('Unable to fetch project details')
      return
    }
    const fileId = projectInfo?.files[0].id ?? -1
    triggerCall(+fileId)
      .unwrap()
      .then(() => {
        successToast('File queued for processing')
        setTimeout(() => {
          navigate(`/run_analysis?project=${projectId}`)
        }, 500)
      })
      .catch((err) => {
        const errMsg = err != null && 'data' in err ? err.data : {}
        const msg = errMsg?.detail ?? 'Some error occurred'

        errorToast(`${msg}`)
      })
  }

  const onBack = () => {
    if (newProject.selectedFile == null) {
      navigate('/projects')
    } else {
      navigate(-1)
    }
  }

  if (newProject.projectId == null || newProject.projectName == null) {
    dispatch(setProjectId(projectId ?? ''))
    dispatch(setProjectName(projectInfo?.name ?? ''))
  }

  return (
    <Box
      display="flex"
      flexDirection="column"
      className="h-screen"
      sx={{
        overflow: 'hidden',
        height: '93vh',
        px: {
          xs: '15px',
          sm: '18px',
          md: '22px',
          lg: '26px',
        },
      }}
    >
      <Box
        display="flex"
        flex={{
          xs: 1,
          sm: 1.2,
          md: 1.8,
          lg: 2,
          xl: 3,
        }}
        flexDirection="row"
        alignItems="stretch"
        sx={{
          maxHeight: {
            md: '38px',
            lg: '43px',
          },
          mb: {
            md: '5px',
            lg: '8px',
          },
        }}
      >
        <Box flex={6} display="flex" flexDirection="row" justifyContent="start">
          <Box
            flex={0.6}
            minWidth="40px"
            bgcolor="bg.200"
            borderRadius="8px"
            display="flex"
            flexDirection="column"
            justifyContent="center"
            alignItems="center"
            py="8px"
            onClick={onBack}
          >
            <WestIcon sx={{ color: 'textColor.300', fontSize: '20px' }} />
          </Box>
          <Box flex={0.15} />
          <Box
            flex={3}
            display="flex"
            flexDirection="row"
            justifyContent="start"
            alignItems="center"
            maxWidth={{
              xs: '8px',
              sm: '100px',
              md: '140px',
              lg: '180px',
              xl: '300px',
            }}
          >
            <Typography
              noWrap
              sx={{
                fontSize: {
                  xs: '16px',
                  sm: '18px',
                  md: '22px',
                  lg: '24px',
                  xl: '28px',
                },
                pl: '4px',
                fontWeight: 400,
                lineHeight: '33.4px',
                color: 'white.200',
                maxLines: 1,
                textOverflow: 'ellipsis',
              }}
            >
              {!validValue(projectInfo?.name) ? 'Project1' : projectInfo?.name}
            </Typography>
          </Box>
          <Box
            flex={7}
            display="flex"
            flexDirection="row"
            justifyContent="start"
            alignItems="center"
          >
            <Button
              variant="contained"
              sx={{
                mr: '15px',
                height: '100%',
                fontSize: '14px',
                fontWeight: 400,
                lineHeight: '19.6px',
                color: isInput ? 'white.main' : 'textColor.300',
                bgcolor: isInput ? 'bg.300' : 'transparent',
                textTransform: 'none',
                borderRadius: '8px',
              }}
              onClick={() => {
                setIsInput(true)
              }}
            >
              Input
            </Button>
            <Button
              variant="contained"
              sx={{
                height: '100%',
                fontSize: '14px',
                fontWeight: 400,
                lineHeight: '19.6px',
                color: !isInput ? 'white.main' : 'textColor.300',
                bgcolor: !isInput ? 'bg.300' : 'transparent',
                textTransform: 'none',
                borderRadius: '8px',
              }}
              onClick={() => {
                setIsInput(false)
              }}
            >
              Output
            </Button>
          </Box>
        </Box>
        {/* <Box flex={3} display="flex" flexDirection="column" alignItems="end">
          <Box
            bgcolor="bg.300"
            sx={{
              width: '186px',
              height: '40px',
              borderRadius: '8px',
              display: 'flex',
              flexDirection: 'row',
              justifyContent: 'space-between',
              alignItems: 'center',
              px: '10px',
            }}
          >
            <Typography
              sx={{
                fontSize: '14px',
                fontWeight: 400,
                color: 'textColor.300',
                textAlign: 'center',
              }}
            >
              No Runs Available
            </Typography>
            <DDIcon />
          </Box>
        </Box> */}
        <Box
          flex={1.4}
          display="flex"
          flexDirection="row"
          alignItems="center"
          justifyContent="flex-end"
        >
          {triggerLoading ? (
            <CircularProgress />
          ) : (
            <Button
              variant="contained"
              sx={{
                height: '100%',
                borderRadius: '8px',
                backgroundColor: 'bg.300',
                color: 'textColor.300',
                textTransform: 'none',
                fontSize: {
                  md: isInput ? '14px' : '12px',
                  lg: '14px',
                },
                fontWeight: 400,
                lineHeight: '19.6px',
              }}
              onClick={() => {
                StartRun()
                  .then((r) => {})
                  .catch(() => {})
              }}
            >
              Start Run
            </Button>
          )}
        </Box>
        {!isInput ? (
          <Box
            flex={1.8}
            display="flex"
            flexDirection="row"
            justifyContent="center"
          >
            <Button
              variant="contained"
              sx={{
                height: '100%',
                borderRadius: '8px',
                textTransform: 'none',
                fontSize: {
                  md: isInput ? '14px' : '12px',
                  lg: '14px',
                },
                fontWeight: 400,
                lineHeight: '19.6px',
                mr: '5px',
              }}
              onClick={() => {
                dispatch(reset())
                setOpenGenRep(true)
              }}
            >
              Generate Report
            </Button>
          </Box>
        ) : null}
        <Box
          flex={0.5}
          display="flex"
          flexDirection="column"
          justifyContent="start"
          alignItems="center"
        >
          <IconButton
            sx={{
              borderRadius: '8px',
              backgroundColor: 'bg.200',
            }}
          >
            <img src={NotificationIcon} />
          </IconButton>
        </Box>
      </Box>

      <Box
        display="flex"
        flex={25}
        sx={{
          overflowY: 'scroll',
          maxHeight: '90vh',
        }}
      >
        {/* @ts-expect-error ref */}
        {isInput ? <InputTab /> : <OutputTab ref={outputTab} />}
      </Box>
      <GenerateReportDialog
        open={openGenRep}
        onCancel={() => {
          setOpenGenRep(false)
        }}
        onGenerateReport={onGenerateReport}
        projectName={projectInfo?.name ?? 'Project1'}
        projectId={projectId ?? '1'}
      />
    </Box>
  )
}

export const RuningProjectView: FC = () => {
  return (
    <ProtectedComponent showSidebarOptions>
      <RunningProject />
    </ProtectedComponent>
  )
}
