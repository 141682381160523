import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react'
import { baseUrl } from '../utils/constants'
import type { Configuration, UpdateUser, User } from '../types/user'
import { validValue } from '../utils/functions'

// Define your API endpoints
export const userApi = createApi({
  reducerPath: 'userService',
  baseQuery: fetchBaseQuery({
    baseUrl,
    prepareHeaders: (headers, { getState }) => {
      // Add token to headers if available
      const token = window.localStorage.getItem('auth')
      if (validValue(token)) {
        headers.set('access_token', `${token}`)
        headers.set('token_type', `bearer`)
        headers.set('Authorization', `Bearer ${token}`)
      }

      return headers
    },
  }),
  endpoints: (builder) => ({
    currentUser: builder.query<User, any>({
      query: () => `users/me`,
    }),
    updateUser: builder.mutation<UpdateUser, any>({
      query: (body) => ({
        url: `users/me`,
        method: 'PUT',
        body,
      }),
    }),
    configuration: builder.query<Configuration, null>({
      query: () => `users_conf/me`,
    }),
    updateConfiguration: builder.mutation<Configuration, Configuration>({
      query: (body) => ({
        url: `users_conf/me`,
        method: 'PUT',
        body,
      }),
    }),
  }),
})

// Export hooks for each endpoint
export const {
  useCurrentUserQuery,
  useLazyCurrentUserQuery,
  useUpdateUserMutation,
  useConfigurationQuery,
  useUpdateConfigurationMutation,
} = userApi
