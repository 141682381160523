import { createSlice } from '@reduxjs/toolkit'
import type { PayloadAction } from '@reduxjs/toolkit'
import type { RootState } from './store'
import type { GeneratedChartData } from '../types/output_object'
import type { Cartesian3 } from 'cesium'

interface CSVMapping {
  header: string // user csv header
  column: string // id, lat, lon
  isCorrect: boolean
}

// Define a type for the slice state
interface NewProjectState {
  projectName: string
  typeOfAsset: string
  country: string

  newProject: string
  marketName: string
  stateName: string
  stateCode: string
  countyName: string
  regionName: string

  inputSource: string | null // 'gsv' | 'cyclomedia' | 'c3spectra' | null
  setting: string | null // 'general' | 'project specific' | null
  gpuUtilization: string | null // 'onprem' | 'cloud' | null
  numberOfGpus: string

  inputSourceKey: string | null
  inputSourceSecret: string | null
  backendVersion: string | null

  // Form fields
  isError: any

  // submission value
  projectId: string
  sampleRows: string[][]
  // for csv in flow
  csvMapping: CSVMapping[]
  selectedFile: File | null
  // for draw polygon flow
  polygonPoints: Cartesian3[]

  // output data
  chartData: GeneratedChartData | null
  outputSample: any
}

// Define the initial state using that type
const initialState: NewProjectState = {
  projectName: '',
  typeOfAsset: '',
  country: '',

  newProject: '',
  marketName: '',
  stateName: '',
  stateCode: '',
  countyName: '',
  regionName: '',

  inputSource: 'gsv',
  setting: null,
  gpuUtilization: null,
  numberOfGpus: '',

  inputSourceKey: '',
  inputSourceSecret: null,
  backendVersion: null,

  isError: {},

  projectId: '',
  csvMapping: [],
  selectedFile: null,
  sampleRows: [],
  polygonPoints: [],

  chartData: null,
  outputSample: null,
}

export const newProjectSlice = createSlice({
  name: 'newProject',
  initialState,
  reducers: {
    setProjectName: (state, action: PayloadAction<string>) => {
      state.projectName = action.payload
    },
    setTypeOfAsset: (state, action: PayloadAction<string>) => {
      state.typeOfAsset = action.payload
    },
    setCountry: (state, action: PayloadAction<string>) => {
      state.country = action.payload
    },
    setNewProject: (state, action: PayloadAction<string>) => {
      state.newProject = action.payload
    },
    setMarketName: (state, action: PayloadAction<string>) => {
      state.marketName = action.payload
    },
    setStateName: (state, action: PayloadAction<string[]>) => {
      state.stateName = action.payload[0]
      state.stateCode = action.payload[1]
    },
    setCountyName: (state, action: PayloadAction<string>) => {
      state.countyName = action.payload
    },
    setRegionName: (state, action: PayloadAction<string>) => {
      state.regionName = action.payload
    },
    setInputSource: (state, action: PayloadAction<string | null>) => {
      state.inputSource = action.payload
      state.inputSourceKey = ''
      state.inputSourceSecret = ''
    },
    setSetting: (state, action: PayloadAction<string | null>) => {
      state.setting = action.payload
    },
    setGpuUtilization: (state, action: PayloadAction<string | null>) => {
      state.gpuUtilization = action.payload
    },
    setNumberOfGpus: (state, action: PayloadAction<string>) => {
      state.numberOfGpus = action.payload
    },
    setInputSourceKey: (state, action: PayloadAction<string | null>) => {
      state.inputSourceKey = action.payload
    },
    setInputSourceSecret: (state, action: PayloadAction<string | null>) => {
      state.inputSourceSecret = action.payload
    },
    setBackendVersion: (state, action: PayloadAction<string | null>) => {
      state.backendVersion = action.payload
    },
    setProjectId: (state, action: PayloadAction<string>) => {
      state.projectId = action.payload
    },
    addCSVMapping: (state, action: PayloadAction<CSVMapping>) => {
      const hasExisting = state.csvMapping.findIndex(
        (item) => item.header === action.payload.header
      )
      if (hasExisting !== -1) {
        state.csvMapping.splice(hasExisting, 1)
      }
      state.csvMapping.push(action.payload)
    },
    clearCSVMapping: (state) => {
      state.csvMapping.splice(0, state.csvMapping.length)
      state.csvMapping = []
    },
    setSelectedFile: (state, action: PayloadAction<File | null>) => {
      state.selectedFile = action.payload
    },
    setSampleCSV: (state, action: PayloadAction<string[][]>) => {
      state.sampleRows = action.payload
    },
    setChartData: (state, action: PayloadAction<GeneratedChartData>) => {
      state.chartData = action.payload
    },
    setOutputData: (state, action: PayloadAction<any>) => {
      state.outputSample = action.payload
    },
    setPolygonPoints: (state, action: PayloadAction<Cartesian3[]>) => {
      state.polygonPoints = action.payload
    },
    isError: (state) => {
      const errors = Object.assign({}, state.isError)
      if (state.projectName === '') {
        if (!Object.prototype.hasOwnProperty.call(errors, 'projectName'))
          errors.projectName = 'projectName'
      } else {
        delete errors.projectName
      }
      if (state.typeOfAsset === '') {
        if (!Object.prototype.hasOwnProperty.call(errors, 'typeOfAsset'))
          errors.typeOfAsset = 'typeOfAsset'
      } else {
        delete errors.typeOfAsset
      }
      if (state.country === '') {
        if (!Object.prototype.hasOwnProperty.call(errors, 'country'))
          errors.country = 'country'
      } else {
        delete errors.country
      }
      if (state.inputSource === null || state.inputSource === '') {
        if (!Object.prototype.hasOwnProperty.call(errors, 'inputSource'))
          errors.inputSource = 'inputSource'
      } else {
        delete errors.inputSource
      }
      if (state.setting === null || state.setting === '') {
        if (!Object.prototype.hasOwnProperty.call(errors, 'setting'))
          errors.setting = 'setting'
      } else {
        delete errors.setting
      }
      if (state.gpuUtilization === null || state.gpuUtilization === '') {
        if (!Object.prototype.hasOwnProperty.call(errors, 'gpuUtilization'))
          errors.gpuUtilization = 'gpuUtilization'
      } else {
        delete errors.gpuUtilization
      }

      if (state.numberOfGpus === '' && state.gpuUtilization === 'cloud') {
        if (!Object.prototype.hasOwnProperty.call(errors, 'numberOfGpus'))
          errors.numberOfGpus = 'numberOfGpus'
      } else {
        delete errors.numberOfGpus
      }
      if (state.setting === 'project specific') {
        if (state.inputSourceKey === null || state.inputSourceKey === '') {
          if (!Object.prototype.hasOwnProperty.call(errors, 'inputSourceKey'))
            errors.inputSourceKey = 'inputSourceKey'
        } else {
          delete errors.inputSourceKey
        }
        if (
          state.inputSourceSecret === null ||
          state.inputSourceSecret === ''
        ) {
          if (
            !Object.prototype.hasOwnProperty.call(errors, 'inputSourceSecret')
          )
            errors.inputSourceSecret = 'inputSourceSecret'
        } else {
          delete errors.inputSourceSecret
        }
      }
      state.isError = errors
    },
    reset: (state) => {
      Object.assign(state, initialState)
    },
  },
})

export const {
  setProjectName,
  setTypeOfAsset,
  setCountry,
  setNewProject,
  setMarketName,
  setStateName,
  setCountyName,
  setRegionName,
  setInputSource,
  setSetting,
  setGpuUtilization,
  setNumberOfGpus,
  setInputSourceKey,
  setInputSourceSecret,
  setBackendVersion,
  setProjectId,
  addCSVMapping,
  clearCSVMapping,
  setSelectedFile,
  setSampleCSV,
  setChartData,
  setOutputData,
  setPolygonPoints,
  reset,
  isError,
} = newProjectSlice.actions
export const newProjectSelector = (state: RootState) => state.newProjectReducer

export default newProjectSlice.reducer
