import React from 'react'
import { Checkbox, type CheckboxProps } from '@mui/material'
import { ReactComponent as CheckedBox } from '../assets/svgs/checked_box.svg'
import { ReactComponent as UnCheckedBox } from '../assets/svgs/un_checked.svg'

export function SDCheckbox(props: CheckboxProps) {
  return (
    <Checkbox
      disableRipple
      color="default"
      checkedIcon={<CheckedBox />}
      icon={<UnCheckedBox />}
      {...props}
    />
  )
}
