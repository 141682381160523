import React, { useEffect, type FC } from 'react'
import SpectraDiscovery from '../assets/svgs/spectra_disc.svg'
import Spline from '@splinetool/react-spline'
import {
  Box,
  Button,
  CircularProgress,
  Grid,
  IconButton,
  InputAdornment,
  Link,
  TextField,
  Typography,
  useMediaQuery,
} from '@mui/material'
import { isValidEmail, validValue } from '../utils/functions'
import { Visibility, VisibilityOff } from '@mui/icons-material'
import { useLoginMutation } from '../services/auth_service'
import type { Login } from '../types/login_request'
import { errorToast, successToast } from '../utils/toast'
import { authSelector, login } from '../store/auth'
import { useNavigate } from 'react-router-dom'
import { useAppSelector, useAppDispatch } from '../store/hooks'

export const LoginView: FC = () => {
  const dispatch = useAppDispatch()
  const auth = useAppSelector(authSelector)
  const navigate = useNavigate()
  const [showPassword, setShowPassword] = React.useState(false)
  const [isMailValid, setIsEmailValid] = React.useState<boolean | null>(null)
  const [isPasswordValid, setIsPasswordValid] = React.useState<boolean | null>(
    null
  )
  const [loginData, setLoginData] = React.useState<Login>({
    username: '',
    password: '',
  })
  const [loginCall, { isLoading }] = useLoginMutation()
  // @ts-expect-error unknown type
  const isSM = useMediaQuery((theme) => theme.breakpoints.up('sm'))
  // @ts-expect-error unknown type
  const isMD = useMediaQuery((theme) => theme.breakpoints.up('md'))
  // @ts-expect-error unknown type
  const isLG = useMediaQuery((theme) => theme.breakpoints.up('lg'))

  function onLoad(spline: any) {
    let zoom = 0.8
    if (!isSM) {
      zoom = 0.2
    } else if (!isMD) {
      zoom = 0.6
    } else if (!isLG) {
      zoom = 0.68
    }
    spline.setZoom(zoom)
  }

  function signIn() {
    let isValid = true
    if (!isValidEmail(loginData.username)) {
      setIsEmailValid(false)
      isValid = false
    } else {
      setIsEmailValid(true)
    }
    if (loginData.password.length < 1) {
      setIsPasswordValid(false)
      isValid = false
    } else {
      setIsPasswordValid(true)
    }

    if (isValid) {
      loginCall(loginData)
        .unwrap()
        .then((response) => {
          dispatch(login(response))
          successToast('Login successful')
          setTimeout(() => {
            navigate('/home')
          }, 500)
        })
        .catch((err) => {
          const errMsg = err != null && 'data' in err ? err.data : {}
          const msg = errMsg?.detail ?? 'Some error occured'

          errorToast(`${msg}`)
        })
    }
  }

  useEffect(() => {
    if (validValue(auth.token)) {
      navigate('/home')
    }
  }, [auth.token])

  return (
    <Grid container className="h-screen">
      <Grid item md={6} pt={2} pl={10}>
        <Box mb={1} display="flex">
          <img src={SpectraDiscovery} alt="Spectra Discovery" />
        </Box>
        <Box
          display="flex"
          justifyContent="center"
          alignItems="center"
          sx={{ height: '80vh', pointerEvents: 'none' }}
        >
          <Spline
            onLoad={onLoad}
            scene="https://prod.spline.design/VB3dtUmFvmLibQl7/scene.splinecode"
          />
        </Box>
      </Grid>
      <Grid
        item
        md={6}
        pl={'5vw'}
        pr={'16vw'}
        container
        direction="column"
        justifyContent="center"
        alignItems="stretch"
      >
        <Box
          component="form"
          noValidate
          autoComplete="off"
          onSubmit={signIn}
          sx={{
            display: 'flex',
            flex: 3,
            flexDirection: 'column',
            justifyContent: 'end',
            alignItems: 'stretch',
          }}
        >
          <Typography
            variant="h5"
            color="white.main"
            mb={'3.5vh'}
            textAlign={'start'}
          >
            Login
          </Typography>
          <TextField
            label="Enter your email"
            variant="outlined"
            type="email"
            size="small"
            error={loginData.username.length > 0 && isMailValid === false}
            helperText={
              loginData.username.length > 0 && isMailValid === false
                ? 'Please enter a valid email address'
                : ''
            }
            sx={{ mb: 2 }}
            onChange={(e) => {
              setLoginData((prev) => ({ ...prev, username: e.target.value }))
            }}
          />

          <TextField
            label="Enter password"
            variant="outlined"
            size="small"
            sx={{ mb: 2 }}
            error={isPasswordValid === false}
            helperText={
              isPasswordValid === false
                ? 'Password must be at least 8 characters'
                : ''
            }
            onKeyDown={(e) => {
              const isValid = !(
                isMailValid !== true &&
                isPasswordValid !== true &&
                loginData.username.length < 8 &&
                loginData.password.length === 0
              )
              if (isValid && e.key === 'Enter') {
                signIn()
              }
            }}
            onChange={(e) => {
              setLoginData((prev) => ({ ...prev, password: e.target.value }))
            }}
            type={showPassword ? 'text' : 'password'}
            InputProps={{
              endAdornment: (
                <InputAdornment position="end">
                  <IconButton
                    aria-label="toggle password visibility"
                    onClick={() => {
                      setShowPassword((prev) => !prev)
                    }}
                    edge="end"
                  >
                    {showPassword ? (
                      <VisibilityOff
                        sx={{ color: 'textColor.main', fontSize: 15 }}
                      />
                    ) : (
                      <Visibility
                        sx={{ color: 'textColor.main', fontSize: 15 }}
                      />
                    )}
                  </IconButton>
                </InputAdornment>
              ),
            }}
          />
          {isLoading ? (
            <Box>
              <CircularProgress />
            </Box>
          ) : (
            <Button
              variant="contained"
              disabled={
                isMailValid !== true &&
                isPasswordValid !== true &&
                loginData.username.length < 8 &&
                loginData.password.length === 0
              }
              onClick={signIn}
            >
              Log in
            </Button>
          )}
          <Box
            display="flex"
            flexDirection="row"
            sx={{
              flexWrap: 'wrap',
              justifyContent: 'center',
              mb: 3,
              mt: 3,
            }}
          >
            <Typography
              color="primary.main"
              sx={{ fontSize: '14px', lineHeight: '19.6px', fontWeight: 400 }}
            >
              Forgot your password?&nbsp;
            </Typography>
            <Typography
              color="textColor.main"
              sx={{ fontSize: '14px', lineHeight: '19.6px', fontWeight: 400 }}
            >
              Reset it here{'\n'}
            </Typography>
          </Box>
        </Box>
        <Box
          sx={{
            flex: 1,
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'end',
            alignItems: 'start',
          }}
          mb={2}
        >
          <Typography
            sx={{ fontSize: '14px', lineHeight: '19.6px', fontWeight: 400 }}
          >
            Don{"'"}t have an account?{' '}
            <Typography
              color="primary.main"
              component="span"
              sx={{ fontSize: '14px', lineHeight: '19.6px', fontWeight: 400 }}
            >
              <Link href="/signup" underline="none">
                Sign up
              </Link>
            </Typography>
          </Typography>
        </Box>
      </Grid>
    </Grid>
  )
}
