import {
  Box,
  Button,
  CircularProgress,
  IconButton,
  Typography,
} from '@mui/material'
import {
  Cartesian3,
  Color,
  MapboxStyleImageryProvider,
  type Viewer as CesiumView,
} from 'cesium'
import React from 'react'
import {
  BillboardGraphics,
  Camera,
  CameraFlyTo,
  type CesiumComponentRef,
  Entity,
  ImageryLayer,
  PolygonGraphics,
  Viewer,
} from 'resium'
import {
  addCSVMapping,
  newProjectSelector,
  setPolygonPoints,
  setSampleCSV,
} from '../store/new_project'
import { useAppDispatch, useAppSelector } from '../store/hooks'
import {
  downloadCSVLines,
  extractPoints,
  formatDate,
  notNull,
  openInStreetView,
  parsePolygon,
  toFixedDown,
  validValue,
} from '../utils/functions'
import {
  useGetFileMutation,
  useGetProjectQuery,
} from '../services/project_services'
import { mapboxConfig } from '../utils/constants'
import { useQuery } from '../utils/parsers'
import { errorToast, successToast, warningToast } from '../utils/toast'
import { logout } from '../store/auth'
import { useNavigate } from 'react-router-dom'
import CloseIcon from '@mui/icons-material/Close'

const InputTab: React.FC = () => {
  const query = useQuery()
  const projectId = query.get('project')
  const navigate = useNavigate()
  const [isCesiumInit, setIsCesiumInit] = React.useState<boolean>(false)
  const viewer = React.useRef<CesiumView>()
  const cesiumContainer = React.useRef<HTMLDivElement>()
  const newProject = useAppSelector(newProjectSelector)
  const [selectedPoint, setSelectedPoint] = React.useState<any>(null)
  const {
    data: projectInfo,
    // eslint-disable-next-line
    error,
    // eslint-disable-next-line
    isLoading,
    // eslint-disable-next-line
    refetch,
  } = useGetProjectQuery(projectId?.toString() ?? '')
  // eslint-disable-next-line
  const [getFile, { isLoading: isFetchingFile, data: file }] =
    useGetFileMutation()
  const dispatch = useAppDispatch()
  const imgProvider = React.useMemo(
    () => new MapboxStyleImageryProvider(mapboxConfig),
    []
  )
  const points = React.useMemo(() => {
    return extractPoints(newProject.sampleRows, newProject.csvMapping)
  }, [newProject.sampleRows, newProject.csvMapping])

  const summary = [
    {
      title: 'Project created',
      val: validValue(projectInfo?.created_at)
        ? formatDate(projectInfo?.created_at ?? '')
        : 'N/A',
    },
    {
      title: 'Market',
      val: validValue(projectInfo?.market) ? projectInfo?.market : 'N/A',
    },
    { title: 'Asset type ', val: 'Joint Utility Pole' },
    { title: 'Input Rows', val: projectInfo?.files[0]?.num_of_rows },
    { title: 'Source file', val: projectInfo?.files[0]?.original_file_name },
    {
      title: 'Number of GPUs',
      val: validValue(projectInfo?.gpu_count?.toString())
        ? projectInfo?.gpu_count
        : '1',
    },
    { title: 'Input file', val: 'download' },
  ]

  const downloadInput = (showDownload = false) => {
    if (!validValue(projectInfo?.files[0]?.id?.toString())) return
    getFile(projectInfo?.files[0]?.id?.toString() ?? '')
      .unwrap()
      .then((response: any) => {
        if (response?.status === 401 || response.data?.status === 401) {
          dispatch(logout())
          navigate('/login')
          setTimeout(() => {
            warningToast('Session expired, please login again')
          }, 500)
          return
        }
        let path = response.trimmed_s3_path
        if (response?.s3_path.includes('Signature') === true) {
          path = response.s3_path
        }
        // eslint-disable-next-line
        downloadCSVLines(path)
          .then((data) => {
            const [lines, csvData] = data
            if (showDownload) {
              const element = document.createElement('a')
              const file = new Blob([csvData.toString()], {
                type: 'text/plain',
              })
              element.href = URL.createObjectURL(file)
              const fileName =
                `${projectInfo?.name}_${projectInfo?.files[0].id}`.replaceAll(
                  ' ',
                  '_'
                )
              element.download = `${fileName}_input.csv`
              document.body.appendChild(element) // Required for this to work in FireFox
              element.click()
              successToast('File downloaded successfully')
            } else {
              // if (lines.length > 50) {
              //   sample = lines.slice(0, 50)
              // }
              // remove 50 rows limit
              if (lines.length > 1) {
                const header = lines[0]
                // eslint-disable-next-line
                Object.entries(response.file_column_map).forEach(
                  ([key, value]) => {
                    let ith = header.indexOf(key)
                    if (ith === -1) {
                      // @ts-expect-error type
                      ith = header.indexOf(value)
                    }
                    dispatch(
                      addCSVMapping({
                        // @ts-expect-error type
                        header: value,
                        column: ith.toString(),
                        isCorrect: true,
                      })
                    )
                  }
                )
              }
              // @ts-expect-error type
              dispatch(setSampleCSV(lines))
            }
          })
          .catch((error) => {
            errorToast('Error downloading CSV')
            console.error('Error downloading or parsing CSV:', error)
          })
      })
      .catch((err: any) => {
        if (err.error?.status === 401 || err.data?.status === 401) {
          dispatch(logout())
          navigate('/login')
          setTimeout(() => {
            warningToast('Session expired, please login again')
          }, 500)
          return
        }
        const errMsg = err != null && 'data' in err ? err.data : {}
        const msg = errMsg?.detail ?? 'Some error occurred'

        errorToast(`${msg}`)
      })
    if (
      newProject.polygonPoints.length < 1 &&
      projectInfo?.is_polygon === true
    ) {
      dispatch(setPolygonPoints(parsePolygon(projectInfo.polygon_coordinates)))
    }
  }

  if (newProject.sampleRows.length < 1 && !isFetchingFile) {
    downloadInput()
  }

  // To Prevent the React.StrictMode Effect on cesium
  React.useEffect(() => {
    if (!isCesiumInit) {
      setTimeout(() => {
        setIsCesiumInit(true)
      }, 1000)
    }
  }, [])

  const initCesium = (e: CesiumComponentRef<CesiumView> | null) => {
    if (e != null) {
      if (e.cesiumElement !== viewer.current && e.cesiumElement != null) {
        viewer.current = e.cesiumElement
      }
    }
  }

  return (
    <Box
      display="flex"
      flex={1}
      flexDirection="column"
      bgcolor="bg.200"
      borderRadius="20px"
      p="20px"
      sx={{
        overflow: 'hidden',
      }}
    >
      <Box flex={12} display="flex" flexDirection="column">
        <Box flex={1.2}>
          <Typography
            sx={{
              fontSize: '18px',
              fontWeight: 400,
              lineHeight: '24.3px',
              textAlign: 'start',
            }}
          >
            Input summary
          </Typography>
        </Box>
        <Box flex={1} display="flex" flexDirection="row">
          {summary.map((item, index) => {
            const isLast = index === summary.length - 1
            const sx = item.title === 'Source file' ? { maxWidth: '40ch' } : {}
            return (
              <React.Fragment key={index}>
                <Box
                  flex={1}
                  display="flex"
                  flexDirection="column"
                  alignItems="start"
                  sx={{
                    cursor: isLast ? 'pointer' : 'default',
                  }}
                  onClick={() => {
                    if (!isLast) return
                    downloadInput(true)
                  }}
                >
                  <Typography
                    sx={{
                      flex: 1.2,
                      fontSize: '12px',
                      fontWeight: 300,
                      lineHeight: '16.8px',
                      color: 'textColor.300',
                    }}
                  >
                    {item.title}
                  </Typography>
                  {isLast && isFetchingFile ? (
                    <CircularProgress />
                  ) : (
                    <Typography
                      noWrap
                      sx={{
                        flex: 1,
                        fontSize: '14px',
                        fontWeight: 400,
                        lineHeight: '19.6px',
                        textDecoration: isLast ? 'underline' : 'none',
                        maxLines: 2,
                        textOverflow: 'ellipsis',
                        ...sx,
                      }}
                    >
                      {item.val}
                    </Typography>
                  )}
                </Box>
                {!isLast ? (
                  <Box
                    flex={0.4}
                    display="flex"
                    flexDirection="column"
                    justifyContent="center"
                    alignItems="center"
                  >
                    <Box
                      bgcolor="stroke.main"
                      sx={{
                        width: '2px',
                        height: '40%',
                      }}
                    />
                  </Box>
                ) : null}
              </React.Fragment>
            )
          })}
        </Box>
      </Box>
      <Box flex={6} />
      <Box
        ref={cesiumContainer}
        flex={77}
        display="flex"
        flexDirection="column"
        sx={{
          overflow: 'hidden',
          borderRadius: '20px',
        }}
      >
        <div
          style={{
            flex: 1,
            flexDirection: 'column',
            justifyContent: 'stretch',
            justifyItems: 'stretch',
            borderRadius: '20px',
            overflow: 'hidden',
          }}
        >
          {isCesiumInit ? (
            <Viewer
              ref={initCesium}
              fullscreenButton={false}
              homeButton={false}
              sceneModePicker={true}
              selectionIndicator={true}
              timeline={false}
              animation={false}
              geocoder={false}
              infoBox={false}
              onSelectedEntityChange={(e) => {
                if (notNull(e?.description)) {
                  // @ts-expect-error type
                  const data = points[parseInt(e.description)]
                  if (notNull(data?.id)) {
                    setSelectedPoint(data)
                  }
                }
              }}
              onTrackedEntityChange={(e) => {}}
              style={{
                height: '100%',
              }}
            >
              <ImageryLayer imageryProvider={imgProvider} />
              <Camera />
              {points.length > 0 &&
              notNull(points[0].lon) &&
              notNull(points[0].lat) ? (
                <CameraFlyTo
                  destination={Cartesian3.fromDegrees(
                    +points[0].lon,
                    +points[0].lat,
                    6000
                  )}
                  once
                  duration={2}
                />
              ) : null}
              {points.map((point, i) => {
                if (!notNull(point?.lat) || !notNull(point?.lon)) return <></>
                return (
                  <Entity
                    key={point.lat + point.lon + i}
                    name={point.cell_id}
                    description={i.toString()}
                    position={Cartesian3.fromDegrees(+point.lon, +point.lat, 2)}
                  >
                    <BillboardGraphics image={'assets/input_marker.svg'} />
                  </Entity>
                )
              })}
              {newProject.polygonPoints.map((point, index) => (
                <Entity
                  key={index}
                  position={point}
                  point={{
                    color: Color.BLUE,
                    pixelSize: 8,
                    outlineColor: Color.WHITE,
                    outlineWidth: 1,
                  }}
                />
              ))}
              {newProject.polygonPoints.length > 1 ? (
                <Entity name="PolygonInput" description="PolygonInput">
                  <PolygonGraphics
                    hierarchy={newProject.polygonPoints}
                    outlineColor={Color.BLUE}
                    outlineWidth={5}
                    outline={true}
                    show={true}
                    material={Color.fromCssColorString('rgba(0, 0, 255, 0.3)')}
                  />
                </Entity>
              ) : null}
            </Viewer>
          ) : (
            <CircularProgress />
          )}
        </div>
      </Box>
      {selectedPoint != null ? (
        <Box
          sx={{
            position: 'absolute',
            top: cesiumContainer.current?.getBoundingClientRect().top,
            left: cesiumContainer.current?.getBoundingClientRect().left,
            right: cesiumContainer.current?.getBoundingClientRect().right,
            minHeight: cesiumContainer.current?.getBoundingClientRect().height,
            minWidth: cesiumContainer.current?.getBoundingClientRect().width,
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'center',
            alignItems: 'center',
          }}
        >
          <Box
            sx={{
              borderRadius: '8px',
              bgcolor: 'bg.200',
              display: 'flex',
              flexDirection: 'column',
              minWidth: '312px',
              px: '16px',
              py: '16px',
            }}
          >
            <Box
              display="flex"
              flexDirection="row"
              justifyContent="space-between"
              alignItems="center"
            >
              <Typography
                sx={{
                  fontSize: '18px',
                  fontWeight: 400,
                  lineHeight: '24.3px',
                  color: 'white.200',
                }}
              >
                {selectedPoint.id}
              </Typography>

              <IconButton
                sx={{ p: 0 }}
                onClick={() => {
                  setSelectedPoint(null)
                }}
              >
                <CloseIcon
                  sx={{
                    color: 'textColor.300',
                    fontSize: '18px',
                  }}
                />
              </IconButton>
            </Box>
            <Box
              display="flex"
              flexDirection="row"
              justifyContent="space-between"
              alignItems="center"
              mt={'16px'}
            >
              <Box display="flex" flexDirection="column">
                <Typography
                  sx={{
                    fontSize: '12px',
                    fontWeight: 300,
                    lineHeight: '16.8px',
                    color: 'blue.200',
                    mb: '8px',
                  }}
                >
                  ID
                </Typography>
                <Typography
                  sx={{
                    fontSize: '14px',
                    fontWeight: 400,
                    lineHeight: '19.6px',
                    color: 'white.200',
                  }}
                >
                  {selectedPoint.id}
                </Typography>
              </Box>
              <Box display="flex" flexDirection="column">
                <Typography
                  sx={{
                    fontSize: '12px',
                    fontWeight: 300,
                    lineHeight: '16.8px',
                    color: 'blue.200',
                    mb: '8px',
                  }}
                >
                  Latitude
                </Typography>
                <Typography
                  sx={{
                    fontSize: '14px',
                    fontWeight: 400,
                    lineHeight: '19.6px',
                    color: 'white.200',
                  }}
                >
                  {toFixedDown(+selectedPoint.lat, 6)}
                </Typography>
              </Box>
              <Box display="flex" flexDirection="column">
                <Typography
                  sx={{
                    fontSize: '12px',
                    fontWeight: 300,
                    lineHeight: '16.8px',
                    color: 'blue.200',
                    mb: '8px',
                  }}
                >
                  Longitude
                </Typography>
                <Typography
                  sx={{
                    fontSize: '14px',
                    fontWeight: 400,
                    lineHeight: '19.6px',
                    color: 'white.200',
                  }}
                >
                  {toFixedDown(+selectedPoint.lon, 6)}
                </Typography>
              </Box>
            </Box>
            <Box
              mt={{
                md: '12px',
                lg: '24px',
              }}
              display="flex"
              flexDirection="column"
              alignItems="stretch"
            >
              <Button
                variant="contained"
                sx={{
                  height: '100%',
                  borderRadius: '8px',
                  backgroundColor: 'bg.300',
                  color: 'textColor.300',
                  textTransform: 'none',
                  fontSize: {
                    md: '12px',
                    lg: '14px',
                  },
                  fontWeight: 400,
                  lineHeight: '19.6px',
                  py: '8px',
                }}
                onClick={() => {
                  openInStreetView({
                    latitude: +selectedPoint.lat,
                    longitude: +selectedPoint.lon,
                  })
                }}
              >
                Open in Street View
              </Button>
            </Box>
          </Box>
        </Box>
      ) : null}
    </Box>
  )
}

export default InputTab
