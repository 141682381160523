import { useLocation } from 'react-router-dom'
import { type Pair } from '../types/misc'

export const parseError = (err: any): Array<Pair<string, string>> => {
  const errMsg = err != null && 'data' in err ? err.data : {}
  const msg = errMsg?.detail

  if (Array.isArray(msg)) {
    const errors: Array<Pair<string, string>> = []
    msg.forEach((m) => {
      let k, v: string
      if ('loc' in m && Array.isArray(m.loc)) {
        k = m.loc[m.loc.length - 1]
      } else {
        k = 'Error'
      }
      if ('msg' in m) {
        v = m.msg
      } else {
        v = 'Some error occurred. Please try again later.'
      }
      errors.push({ first: k, second: v })
    })
    return errors
  } else {
    return [
      {
        first: 'Error',
        second:
          typeof msg === 'string'
            ? msg
            : 'Some error occurred. Please try again later.',
      },
    ]
  }
}

export function useQuery() {
  const { search } = useLocation()

  return new URLSearchParams(search)
}
