import { configureStore } from '@reduxjs/toolkit'
import signupReducer from './signup_store'
import authReducer from './auth'
import newProjectReducer from './new_project'
import mappingReducer from './input_mapping'
import genReportReducer from './gen_report'
import { authApi } from '../services/auth_service'
import { userApi } from '../services/user_service'
import { enableMapSet } from 'immer'
import { projectApi } from '../services/project_services'

enableMapSet()

export const store = configureStore({
  reducer: {
    signupReducer,
    authReducer,
    newProjectReducer,
    mappingReducer,
    genReportReducer,
    [authApi.reducerPath]: authApi.reducer,
    [userApi.reducerPath]: userApi.reducer,
    [projectApi.reducerPath]: projectApi.reducer,
  },
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({ serializableCheck: false }).concat([
      authApi.middleware,
      userApi.middleware,
      projectApi.middleware,
    ]),
})

// Infer the `RootState` and `AppDispatch` types from the store itself
export type RootState = ReturnType<typeof store.getState>
// Inferred type: {posts: PostsState, comments: CommentsState, users: UsersState}
export type AppDispatch = typeof store.dispatch
