import { Box, Typography } from '@mui/material'
import SpectraDiscovery from '../assets/svgs/spectra_disc.svg'
import React from 'react'
import { ProtectedComponent } from '../components/protected_route'

const Insights = () => {
  return (
    <Box
      display="flex"
      flex={1}
      flexDirection="column"
      justifyContent="center"
      alignItems="center"
      className="h-screen"
    >
      <Box pl="25px">
        <img src={SpectraDiscovery} alt="create project" />
      </Box>
      <Box pt="8px">
        <Typography
          sx={{
            fontSize: '24px',
            fontWeight: 600,
            lineHeight: '33.4px',
            textAlign: 'center',
          }}
        >
          Coming soon
        </Typography>
      </Box>
      <Box pt="6px">
        <Typography
          sx={{
            fontSize: '14px',
            fontWeight: 400,
            lineHeight: '19.6px',
            color: 'textColor.300',
          }}
        >
          <b>Insights</b> features will be available soon
        </Typography>
      </Box>
    </Box>
  )
}

export const InsightsView = () => {
  return (
    <ProtectedComponent showSidebarOptions>
      <Insights />
    </ProtectedComponent>
  )
}
