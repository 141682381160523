// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `
.h-screen {
    height: 100vh;
}


*::-webkit-scrollbar-track{
	-webkit-box-shadow: inset 0 0 6px rgba(0,0,0,0.3);
	border-radius: 10px;
	background-color: #000;
}

*::-webkit-scrollbar{
	width: 12px;
	background-color: #000;
}

*::-webkit-scrollbar-thumb{
	border-radius: 10px;
	background-color: #2A2B2E;
}`, "",{"version":3,"sources":["webpack://./src/index.css"],"names":[],"mappings":";AACA;IACI,aAAa;AACjB;;;AAGA;CACC,iDAAiD;CACjD,mBAAmB;CACnB,sBAAsB;AACvB;;AAEA;CACC,WAAW;CACX,sBAAsB;AACvB;;AAEA;CACC,mBAAmB;CACnB,yBAAyB;AAC1B","sourcesContent":["\n.h-screen {\n    height: 100vh;\n}\n\n\n*::-webkit-scrollbar-track{\n\t-webkit-box-shadow: inset 0 0 6px rgba(0,0,0,0.3);\n\tborder-radius: 10px;\n\tbackground-color: #000;\n}\n\n*::-webkit-scrollbar{\n\twidth: 12px;\n\tbackground-color: #000;\n}\n\n*::-webkit-scrollbar-thumb{\n\tborder-radius: 10px;\n\tbackground-color: #2A2B2E;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
