import { createSlice } from '@reduxjs/toolkit'
import type { PayloadAction } from '@reduxjs/toolkit'
import type { RootState } from './store'
import { validValue } from '../utils/functions'
import { useSelector } from 'react-redux'
import type { User } from '../types/user'

// Define a type for the slice state
interface AuthState {
  token: string
  userData: User | null
  isSidebarOpen: boolean
}

// Define the initial state using that type
const initialState: AuthState = {
  token: '',
  userData: null,
  isSidebarOpen: true,
}

export const authSlice = createSlice({
  name: 'auth',
  initialState: () => {
    const token = window.localStorage.getItem('auth')
    return token != null && token.length > 0
      ? { token, userData: null, isSidebarOpen: true }
      : initialState
  },
  reducers: {
    login: (state, action: PayloadAction<any>) => {
      if (validValue(action.payload.access_token)) {
        state.token = action.payload.access_token
        state.userData = action.payload.userData
        window.localStorage.setItem('auth', state.token)
        window.localStorage.setItem('isUser', 'yes')
      }
    },
    logout: (state) => {
      state.token = ''
      state.userData = null
      window.localStorage.removeItem('auth')
    },
    setSidebarState: (state, action: PayloadAction<boolean>) => {
      state.isSidebarOpen = action.payload
    },
  },
})

export const { login, logout, setSidebarState } = authSlice.actions
export const authSelector = (state: RootState) => state.authReducer

export default authSlice.reducer

export const useAuthToken = () => {
  return useSelector(authSelector)
}
