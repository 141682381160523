export interface NewProject {
  name: string
  type_of_assets: string
  country: string
  gpu_utilization: string
  gpu_count: number
  is_default: boolean
  project_specific_settings: any
  total_assets_analyzed_count: number
  detail: string
  market: string
  state: string
  county: string
  region: string
}

export const isProjectMandatoryFill = (pro: NewProject) => {
  return !(
    pro.name === '' ||
    pro.type_of_assets === '' ||
    pro.country === '' ||
    pro.gpu_utilization === '' ||
    (pro.gpu_utilization === 'onprem' && pro.gpu_count === 0)
  )
}

export interface ProjectFileUpload {
  project_id: any
  file: any
  column_mapping: any
}

export interface NewProjectResponse {
  name: string
  type_of_assets: string
  detail: string
  market: string
  country: string
  state: string
  county: string
  region: string
  gpu_utilization: string
  gpu_count: any
  is_default: boolean
  is_polygon: boolean
  polygon_coordinates: string
  kml_s3_path: any
  project_specific_settings: any
  total_assets_analyzed_count: number
  id: number
  created_at: string
  updated_at: string
  tenant_id: number
  user_id: number
  files: ProjectFileUploadResponse[]
}

export interface ProjectFileUploadResponse {
  original_file_name: string
  s3_path: string
  unique_id: string
  market: any
  status: string
  file_type: string
  file_size: any
  file_location: string
  description: any
  tags: any
  file_column_map: FileColumnMap
  tenant_id: number
  user_id: number
  project_id: number
  is_trimmed: boolean
  trimmed_s3_path: any
  num_of_rows: number
  processed_rows: number
  output_file_s3_path: string
  is_triggered: boolean
  triggered_at: string
  process_status: string
  id: number
  created_at: string
  updated_at: string
}

export interface FileColumnMap {
  cell_id: string
  input_lat: string
  input_long: string
}
