import React, { type FC } from 'react'
import { ProtectedComponent } from '../components/protected_route'
import {
  Stack,
  Box,
  Typography,
  IconButton,
  TextField,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  Autocomplete,
  RadioGroup,
  FormControlLabel,
  Button,
} from '@mui/material'
import C3SpectraAssetDetLogo from '../assets/svgs/spectra_asset_logo.svg'
import CloseIcon from '@mui/icons-material/Close'
import WestIcon from '@mui/icons-material/West'
import EastIcon from '@mui/icons-material/East'
import { BpRadio } from '../components/custom_radio'
import ProjectStep from '../components/project_step'
import {
  newProjectSelector,
  setCountry,
  setGpuUtilization,
  setInputSource,
  setMarketName,
  setNewProject,
  setNumberOfGpus,
  setProjectName,
  setRegionName,
  setSetting,
  setStateName,
  setTypeOfAsset,
  isError,
  setProjectId,
  reset,
  setCountyName,
  setInputSourceKey,
  setInputSourceSecret,
  setBackendVersion,
} from '../store/new_project'
import { useAppDispatch, useAppSelector } from '../store/hooks'
import { useNavigate } from 'react-router-dom'
import { useNewProjectMutation } from '../services/project_services'
import { errorToast, successToast } from '../utils/toast'
import { parseError } from '../utils/parsers'
import { City, Country, State } from 'country-state-city'
import { SelectIcon } from '../components/select_icon'
import {
  isProjectMandatoryFill,
  type NewProject as NewPro,
} from '../types/new_pro_request'
import { isGSVKeyValid, validValue } from '../utils/functions'

const NewProject: FC = () => {
  const newProject = useAppSelector(newProjectSelector)
  const dispatch = useAppDispatch()
  const navigate = useNavigate()
  const [projectCall] = useNewProjectMutation()

  const createRequestObject = (): NewPro => {
    return {
      name: newProject.projectName,
      type_of_assets: newProject.typeOfAsset,
      country: newProject.country,
      gpu_utilization: newProject.gpuUtilization ?? '',
      gpu_count: parseInt(newProject?.numberOfGpus ?? '0'),
      is_default: newProject.setting === 'general',
      project_specific_settings:
        newProject.setting === 'project specific'
          ? {
              api_key: newProject.inputSourceKey ?? '',
              secret: newProject.inputSourceSecret ?? '',
              backend: newProject.backendVersion ?? '',
            }
          : {},
      total_assets_analyzed_count: 0,
      detail: newProject.newProject,
      market: newProject.marketName,
      state: newProject.stateName,
      county: newProject.countyName,
      region: newProject.regionName,
    }
  }

  const onSubmit = () => {
    if (validValue(newProject.projectId)) {
      setTimeout(() => {
        navigate(`/project_input?project=${newProject.projectId}`)
      }, 100)
      return
    }
    dispatch(isError())
    const payload = createRequestObject()
    if (isProjectMandatoryFill(payload)) {
      projectCall(payload)
        .unwrap()
        .then((res) => {
          successToast('Project Created')
          dispatch(setProjectId(res.id.toString()))
          setTimeout(() => {
            navigate(`/project_input?project=${res.id}`)
          }, 100)
        })
        .catch((err) => {
          const errors = parseError(err)
          errors.forEach((e) => {
            errorToast(`${e.first}\n${e.second}`)
          })
        })
    } else if (!validValue(payload.gpu_utilization)) {
      errorToast('Please select GPU Utilization')
    }
  }

  return (
    <Stack className="h-screen">
      <Box display="flex" flexDirection="row" px="18px" pt="15px" pb="25px">
        <Box flex={3} display="flex" flexDirection="row" justifyContent="start">
          <img src={C3SpectraAssetDetLogo} alt="Spectra Discovery" />
        </Box>
        <Box
          flex={12}
          display="flex"
          flexDirection="row"
          justifyContent="start"
        >
          <Typography
            sx={{
              fontSize: '24px',
              fontWeight: 600,
              lineHeight: '32.4px',
              color: 'white.main',
            }}
          >
            New project
          </Typography>
        </Box>
        <Box
          flex={1}
          display="flex"
          flexDirection="column"
          justifyContent="start"
          alignItems="end"
        >
          <IconButton
            sx={{
              borderRadius: '8px',
              backgroundColor: 'bg.200',
            }}
            onClick={() => {
              dispatch(reset())
              navigate(-1)
            }}
          >
            <CloseIcon sx={{ color: 'textColor.main', fontSize: '15px' }} />
          </IconButton>
        </Box>
      </Box>
      <Box display="flex" flexDirection="row" sx={{ overflow: 'hidden' }}>
        <Box flex={3} />
        <Box
          flex={8}
          sx={{
            overflowY: 'scroll',
            maxHeight: {
              sm: '70vh',
              md: '76vh',
              lg: '78vh',
              xl: '80vh',
            },
            scrollbarWidth: 'none',
            msOverflowStyle: 'none',
          }}
        >
          <Stack
            bgcolor="bg.200"
            borderRadius="20px"
            direction="column"
            justifyContent="center"
            alignItems="stretch"
            spacing="20px"
            padding="24px 32px"
          >
            <Typography textAlign="start" pb="15px">
              Project Information
            </Typography>
            <TextField
              label="Project name"
              variant="outlined"
              size="small"
              value={newProject.projectName}
              error={Object.prototype.hasOwnProperty.call(
                newProject.isError,
                'projectName'
              )}
              onChange={(e) => {
                dispatch(setProjectName(e.target.value))
              }}
            />
            <FormControl
              error={Object.prototype.hasOwnProperty.call(
                newProject.isError,
                'typeOfAsset'
              )}
              fullWidth
              size="small"
            >
              <InputLabel id="asset-type-label">Type of Assets</InputLabel>
              <Select
                labelId="asset-type-label"
                id="asset-type"
                label="Type of Assets"
                defaultValue=""
                value={newProject.typeOfAsset}
                onChange={(event) => {
                  dispatch(setTypeOfAsset(event.target.value))
                }}
              >
                <MenuItem value={'pole'}>Utility Pole</MenuItem>
              </Select>
            </FormControl>
            <FormControl
              error={Object.prototype.hasOwnProperty.call(
                newProject.isError,
                'country'
              )}
              fullWidth
              size="small"
            >
              <InputLabel id="country-label">Country</InputLabel>
              <Select
                labelId="country-label"
                id="country"
                label="Countary"
                value={newProject.country}
                defaultValue=""
                onChange={(event) => {
                  dispatch(setCountry(event.target.value))
                }}
              >
                {Country.getAllCountries()
                  .filter((count) => ['CA', 'US'].includes(count.isoCode))
                  .map((country) => (
                    <MenuItem key={country.isoCode} value={country.isoCode}>
                      {country.name}
                    </MenuItem>
                  ))}
              </Select>
            </FormControl>

            <Typography textAlign="start" pb="15px" mt="30px">
              Additional Input (Optional)
            </Typography>
            <TextField
              label="Project Description"
              variant="outlined"
              size="small"
              value={newProject.newProject}
              onChange={(e) => {
                dispatch(setNewProject(e.target.value))
              }}
            />
            <Autocomplete
              disablePortal
              size="small"
              id="state-name"
              popupIcon={<SelectIcon />}
              options={(State.getStatesOfCountry(newProject.country) ?? []).map(
                (state) => ({
                  ...state,
                  label: state.name,
                  id: state.name,
                  code: state.isoCode,
                })
              )}
              onChange={(_, value) => {
                dispatch(setStateName([value?.label ?? '', value?.code ?? '']))
              }}
              renderInput={(params) => (
                <TextField
                  placeholder="Select or enter state name"
                  {...params}
                  label="Select or enter state name"
                />
              )}
            />
            <Autocomplete
              disablePortal
              size="small"
              id="market-name"
              popupIcon={<SelectIcon />}
              options={(
                City.getCitiesOfState(
                  newProject.country,
                  newProject.stateCode
                ) ?? []
              ).map((city) => ({ ...city, label: city.name, id: city.name }))}
              onChange={(_, value) => {
                dispatch(setMarketName(value?.label ?? ''))
              }}
              renderInput={(params) => (
                <TextField
                  key={`${params.id}_field`}
                  placeholder="Select or enter market name"
                  label="Select or market name"
                  {...params}
                />
              )}
            />
            <TextField
              label="Enter County Name"
              variant="outlined"
              size="small"
              value={newProject.countyName}
              onChange={(e) => {
                dispatch(setCountyName(e.target.value))
              }}
            />
            <TextField
              label="Enter Region Name"
              variant="outlined"
              size="small"
              value={newProject.regionName}
              onChange={(e) => {
                dispatch(setRegionName(e.target.value))
              }}
            />
          </Stack>
          <Stack
            bgcolor="bg.200"
            borderRadius="20px"
            direction="column"
            justifyContent="center"
            alignItems="stretch"
            spacing="10px"
            padding="24px 32px"
            mt="30px"
          >
            <Typography
              textAlign="start"
              pb="8px"
              sx={{
                fontSize: '18px',
                fontWeight: 400,
                lineHeight: '24.3px',
                color: 'white.200',
              }}
            >
              Input source
            </Typography>
            <FormControl
              error={Object.prototype.hasOwnProperty.call(
                newProject.isError,
                'inputSource'
              )}
            >
              <RadioGroup
                defaultValue="gsv"
                aria-labelledby="input-source-label"
                name="input-source"
                onChange={(_, v) => {
                  dispatch(setInputSource(v))
                }}
              >
                <FormControlLabel
                  value="gsv"
                  control={<BpRadio />}
                  label="GSV"
                />
                <FormControlLabel
                  value="cyclomedia"
                  control={<BpRadio />}
                  label="Cyclo Media"
                />
                <FormControlLabel
                  value="c3spectra"
                  control={<BpRadio />}
                  label="C3Spectra"
                />
              </RadioGroup>
            </FormControl>

            <Typography
              textAlign="start"
              pb="8px"
              sx={{
                fontSize: '18px',
                fontWeight: 400,
                lineHeight: '24.3px',
                color: 'white.200',
              }}
            >
              Settings
            </Typography>
            <FormControl
              error={Object.prototype.hasOwnProperty.call(
                newProject.isError,
                'setting'
              )}
            >
              <RadioGroup
                defaultValue="general"
                aria-labelledby="input-source-label"
                name="input-source"
                onChange={(_, v) => {
                  dispatch(setSetting(v))
                }}
              >
                <FormControlLabel
                  value="general"
                  control={<BpRadio />}
                  label="General"
                />
                <FormControlLabel
                  value="project specific"
                  control={<BpRadio />}
                  label="Project Specific"
                />
              </RadioGroup>
            </FormControl>
            {newProject.setting === 'project specific' && (
              <ProjectSpecificSetting
                inputType={newProject?.inputSource ?? ''}
              />
            )}
            <Typography
              textAlign="start"
              pb="8px"
              sx={{
                fontSize: '18px',
                fontWeight: 400,
                lineHeight: '24.3px',
                color: 'white.200',
              }}
            >
              GPU Utilization
            </Typography>
            <FormControl
              error={Object.prototype.hasOwnProperty.call(
                newProject.isError,
                'gpuUtilization'
              )}
            >
              <RadioGroup
                defaultValue="onprem"
                aria-labelledby="gpu-label"
                name="gpu-source"
                value={newProject.gpuUtilization}
                onChange={(_, v) => {
                  dispatch(setGpuUtilization(v))
                }}
              >
                <FormControlLabel
                  value="onprem"
                  control={<BpRadio />}
                  label="On-Prem GOUS"
                />
                <FormControlLabel
                  value="cloud"
                  control={<BpRadio />}
                  label="Cloud GPUs"
                />
              </RadioGroup>
            </FormControl>
            {newProject.gpuUtilization === 'cloud' && (
              <TextField
                label="Number of GPUs"
                variant="outlined"
                size="small"
                value={newProject.numberOfGpus}
                error={Object.prototype.hasOwnProperty.call(
                  newProject.isError,
                  'numberOfGpus'
                )}
                onChange={(e) => {
                  dispatch(setNumberOfGpus(e.target.value))
                }}
                sx={{ pb: '8px' }}
              />
            )}
          </Stack>
        </Box>
        <Box flex={4} />
      </Box>
      <Box
        display="flex"
        flexGrow={1}
        flexDirection="row"
        justifyContent="stretch"
        minHeight={{
          sm: '60px',
          md: '70px',
          lg: '80px',
          xl: '80px',
        }}
      >
        <Box
          flex={3}
          display="flex"
          flexDirection="row"
          justifyContent="center"
          alignItems="center"
        >
          <Button
            variant="contained"
            startIcon={<WestIcon sx={{ color: 'textColor.300' }} />}
            sx={{
              bgcolor: 'bg.300',
              textTransform: 'none',
              borderRadius: '8px',
              color: 'textColor.300',
              minWidth: '109px',
            }}
            onClick={() => {
              dispatch(reset())
              navigate(-1)
            }}
          >
            Back
          </Button>
        </Box>
        <Box
          flex={9}
          display="flex"
          flexDirection="row"
          justifyContent="stretch"
        >
          <ProjectStep step={1} />
        </Box>
        <Box
          flex={4}
          display="flex"
          flexDirection="row"
          justifyContent="end"
          alignItems="center"
          pr="20px"
        >
          <Button
            variant="contained"
            sx={{
              bgcolor: 'primary.main',
              textTransform: 'none',
              borderRadius: '8px',
              minWidth: '109px',
            }}
            endIcon={<EastIcon />}
            onClick={onSubmit}
          >
            Next
          </Button>
        </Box>
      </Box>
    </Stack>
  )
}

const ProjectSpecificSetting: FC<any> = (props: any) => {
  let { inputType } = props
  inputType = `${inputType.toString().charAt(0).toUpperCase()}${inputType.toString().slice(1)}`
  const dispatch = useAppDispatch()
  const newProject = useAppSelector(newProjectSelector)

  const onKeyValidate = async () => {
    if (
      props.inputType.toLowerCase() === 'gsv' &&
      newProject.inputSourceKey != null
    ) {
      const isValid = await isGSVKeyValid(newProject.inputSourceKey)

      if (!isValid) {
        errorToast('Invalid GSV key')
        dispatch(setInputSourceKey(''))
      } else {
        successToast('GSV key validated')
      }
    }
  }

  return (
    <Box display="flex" flexDirection="column" alignItems="stretch">
      <Box display="flex" flexDirection="row" py="6px">
        <TextField
          sx={{ flex: 7 }}
          label={`${inputType} API Key`}
          variant="outlined"
          size="small"
          value={newProject.inputSourceKey}
          onChange={(e) => {
            dispatch(setInputSourceKey(e.target.value))
          }}
        />
        <Box
          flex={2}
          display="flex"
          flexDirection="column"
          alignItems="stretch"
          pl="8px"
        >
          <Button
            variant="contained"
            onClick={() => {
              onKeyValidate()
                .then(() => {})
                .catch(() => {})
            }}
            sx={{
              bgcolor: 'bg.300',
              textTransform: 'none',
              borderRadius: '8px',
              color: 'textColor.300',
              borderColor: 'stroke.main',
              border: '1px solid',
            }}
          >
            Validate & Save
          </Button>
        </Box>
      </Box>
      <Box display="flex" flexDirection="row" py="6px">
        <TextField
          sx={{ flex: 7 }}
          label={`${inputType} secret`}
          variant="outlined"
          size="small"
          value={newProject.inputSourceSecret}
          onChange={(e) => {
            dispatch(setInputSourceSecret(e.target.value))
          }}
        />
        <Box
          flex={2}
          display="flex"
          flexDirection="column"
          alignItems="stretch"
          pl="8px"
        >
          <Button
            variant="contained"
            sx={{
              bgcolor: 'bg.300',
              textTransform: 'none',
              borderRadius: '8px',
              color: 'textColor.300',
              borderColor: 'stroke.main',
              border: '1px solid',
            }}
          >
            Save
          </Button>
        </Box>
      </Box>
      <Box display="flex" flexDirection="row" py="6px">
        <FormControl
          // error={newProject.isError.includes('typeOfAsset')}
          fullWidth
          size="small"
        >
          <InputLabel id="backend-label">Backend Version</InputLabel>
          <Select
            labelId="backend-label"
            id="backend"
            label="Backend Version"
            defaultValue=""
            onChange={(event) => {
              dispatch(setBackendVersion(event.target.value))
            }}
          >
            <MenuItem value={'3_1'}>3.1</MenuItem>
            <MenuItem value={'3_2'}>3.2</MenuItem>
            <MenuItem value={'1_0'}>1.0</MenuItem>
          </Select>
        </FormControl>
      </Box>
    </Box>
  )
}

export const NewProjectView: FC = () => {
  return (
    <ProtectedComponent noSidebar>
      <NewProject />
    </ProtectedComponent>
  )
}
