import React, { type FC } from 'react'
import { ProtectedComponent } from '../components/protected_route'
import {
  Box,
  Typography,
  IconButton,
  Button,
  LinearProgress,
  TextField,
  InputAdornment,
} from '@mui/material'
import C3SpectraAssetDetLogo from '../assets/svgs/spectra_asset_logo.svg'
import CloseIcon from '@mui/icons-material/Close'
import WestIcon from '@mui/icons-material/West'
import { useNavigate } from 'react-router-dom'
import { ReactComponent as SearchIcon } from '../assets/svgs/search.svg'
import SettingIcon from '../assets/svgs/setting_icon.svg'
import TaskItem from '../components/task_item'
import { useGetProjectQuery } from '../services/project_services'
import { formatDate, notNull, validValue } from '../utils/functions'
import { errorToast, successToast } from '../utils/toast'
import { useQuery } from '../utils/parsers'
import { logout } from '../store/auth'
import { useAppDispatch } from '../store/hooks'

const RunAnalysis: FC = () => {
  const query = useQuery()
  const projectId = query.get('project')
  const navigate = useNavigate()
  const dispatch = useAppDispatch()
  const {
    data: projectInfo,
    // eslint-disable-next-line
    error,
    // eslint-disable-next-line
    isLoading,
  } = useGetProjectQuery(projectId ?? '', {
    skip: !notNull(projectId),
    pollingInterval: 2000,
  })
  const isFromDraw = React.useMemo(
    () =>
      query.get('from') === 'draw_input' || projectInfo?.is_polygon === true,
    [query, projectInfo]
  )

  React.useEffect(() => {
    if (projectId == null) {
      setTimeout(() => {
        navigate('/projects')
      }, 500)
    }
  }, [query, projectId])

  const summary = [
    {
      title: 'Market',
      val: validValue(projectInfo?.market) ? projectInfo?.market : 'N/A',
    },
    { title: 'Input Rows', val: projectInfo?.files[0]?.num_of_rows ?? 'N/A' },
    {
      title: 'Source file ',
      val:
        projectInfo?.files[0]?.original_file_name ??
        (isFromDraw ? 'Creating...' : 'N/A'),
    },
    {
      title: 'Number of GPUs',
      val: validValue(projectInfo?.gpu_count?.toString())
        ? projectInfo?.gpu_count
        : '1',
    },
  ]
  const progressSummary = [
    {
      title: 'Process started at',
      val: validValue(projectInfo?.files[0]?.created_at)
        ? formatDate(projectInfo?.files[0]?.created_at ?? '')
        : 'N/A',
    },
    { title: 'Divided in task(s)', val: '1' },
    { title: 'Task(s) completed', val: '0' },
  ]
  const percentage = () => {
    const proceess = projectInfo?.files[0]?.processed_rows ?? null
    const numRows = projectInfo?.files[0]?.num_of_rows ?? null

    if (
      projectInfo?.files[0]?.process_status === 'Completed' ||
      projectInfo?.files[0]?.output_file_s3_path != null
    ) {
      return 100
    }

    if (notNull(proceess) && notNull(numRows)) {
      // eslint-disable-next-line
      return parseInt(((proceess! / numRows!) * 100).toString())
    }
    return 0
  }

  const processedRows = () => {
    const p = percentage()
    if (p === 100) {
      return `${projectInfo?.files[0]?.num_of_rows ?? 0}`
    } else if (p > 0) {
      return `${projectInfo?.files[0]?.processed_rows}`
    } else {
      return '0'
    }
  }

  if (
    projectInfo?.files[0]?.process_status === 'Completed' &&
    projectInfo?.files[0]?.output_file_s3_path != null
  ) {
    // DEBUG (Will be removed after resolve)
    console.info({
      percentage: percentage(),
      s: projectInfo?.files[0]?.process_status === 'Completed',
      projectInfo,
    })
    successToast('File processed successfully')
    setTimeout(() => {
      navigate(`/running_project?project=${projectId}&output=true`)
    }, 500)
  } else {
    // DEBUG (Will be removed after resolve)
    console.log({
      from: 'checker',
      percentage: percentage(),
      s: projectInfo?.files[0]?.process_status === 'Completed',
      projectInfo,
    })
  }

  // @ts-expect-error status
  if (error?.status === 401) {
    dispatch(logout())
    navigate('/login')
  }

  const showOutput = () => {
    if (percentage() < 100) {
      errorToast('Please wait for the process to complete')
      // DEBUG (Will be removed after resolve)
      console.log({
        percentage: percentage(),
        s: projectInfo?.files[0]?.process_status === 'Completed',
      })
    } else {
      navigate(`/running_project?project=${projectId}&output=true`)
    }
  }

  return (
    <Box display="flex" flexDirection="column" className="h-screen">
      <Box display="flex" flexDirection="row" px="18px" pt="15px" pb="25px">
        <Box
          flex={3}
          display="flex"
          flexDirection="row"
          justifyContent="start"
          alignItems="start"
        >
          <img src={C3SpectraAssetDetLogo} alt="Spectra Discovery" />
        </Box>
        <Box
          flex={12}
          display="flex"
          flexDirection="column"
          alignItems="start"
          justifyContent="center"
        >
          <Typography
            sx={{
              fontSize: '24px',
              fontWeight: 600,
              lineHeight: '32.4px',
              color: 'white.main',
            }}
          >
            Run analysis ({projectInfo?.name ?? ''})
          </Typography>
        </Box>
        <Box
          flex={1}
          display="flex"
          flexDirection="column"
          justifyContent="center"
          alignItems="end"
        >
          <IconButton
            sx={{
              borderRadius: '8px',
              backgroundColor: 'bg.200',
            }}
            onClick={() => {
              navigate('/projects')
            }}
          >
            <CloseIcon sx={{ color: 'textColor.main', fontSize: '15px' }} />
          </IconButton>
        </Box>
      </Box>
      <Box display="flex" flexDirection="row" flexGrow={1} alignItems="stretch">
        <Box flex={3} />
        <Box
          flex={11.8}
          display="flex"
          flexDirection="row"
          alignItems="stretch"
          justifyItems="space-between"
        >
          <Box
            sx={{
              flex: 1,
              display: 'flex',
              bgcolor: 'bg.200',
              borderRadius: '20px',
              flexDirection: 'column',
              justifyContent: 'stretch',
              alignItems: 'stretch',
              padding: '24px',
            }}
          >
            <Box flex={2} display="flex" flexDirection="row" minHeight="93px">
              <Box
                flex={1}
                display="flex"
                flexDirection="column"
                alignItems="stretch"
                justifyContent="space-between"
              >
                <Box flex={1}>
                  <Typography sx={{ textAlign: 'start' }}>
                    Input Summary
                  </Typography>
                </Box>
                <Box flex={0.4} />
                <Box flex={1.6} display="flex" flexDirection="row">
                  {summary.map((item, index) => {
                    const isLast = index === summary.length - 1
                    return (
                      <React.Fragment key={index}>
                        <Box
                          flex={index === 0 ? 1.5 : 1.3}
                          display="flex"
                          flexDirection="column"
                          alignItems="start"
                        >
                          <Typography
                            sx={{
                              flex: 1.2,
                              fontSize: '12px',
                              fontWeight: 300,
                              lineHeight: '16.8px',
                              color: 'textColor.300',
                            }}
                          >
                            {item.title}
                          </Typography>
                          <Typography
                            sx={{
                              flex: 1,
                              fontSize: '14px',
                              fontWeight: 400,
                              lineHeight: '19.6px',
                              textDecoration: 'none',
                            }}
                          >
                            {item.val}
                          </Typography>
                        </Box>
                        {!isLast ? (
                          <Box
                            flex={0.4}
                            display="flex"
                            flexDirection="column"
                            justifyContent="center"
                            alignItems="center"
                          >
                            <Box
                              sx={{
                                width: '2px',
                                bgcolor: 'stroke.main',
                                height: '40%',
                              }}
                            />
                          </Box>
                        ) : null}
                      </React.Fragment>
                    )
                  })}
                </Box>
              </Box>
              <Box flex={0.1} />
              <Box
                flex={1}
                display="flex"
                flexDirection="column"
                alignItems="stretch"
                justifyContent="space-between"
              >
                <Box flex={1}>
                  <Typography sx={{ textAlign: 'start' }}>
                    Progress Summary
                  </Typography>
                </Box>
                <Box flex={0.4} />
                <Box flex={1.6} display="flex" flexDirection="row">
                  {progressSummary.map((item, index) => {
                    const isLast = index === progressSummary.length - 1
                    return (
                      <React.Fragment key={index}>
                        <Box
                          flex={index === 0 ? 1.5 : 1}
                          display="flex"
                          flexDirection="column"
                          alignItems="start"
                        >
                          <Typography
                            sx={{
                              flex: 1.2,
                              fontSize: '12px',
                              fontWeight: 300,
                              lineHeight: '16.8px',
                              color: 'textColor.300',
                            }}
                          >
                            {item.title}
                          </Typography>
                          <Typography
                            sx={{
                              flex: 1,
                              fontSize: '14px',
                              fontWeight: 400,
                              lineHeight: '19.6px',
                              textDecoration: 'none',
                            }}
                          >
                            {item.val}
                          </Typography>
                        </Box>
                        {!isLast ? (
                          <Box
                            flex={0.4}
                            display="flex"
                            flexDirection="column"
                            justifyContent="center"
                            alignItems="center"
                          >
                            <Box
                              sx={{
                                width: '2px',
                                bgcolor: 'stroke.main',
                                height: '40%',
                              }}
                            />
                          </Box>
                        ) : null}
                      </React.Fragment>
                    )
                  })}
                </Box>
              </Box>
            </Box>
            <Box flex={8} display="flex" flexDirection="row">
              <Box display="flex" flexDirection="column" flex={4}>
                <Box
                  flex={1.5}
                  display="flex"
                  flexDirection="column"
                  justifyContent="center"
                >
                  <Typography
                    sx={{
                      fontSize: '24px',
                      fontWeight: 400,
                      lineHeight: '32.4px',
                      color: 'white.200',
                      textAlign: 'start',
                    }}
                  >
                    Progress
                  </Typography>
                </Box>
                <Box
                  flex={8.5}
                  bgcolor="bg.main"
                  display="flex"
                  flexDirection="column"
                  borderRadius="16px"
                  alignItems="stretch"
                  p="16px"
                  maxHeight="45vh"
                >
                  {projectInfo?.files?.map((file) => {
                    return (
                      <TaskItem
                        key={file.id}
                        taskId={file.id.toString()}
                        gpuId={'1'}
                        percentage={percentage()}
                        onPause={() => {}}
                        onRestart={() => {}}
                        onShowLogs={() => {}}
                      />
                    )
                  })}
                </Box>
              </Box>
              <Box
                flex={0.3}
                flexDirection="row"
                display="flex"
                justifyContent="center"
                alignItems="center"
              >
                <Box
                  sx={{
                    width: '1px',
                    bgcolor: 'stroke.main',
                    height: '90%',
                  }}
                />
              </Box>
              <Box flex={6} display="flex" flexDirection="column">
                <Box
                  flex={1.5}
                  display="flex"
                  flexDirection="row"
                  justifyContent="space-between"
                  alignItems="center"
                >
                  <Box>
                    <Typography
                      sx={{
                        fontSize: '24px',
                        fontWeight: 400,
                        lineHeight: '24.3px',
                        color: 'white.200',
                        textAlign: 'start',
                      }}
                    >
                      Logs
                    </Typography>
                  </Box>
                  <Box>
                    <TextField
                      placeholder="Search"
                      variant="outlined"
                      size="small"
                      InputProps={{
                        startAdornment: (
                          <InputAdornment position="end" sx={{ mr: '5px' }}>
                            <SearchIcon />
                          </InputAdornment>
                        ),
                      }}
                    />
                    <IconButton>
                      <img src={SettingIcon} />
                    </IconButton>
                  </Box>
                </Box>
                <Box
                  flex={8.5}
                  bgcolor="bg.main"
                  display="flex"
                  flexDirection="column"
                  borderRadius="16px"
                  alignItems="stretch"
                  p="16px"
                  maxHeight="45vh"
                >
                  {
                    // eslint-disable-next-line
                    isFromDraw ? (
                      <Typography
                        sx={{
                          fontSize: '14px',
                          fontWeight: 400,
                          lineHeight: '21px',
                          color: 'blue.200',
                          textAlign: 'start',
                        }}
                      >
                        Processing the polygon...
                      </Typography>
                    ) : null
                  }
                  {
                    // eslint-disable-next-line
                    projectInfo?.files[0]?.process_status == 'Pending' ? (
                      <Typography
                        sx={{
                          fontSize: '14px',
                          fontWeight: 400,
                          lineHeight: '21px',
                          color: 'blue.200',
                          textAlign: 'start',
                        }}
                      >
                        {isFromDraw
                          ? 'Point generated successfully within polygon'
                          : 'File uploaded successfully'}
                      </Typography>
                    ) : null
                  }
                  {
                    // eslint-disable-next-line
                    validValue(projectInfo?.files[0]?.triggered_at) ? (
                      <Typography
                        sx={{
                          fontSize: '14px',
                          fontWeight: 400,
                          lineHeight: '21px',
                          color: 'blue.200',
                          textAlign: 'start',
                        }}
                      >
                        Task Queued for processing...
                      </Typography>
                    ) : null
                  }
                  {projectInfo?.files[0]?.process_status === 'Processing' ||
                  (projectInfo?.files[0]?.processed_rows ?? 0) > 0 ? (
                    <Typography
                      sx={{
                        fontSize: '14px',
                        fontWeight: 400,
                        lineHeight: '21px',
                        color: 'blue.200',
                        textAlign: 'start',
                      }}
                    >
                      Processing the task&nbsp;&nbsp;
                      {processedRows()}/{projectInfo?.files[0]?.num_of_rows}
                      ...
                    </Typography>
                  ) : null}
                </Box>
              </Box>
            </Box>
          </Box>
        </Box>
        <Box flex={0.2} />
      </Box>
      <Box
        display="flex"
        flexDirection="row"
        justifyContent="stretch"
        mt="30px"
        minHeight="88px"
      >
        <Box
          flex={3}
          display="flex"
          flexDirection="row"
          justifyContent="center"
          alignItems="center"
        >
          <Button
            variant="contained"
            startIcon={<WestIcon sx={{ color: 'textColor.300' }} />}
            sx={{
              bgcolor: 'bg.300',
              textTransform: 'none',
              borderRadius: '8px',
              color: 'textColor.300',
              minWidth: '109px',
            }}
            onClick={() => {
              navigate('/projects')
            }}
          >
            Back
          </Button>
        </Box>
        <Box
          flex={11}
          display="flex"
          flexDirection="row"
          justifyContent="center"
          alignItems="center"
        >
          <Box display="flex" flexDirection="column" minWidth="346px">
            <Box
              flex={1}
              display="flex"
              flexDirection="row"
              justifyContent="space-between"
              alignItems="center"
              pb="8px"
            >
              <Typography
                sx={{
                  fontSize: '14px',
                  fontWeight: 400,
                  lineHeight: '19.6px',
                  color: 'white.200',
                }}
              >
                In progress...
              </Typography>
              <Box
                display="flex"
                flexDirection="row"
                justifyContent="space-between"
                alignItems="center"
              >
                <Typography
                  sx={{
                    fontSize: '14px',
                    fontWeight: 400,
                    lineHeight: '19.6px',
                    color: 'white.200',
                    pr: '6px',
                  }}
                >
                  0/1
                </Typography>
                <Typography
                  sx={{
                    fontSize: '14px',
                    fontWeight: 400,
                    lineHeight: '19.6px',
                    color: 'textColor.300',
                  }}
                >
                  Tasks
                </Typography>
              </Box>
            </Box>
            <LinearProgress
              sx={{
                backgroundColor: 'stroke.main',
                '& .MuiLinearProgress-bar': {
                  backgroundColor: 'success.main',
                },
              }}
              variant="determinate"
              value={percentage()}
            />
          </Box>
        </Box>
        <Box
          flex={2}
          display="flex"
          flexDirection="column"
          justifyContent="center"
          alignItems="center"
        >
          <Button
            variant="contained"
            sx={{
              bgcolor: 'bg.300',
              textTransform: 'none',
              borderRadius: '8px',
              minWidth: '109px',
            }}
            onClick={showOutput}
          >
            View Output
          </Button>
        </Box>
      </Box>
    </Box>
  )
}

export const RunAnalysisView: FC = () => {
  return (
    <ProtectedComponent noSidebar>
      <RunAnalysis />
    </ProtectedComponent>
  )
}
