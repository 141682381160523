import React, { type FC } from 'react'
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown'
import CheckIcon from '@mui/icons-material/Check'
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp'

export const SelectIcon: FC = (): React.ReactElement => {
  return (
    <KeyboardArrowDownIcon
      sx={{ color: 'textColor.300', fontSize: 18, pr: '5px' }}
    />
  )
}

export const SelectIconOp = () => {
  return (
    <KeyboardArrowUpIcon
      sx={{ color: 'textColor.300', fontSize: 18, pr: '5px' }}
    />
  )
}

export const CheckBoxIcon: React.FC = () => {
  return <CheckIcon sx={{ color: 'white.main' }} />
}
