import { Grid, Typography, Box, Button, useMediaQuery } from '@mui/material'
import React, { type FC } from 'react'
import { useNavigate } from 'react-router-dom'
import { ProtectedComponent } from '../components/protected_route'
import LauchpadTabButton, { IconType } from '../components/launchpad_tabs'
import { AssetDetectionIcon } from '../components/launchpad_icons'
import CheckIcon from '@mui/icons-material/Check'
import { useAppSelector } from '../store/hooks'
import { authSelector } from '../store/auth'

const Lauchpad: FC = () => {
  const auth = useAppSelector(authSelector)
  const navigate = useNavigate()
  // @ts-expect-error unknown type
  const isSM = useMediaQuery((theme) => theme.breakpoints.up('sm'))
  // @ts-expect-error unknown type
  const isMD = useMediaQuery((theme) => theme.breakpoints.up('md'))
  // @ts-expect-error unknown type
  const isLG = useMediaQuery((theme) => theme.breakpoints.up('lg'))

  return (
    <Box
      display="flex"
      flexDirection="column"
      justifyContent="space-between"
      alignContent="stretch"
      px={2}
    >
      <Box
        display="flex"
        flexDirection="column"
        alignItems="start"
        justifyContent="start"
        flex={2}
      >
        <Typography
          sx={{
            fontSize: '24px',
            fontWeight: 600,
            lineHeight: '33.4px',
            textTransform: 'capitalize',
          }}
        >
          Hi {auth.userData?.first_name}!
        </Typography>
        <Typography
          sx={{
            fontSize: '14px',
            fontWeight: 400,
            color: 'textColor.300',
          }}
        >
          Explore our suite of applications
        </Typography>
      </Box>
      <Box
        display="flex"
        flexDirection="column"
        alignContent="stretch"
        flex={2}
        mt={'2.5vh'}
      >
        <Grid container spacing={1}>
          <Grid item md={3}>
            <LauchpadTabButton
              name="Asset Detection"
              icon={IconType.pole_detec}
              selected={true}
            />
          </Grid>
          <Grid item md={3}>
            <LauchpadTabButton
              name="Shared Spectrum"
              icon={IconType.shared_spectrum}
            />
          </Grid>
          <Grid item md={3}>
            <LauchpadTabButton name="6 GHz Z-Axis" icon={IconType.six_6ghz} />
          </Grid>
          <Grid item md={3}>
            <LauchpadTabButton
              name="PAL Subleasing"
              icon={IconType.pale_subleasing}
            />
          </Grid>
        </Grid>
      </Box>
      <Box
        display="flex"
        bgcolor="bg.200"
        flex={10}
        px={2}
        py={2}
        borderRadius={4}
        mt={'2vh'}
        sx={{
          flexDirection: {
            xs: 'column-reverse',
            sm: 'column-reverse',
            md: 'row',
            lg: 'row',
            xl: 'row',
          },
        }}
      >
        <Box
          flex={3}
          display="flex"
          flexDirection="column"
          justifyContent="space-between"
        >
          <Box
            flex={1}
            display="flex"
            flexDirection="column"
            justifyContent="end"
          >
            <Box display="flex" flexDirection="row">
              <Typography
                sx={{
                  fontSize: '14px',
                  fontWeight: 400,
                  lineHeight: '19.6px',
                  color: 'blue.50',
                }}
                bgcolor={'bg.300'}
                px="18px"
                py="8px"
                borderRadius="8px"
              >
                Licenced
              </Typography>
            </Box>
          </Box>
          <Box
            display="flex"
            justifyContent="start"
            alignItems="center"
            flex={2}
          >
            <AssetDetectionIcon color="white" />
            <Typography
              pl="16px"
              sx={{
                fontSize: '24px',
                fontWeight: 600,
                lineHeight: '32.4px',
                color: 'white.200',
              }}
            >
              Asset Detection
            </Typography>
          </Box>
          <Box flex={3}>
            <Typography
              sx={{
                fontSize: '16px',
                fontWeight: 400,
                lineHeight: '19.6px',
                color: 'textColor.300',
                textAlign: 'start',
              }}
            >
              Inaccurate and outdated data in digital transformation journey
              leads to expensive planning and management operations. This is
              especially critical for small cell planning and deployment.
            </Typography>
          </Box>
          <Box flex={3}>
            <Typography
              sx={{
                fontSize: '16px',
                fontWeight: 400,
                lineHeight: '19.6px',
                color: 'textColor.300',
                textAlign: 'start',
              }}
            >
              Spectra-Discovery uses advanced AI algorithms and uses various
              data sources such as Satellite Imagery, Google StreetView and
              LIDAR data to bring the most accurate Asset data.
            </Typography>
          </Box>
          <Box flex={1}>
            <Typography
              sx={{
                fontSize: '18px',
                fontWeight: 400,
                lineHeight: '24.3px',
                color: 'white.200',
                textAlign: 'start',
              }}
            >
              It is very easy to get started:
            </Typography>
          </Box>
          <Box
            display="flex"
            flexDirection="column"
            justifyContent="center"
            flex={4}
          >
            <Box flex={1} />
            {[
              'Step 1: Define a Project',
              'Step 2: Configure Inputs',
              'Step 3: Run the Analysis',
            ].map((item, index) => (
              <Box display="flex" flexDirection="row" flex={2} key={index}>
                <CheckIcon color="primary" />
                <Typography
                  pl="10px"
                  sx={{
                    fontSize: '16px',
                    fontWeight: 400,
                    lineHeight: '19.6px',
                    color: 'textColor.300',
                    textAlign: 'start',
                  }}
                >
                  {item}
                </Typography>
              </Box>
            ))}
            <Box flex={2} />
          </Box>
          <Box
            display="flex"
            flexDirection="column"
            justifyContent="center"
            alignItems="stretch"
          >
            <Button
              variant="contained"
              color="primary"
              size="large"
              onClick={() => {
                navigate('/projects')
              }}
            >
              Open
            </Button>
          </Box>
        </Box>
        <Box
          sx={{
            display: 'flex',
            flex: {
              xs: 0.5,
              sm: 0.5,
              md: 0.5,
              lg: 2,
              xl: 2,
            },
          }}
        />
        <Box display="flex" flexDirection="column" justifyContent="center">
          <Box
            display="flex"
            flexDirection="column"
            justifyContent="center"
            alignContent="stretch"
          >
            <img
              src="assets/pole_image.png"
              style={{
                objectFit: 'cover',
                borderRadius: '12px',
                height: !isSM
                  ? '100%'
                  : !isMD
                    ? '55vh'
                    : !isLG
                      ? '58vh'
                      : '65vh',
                width: !isSM
                  ? '100%'
                  : !isMD
                    ? '100%'
                    : !isLG
                      ? '38vw'
                      : '100%',
              }}
            />
          </Box>
        </Box>
      </Box>
      <Box display="flex" flex={2} />
    </Box>
  )
}

export const LauchpadView: FC = () => {
  return (
    <ProtectedComponent>
      <Lauchpad />
    </ProtectedComponent>
  )
}
