import { createTheme } from '@mui/material'
import { inputLabelClasses } from '@mui/material/InputLabel'
import Colors from './utils/colors'
import { SelectIcon } from './components/select_icon'

declare module '@mui/material/styles' {
  interface Palette {
    blue: Palette['primary']
    white: Palette['primary']
    textColor: Palette['primary']
    bg: Palette['primary']
    stroke: Palette['primary']
  }

  interface PaletteOptions {
    blue?: PaletteOptions['primary']
    white: Palette['primary']
    textColor: Palette['primary']
    bg: Palette['primary']
    stroke: Palette['primary']
  }
}
const { palette } = createTheme()

const theme = createTheme({
  typography: {
    fontFamily: ['"Spline Sans"', 'Roboto', 'Arial', 'sans-serif'].join(','),
  },
  palette: {
    primary: {
      main: Colors.primary100,
    },
    secondary: {
      main: Colors.secondary100,
      200: Colors.secondary200,
    },
    common: {
      white: Colors.white1,
    },
    warning: {
      main: Colors.warning,
    },
    success: {
      main: Colors.success,
    },
    error: {
      main: Colors.cancel,
    },
    text: {
      primary: Colors.white1,
      secondary: Colors.textSecondary,
      // tertiary: Colors.textTertiary,
    },
    background: {
      default: Colors.background,
      paper: Colors.bg1,
    },
    blue: palette.augmentColor({
      color: {
        100: Colors.blue100,
        50: Colors.blue50,
        main: Colors.blue30,
        200: Colors.greyBlue,
      },
      name: 'blue',
    }),
    textColor: palette.augmentColor({
      color: {
        main: Colors.textSecondary,
        200: Colors.textTertiary,
        300: Colors.textSecondary3,
      },
      name: 'textColor',
    }),
    bg: palette.augmentColor({
      color: {
        300: Colors.bg3,
        200: Colors.bg2,
        main: Colors.bg1,
      },
      name: 'bg',
    }),
    stroke: palette.augmentColor({
      color: {
        main: Colors.stroke,
      },
      name: 'stroke',
    }),
    white: palette.augmentColor({
      color: {
        main: Colors.white1,
        200: Colors.white2,
      },
      name: 'white',
    }),
  },
  components: {
    MuiFormControlLabel: {
      defaultProps: {
        sx: {
          '.MuiFormControlLabel-label': {
            fontSize: '14px',
            lineHeight: '19.6px',
            fontWeight: 400,
            color: Colors.white1,
          },
        },
      },
    },
    MuiInputLabel: {
      defaultProps: {
        sx: {
          color: Colors.textTertiary,
          fontWeight: 300,
          fontSize: '14px',
          lineHeight: '21px',
          [`&.${inputLabelClasses.shrink}`]: {
            color: Colors.textTertiary,
            fontWeight: 300,
            fontSize: '12px',
            lineHeight: '16.8px',
            letterSpacing: '0.02em',
          },
        },
      },
    },
    MuiInputBase: {
      defaultProps: {
        sx: {
          textAlign: 'start',
        },
      },
    },
    MuiButton: {
      defaultProps: {
        sx: {
          textTransform: 'none',
          borderRadius: '8px',
          '&.Mui-disabled': {
            background: 'rgba(40,80,191,0.55)',
            color: 'rgba(255,255,255,0.55)',
          },
        },
      },
    },
    MuiOutlinedInput: {
      styleOverrides: {
        root: {
          borderRadius: 10,
          fieldSet: {
            borderColor: Colors.bg3,
          },
          fontWeight: 300,
          fontSize: '14px',
          lineHeight: '21px',
          color: Colors.white2,
        },
      },
    },
    MuiSelect: {
      defaultProps: {
        IconComponent: SelectIcon,
      },
    },
    MuiRadio: {
      defaultProps: {
        sx: {
          color: Colors.white1,
        },
      },
    },
    MuiCheckbox: {
      defaultProps: {
        sx: {
          color: Colors.stroke,
          '&.Mui-checked': {
            color: Colors.blue100,
          },
          '&.MuiCheckbox-colorSecondary': {
            color: 'red',
          },
        },
      },
    },
  },
})

export default theme
