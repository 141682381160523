import { createSlice } from '@reduxjs/toolkit'
import type { PayloadAction } from '@reduxjs/toolkit'
import type { RootState } from './store'

// Define a type for the slice state
interface MappingState {
  nameColumn: string
  latColumn: string
  longColumn: string

  clusterColumn: string
  marketColumn: string
  regionColumn: string
  countryColumn: string
}

// Define the initial state using that type
const initialState: MappingState = {
  nameColumn: '',
  latColumn: '',
  longColumn: '',
  clusterColumn: '',
  marketColumn: '',
  regionColumn: '',
  countryColumn: '',
}

export const mappingSlice = createSlice({
  name: 'mapping',
  // `createSlice` will infer the state type from the `initialState` argument
  initialState,
  reducers: {
    updateNameColumn: (state, action: PayloadAction<string>) => {
      state.nameColumn = action.payload
    },
    updateLatColumn: (state, action: PayloadAction<string>) => {
      state.latColumn = action.payload
    },
    updateLongColumn: (state, action: PayloadAction<string>) => {
      state.longColumn = action.payload
    },
    updateClusterColumn: (state, action: PayloadAction<string>) => {
      state.clusterColumn = action.payload
    },
    updateMarketColumn: (state, action: PayloadAction<string>) => {
      state.marketColumn = action.payload
    },
    updateRegionColumn: (state, action: PayloadAction<string>) => {
      state.regionColumn = action.payload
    },
    updateCountryColumn: (state, action: PayloadAction<string>) => {
      state.countryColumn = action.payload
    },
    reset: (state) => {
      Object.assign(state, initialState)
    },
  },
})

export const {
  updateNameColumn,
  updateLatColumn,
  updateLongColumn,
  updateClusterColumn,
  updateMarketColumn,
  updateRegionColumn,
  updateCountryColumn,
  reset,
} = mappingSlice.actions
export const mappingSelector = (state: RootState) => state.mappingReducer

export default mappingSlice.reducer
