import React, { type FC } from 'react'
import { ProtectedComponent } from '../components/protected_route'
import {
  Box,
  Button,
  CircularProgress,
  IconButton,
  InputAdornment,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TextField,
  Typography,
} from '@mui/material'
import SearchIcon from '../assets/svgs/search.svg'
import NotificationIcon from '../assets/svgs/notification.svg'
import CreateProjectImg from '../assets/svgs/create_pro_img.svg'
import Colors from '../utils/colors'
import { useNavigate } from 'react-router-dom'
import { reset } from '../store/new_project'
import { reset as reportReset } from '../store/gen_report'
import { reset as inputMappingReset } from '../store/input_mapping'
import {
  useGetAllProjectsQuery,
  useGetProjectQuery,
} from '../services/project_services'
import { useAppDispatch } from '../store/hooks'
import { formatDate, notNull, validValue } from '../utils/functions'

const Project: React.FC = () => {
  const navigate = useNavigate()
  const [search, setSearch] = React.useState('')
  const { data, isLoading } = useGetAllProjectsQuery(
    {},
    {
      selectFromResult: ({ data, isLoading }) => {
        let filters = data?.filter((project) =>
          search.length < 1
            ? true
            : project.name.toLowerCase().includes(search.trim().toLowerCase())
        )
        filters = filters?.sort((a, b) => b.id - a.id)
        return { data: filters, isLoading }
      },
    }
  )
  const [projectId, setProjectId] = React.useState('')
  // eslint-disable-next-line
  const { data: project, isLoading: projectLoading } = useGetProjectQuery(
    projectId?.toString() ?? '',
    { skip: !validValue(projectId) }
  )
  const dispatch = useAppDispatch()

  React.useEffect(() => {
    if (project === null || !notNull(project?.id)) return
    if ((project?.files?.length ?? 0) <= 0) {
      setTimeout(() => {
        navigate(`/project_input?project=${project?.id}`)
      }, 100)
      return
    }
    // eslint-disable-next-line
    const file = project!.files[0]
    if (!file.is_triggered || file.process_status === 'Completed') {
      const param = file.process_status === 'Completed' ? '&output=true' : ''
      setTimeout(() => {
        // eslint-disable-next-line
        navigate(`/running_project?project=${project!.id}${param}`)
      }, 100)
    } else {
      setTimeout(() => {
        // eslint-disable-next-line
        navigate(`/run_analysis?project=${project!.id}`)
      }, 100)
    }
  }, [project])

  const toNewProject = React.useCallback(() => {
    dispatch(reset())
    dispatch(reportReset())
    dispatch(inputMappingReset())
    navigate('/new_project')
  }, [navigate])

  const toProjectDetails = React.useCallback(
    (id: number) => {
      setProjectId(id.toString())
    },
    [setProjectId]
  )

  if (isLoading) {
    return (
      <Box
        display="flex"
        flex={1}
        flexDirection="column"
        justifyContent="center"
        alignItems="center"
        alignContent="center"
      >
        <CircularProgress />
      </Box>
    )
  }

  return (
    <Box
      display="flex"
      flexDirection="column"
      className="h-screen"
      sx={{
        overflow: 'hidden',
        height: '93vh',
        px: {
          xs: '16px',
          sm: '24px',
          md: '32px',
          lg: '40px',
        },
      }}
    >
      <Box display="flex" flex={2}>
        <Box
          flex={{
            xs: 1,
            sm: 2,
            md: 3,
            lg: 5.8,
            xl: 5.8,
          }}
          display="flex"
          flexDirection="row"
          justifyContent="start"
        >
          <Typography
            sx={{
              fontSize: '24px',
              fontWeight: 400,
              lineHeight: '33.4px',
              color: 'white.200',
            }}
          >
            Projects
          </Typography>
        </Box>
        <Box
          flex={{
            xs: 1,
            sm: 1.8,
            md: 2.2,
            lg: 3,
            xl: 3,
          }}
          display="flex"
          flexDirection="column"
          alignContent="stretch"
        >
          <TextField
            placeholder="Search"
            variant="outlined"
            size="small"
            onChange={(event) => {
              setSearch(event.target.value)
            }}
            InputProps={{
              style: {
                backgroundColor: Colors.bg2,
              },
              startAdornment: (
                <InputAdornment position="start">
                  <img src={SearchIcon} />
                </InputAdornment>
              ),
            }}
          />
        </Box>
        <Box flex={1.4}>
          <Button
            variant="contained"
            onClick={() => {
              toNewProject()
            }}
          >
            Create Project
          </Button>
        </Box>
        <Box
          flex={0.5}
          display="flex"
          flexDirection="column"
          justifyContent="start"
          alignItems="center"
        >
          <IconButton
            sx={{
              borderRadius: '8px',
              backgroundColor: 'bg.200',
            }}
          >
            <img src={NotificationIcon} />
          </IconButton>
        </Box>
      </Box>

      <Box
        display="flex"
        flex={25}
        sx={{
          overflowY: 'scroll',
          maxHeight: '90vh',
        }}
      >
        {isLoading ? (
          <Box
            display="flex"
            flex={1}
            flexDirection="column"
            justifyContent="center"
            alignItems="center"
            alignContent="center"
          >
            <CircularProgress />
          </Box>
        ) : null}
        {(data?.length ?? 0) === 0 ? (
          <Box
            display="flex"
            flex={1}
            flexDirection="column"
            justifyContent="center"
          >
            <Box>
              <img src={CreateProjectImg} alt="create project" />
            </Box>
            <Box pt="8px">
              <Typography
                sx={{
                  fontSize: '24px',
                  fontWeight: 600,
                  lineHeight: '33.4px',
                }}
              >
                Create your first project
              </Typography>
            </Box>
            <Box pt="6px">
              <Typography
                sx={{
                  fontSize: '14px',
                  fontWeight: 400,
                  lineHeight: '19.6px',
                  color: 'textColor.300',
                }}
              >
                Define inputs, run analysis, and visualize results.
              </Typography>
            </Box>
            <Box pt="20px">
              <Button
                variant="contained"
                onClick={() => {
                  toNewProject()
                }}
              >
                Create Project
              </Button>
            </Box>
          </Box>
        ) : null}
        {data?.length !== undefined && data?.length > 0 ? (
          <TableContainer sx={{ flex: 95, borderRadius: '20px' }}>
            <Table stickyHeader size="small" aria-label="data-table">
              <TableHead>
                <TableRow
                  sx={{
                    '& th': {
                      backgroundColor: 'bg.200',
                      fontSize: '14px',
                      fontWeight: 400,
                      lineHeight: '19.6px',
                      py: '15px',
                      textAlign: 'start',
                      border: 0,
                    },
                  }}
                >
                  <TableCell>Project ID</TableCell>
                  <TableCell>Project Name</TableCell>
                  <TableCell>Start date</TableCell>
                  <TableCell>Last run</TableCell>
                  <TableCell>Total assets analyzed</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {data?.map((row) => {
                  return (
                    <TableRow
                      onClick={toProjectDetails.bind(null, row.id)}
                      key={row.id}
                      sx={{
                        '& td': {
                          backgroundColor: 'bg.300',
                          fontSize: '14px',
                          fontWeight: 400,
                          lineHeight: '19.6px',
                          textAlign: 'start',
                          border: 0,
                          py: '12px',
                        },
                      }}
                    >
                      <TableCell
                        key={`${row.id}_id`}
                        sx={{ color: 'textColor.300' }}
                      >
                        {row.id}
                      </TableCell>
                      <TableCell
                        key={`${row.id}_name`}
                        sx={{ color: 'textColor.300' }}
                      >
                        {row.name}
                      </TableCell>
                      <TableCell
                        key={`${row.id}+created_at`}
                        sx={{
                          color: 'textColor.300',
                        }}
                      >
                        {formatDate(row.created_at)}
                      </TableCell>
                      <TableCell
                        key={`${row.id}+updated_at`}
                        sx={{
                          color: 'textColor.300',
                        }}
                      >
                        {formatDate(row.updated_at)}
                      </TableCell>
                      <TableCell
                        key={`${row.id}+total_assets_analyzed_count`}
                        sx={{
                          color: 'textColor.300',
                        }}
                      >
                        {row.total_assets_analyzed_count}
                      </TableCell>
                    </TableRow>
                  )
                })}
              </TableBody>
            </Table>
          </TableContainer>
        ) : null}
      </Box>
    </Box>
  )
}

export const ProjectView: FC = () => {
  return (
    <ProtectedComponent showSidebarOptions>
      <Project />
    </ProtectedComponent>
  )
}
