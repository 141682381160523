import { createSlice } from '@reduxjs/toolkit'
import type { PayloadAction } from '@reduxjs/toolkit'
import type { RootState } from './store'

// Define a type for the slice state
interface SignupState {
  email: string
  firstName: string
  lastName: string
  password: string
}

// Define the initial state using that type
const initialState: SignupState = {
  email: '',
  firstName: '',
  lastName: '',
  password: '',
}

export const signupSlice = createSlice({
  name: 'signup',
  // `createSlice` will infer the state type from the `initialState` argument
  initialState,
  reducers: {
    updateEmail: (state, action: PayloadAction<string>) => {
      state.email = action.payload
    },
    updateFistName: (state, action: PayloadAction<string>) => {
      state.firstName = action.payload
    },
    updateLastName: (state, action: PayloadAction<string>) => {
      state.lastName = action.payload
    },
    updatePassword: (state, action: PayloadAction<string>) => {
      state.password = action.payload
    },
    reset: (state) => {
      Object.assign(state, initialState)
    },
  },
})

export const { updateEmail, updateFistName, updateLastName, updatePassword } =
  signupSlice.actions
export const signupSelector = (state: RootState) => state.signupReducer

export default signupSlice.reducer
