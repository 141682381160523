import React, { type FC } from 'react'
import SpectraDiscovery from '../assets/svgs/spectra_disc.svg'
import { useNavigate } from 'react-router-dom'
import Spline from '@splinetool/react-spline'
import {
  Box,
  Button,
  CircularProgress,
  Grid,
  IconButton,
  InputAdornment,
  Link,
  TextField,
  Typography,
  useMediaQuery,
} from '@mui/material'
import { useAppSelector, useAppDispatch } from '../store/hooks'
import type { Signup } from '../types/signup_request'
import {
  signupSelector,
  updateEmail,
  updateFistName,
  updateLastName,
  updatePassword,
} from '../store/signup_store'
import { Visibility, VisibilityOff } from '@mui/icons-material'
import { isValidEmail } from '../utils/functions'
import { useSignupMutation } from '../services/auth_service'
import { errorToast, successToast } from '../utils/toast'

export const SignupView: FC = () => {
  const signup = useAppSelector(signupSelector)
  const dispatch = useAppDispatch()
  const navigate = useNavigate()
  const [showPassword, setShowPassword] = React.useState(false)
  const [isMailValid, setIsEmailValid] = React.useState<boolean | null>(null)
  const [isPasswordValid, setIsPasswordValid] = React.useState<boolean | null>(
    null
  )

  const [signupCall, { isLoading }] = useSignupMutation()
  // @ts-expect-error unknown type
  const isSM = useMediaQuery((theme) => theme.breakpoints.up('sm'))
  // @ts-expect-error unknown type
  const isMD = useMediaQuery((theme) => theme.breakpoints.up('md'))
  // @ts-expect-error unknown type
  const isLG = useMediaQuery((theme) => theme.breakpoints.up('lg'))

  function onLoad(spline: any) {
    let zoom = 0.68
    if (!isSM) {
      zoom = 0.2
    } else if (!isMD) {
      zoom = 0.6
    } else if (!isLG) {
      zoom = 0.7
    }
    spline.setZoom(zoom)
  }

  function isFromValid() {
    if (!isValidEmail(signup.email)) {
      setIsEmailValid(false)
      return false
    } else {
      setIsEmailValid(true)
    }
    if (signup.password.length < 8) {
      setIsPasswordValid(false)
      return false
    } else {
      setIsPasswordValid(true)
    }
    return true
  }

  function signUp(e: any) {
    e.preventDefault()
    const isValid = isFromValid()

    if (isValid) {
      const data: Signup = {
        email: signup.email,
        first_name: signup.firstName,
        last_name: signup.lastName,
        password: signup.password,
        is_active: true,
        is_superuser: false,
        tenant_id: null,
        role_id: null,
      }
      signupCall(data)
        .unwrap()
        .then((res) => {
          successToast('Signup successful')
          setTimeout(() => {
            navigate('/login')
          }, 100)
        })
        .catch((err) => {
          const errMsg = err != null && 'data' in err ? err.data : {}
          const msg = errMsg?.detail ?? 'Some error occured'

          errorToast(`${msg}`)
        })
    }
  }

  return (
    <Grid container className="h-screen">
      <Grid md={6} pt={2} pl={10} spacing={0}>
        <Box mb={1} display="flex">
          <img src={SpectraDiscovery} alt="Spectra Discovery" />
        </Box>
        <Box
          display="flex"
          justifyContent="center"
          alignItems="center"
          sx={{ height: '80vh', pointerEvents: 'none' }}
        >
          <Spline
            onLoad={onLoad}
            scene="https://prod.spline.design/VB3dtUmFvmLibQl7/scene.splinecode"
          />
        </Box>
      </Grid>
      <Grid
        md={6}
        pl={'8vw'}
        pr={'12vw'}
        sx={{
          display: 'flex',
          flexDirection: 'column',
          justifyContent: 'center',
          alignContent: 'center',
        }}
      >
        <Box
          component="form"
          noValidate
          autoComplete="off"
          sx={{
            display: 'flex',
            flex: 3,
            flexDirection: 'column',
            justifyContent: 'end',
            alignContent: 'center',
          }}
        >
          <Typography
            variant="h5"
            color="white.main"
            mb={'3.5vh'}
            textAlign={'start'}
          >
            Create your account
          </Typography>
          <TextField
            label="Enter your email"
            variant="outlined"
            type="email"
            size="small"
            error={signup.email.length > 0 && isMailValid === false}
            helperText={
              signup.email.length > 0 && isMailValid === false
                ? 'Please enter a valid email address'
                : ''
            }
            sx={{ mb: 2 }}
            onChange={(e) => {
              dispatch(updateEmail(e.target.value))
              if (isMailValid === false) {
                isFromValid()
              }
            }}
          />
          <TextField
            label="Enter your first name"
            variant="outlined"
            placeholder="e.g., John"
            size="small"
            sx={{ mb: 2 }}
            onChange={(e) => {
              dispatch(updateFistName(e.target.value))
            }}
          />
          <TextField
            label="Enter your last name"
            variant="outlined"
            placeholder="e.g., Conner"
            size="small"
            sx={{ mb: 2 }}
            onChange={(e) => {
              dispatch(updateLastName(e.target.value))
            }}
          />
          <TextField
            label="Create password"
            variant="outlined"
            size="small"
            placeholder="At least 8 characters"
            sx={{ mb: 2 }}
            error={isPasswordValid === false}
            helperText={
              isPasswordValid === false
                ? 'Password must be at least 8 characters'
                : ''
            }
            onChange={(e) => {
              dispatch(updatePassword(e.target.value))
              if (isPasswordValid === false) {
                isFromValid()
              }
            }}
            type={showPassword ? 'text' : 'password'}
            InputProps={{
              endAdornment: (
                <InputAdornment position="end">
                  <IconButton
                    aria-label="toggle password visibility"
                    onClick={() => {
                      setShowPassword((prev) => !prev)
                    }}
                    edge="end"
                  >
                    {showPassword ? (
                      <VisibilityOff
                        sx={{ color: 'textColor.main', fontSize: 15 }}
                      />
                    ) : (
                      <Visibility
                        sx={{ color: 'textColor.main', fontSize: 15 }}
                      />
                    )}
                  </IconButton>
                </InputAdornment>
              ),
            }}
          />
          <Box
            display="flex"
            flexDirection="row"
            sx={{
              flexWrap: 'wrap',
              mb: 3,
            }}
          >
            <Typography
              color="textColor.main"
              sx={{
                fontSize: '14px',
                lineHeight: '19.6px',
                fontWeight: 400,
              }}
            >
              By creating an account, you agree to our&nbsp;
            </Typography>
            <Typography
              color="primary.main"
              sx={{ fontSize: '14px', lineHeight: '19.6px', fontWeight: 400 }}
            >
              Terms of Service&nbsp;
            </Typography>
            <Typography
              color="textColor.main"
              sx={{ fontSize: '14px', lineHeight: '19.6px', fontWeight: 400 }}
            >
              and{'\n'}
            </Typography>
            <Typography
              color="primary.main"
              sx={{ fontSize: '14px', lineHeight: '19.6px', fontWeight: 400 }}
            >
              Privacy & Cookie Statement
            </Typography>
          </Box>
          {isLoading ? (
            <Box display="flex" flexDirection="row" justifyContent="center">
              <CircularProgress />
            </Box>
          ) : (
            <Button
              variant="contained"
              disabled={
                signup.password.length < 8 &&
                signup.firstName.length === 0 &&
                signup.lastName.length === 0 &&
                signup.email.length === 0
              }
              onClick={signUp}
            >
              Sign up
            </Button>
          )}
        </Box>
        <Box
          sx={{
            flex: 1,
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'end',
            alignItems: 'start',
          }}
          mb={2}
        >
          <Typography
            sx={{ fontSize: '14px', lineHeight: '19.6px', fontWeight: 400 }}
          >
            Already have an account?{' '}
            <Typography
              color="primary.main"
              component="span"
              sx={{ fontSize: '14px', lineHeight: '19.6px', fontWeight: 400 }}
            >
              <Link href="/login" underline="none">
                Log in
              </Link>
            </Typography>
          </Typography>
        </Box>
      </Grid>
    </Grid>
  )
}
