import {
  Box,
  Button,
  CircularProgress,
  IconButton,
  InputAdornment,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TextField,
  Typography,
} from '@mui/material'
import React, { type FC } from 'react'
import { ProtectedComponent } from '../components/protected_route'
import { useNavigate } from 'react-router-dom'
import {
  useGetAllProjectsQuery,
  useGetFileMutation,
} from '../services/project_services'
import {
  downloadCSVLines,
  formatDate,
  notNull,
  parsePolygonComp,
} from '../utils/functions'
import { useAppDispatch } from '../store/hooks'
import { reset } from '../store/new_project'
import { reset as reportReset } from '../store/gen_report'
import { reset as inputMappingReset } from '../store/input_mapping'
import type { NewProjectResponse } from '../types/new_pro_request'
import { errorToast, successToast } from '../utils/toast'
import Colors from '../utils/colors'
import SearchIcon from '../assets/svgs/search.svg'
import NotificationIcon from '../assets/svgs/notification.svg'
import { ReactComponent as DownloadIcon } from '../assets/svgs/download_icon.svg'

const Imports: React.FC = () => {
  const navigate = useNavigate()
  const [search, setSearch] = React.useState('')
  const { data, isLoading } = useGetAllProjectsQuery(
    {},
    {
      selectFromResult: ({ data, isLoading }) => {
        let filtered =
          data?.filter((project) =>
            search.length < 1
              ? true
              : project.name.toLowerCase().includes(search.trim().toLowerCase())
          ) ?? []
        filtered = filtered.filter(
          (project) => notNull(project.files) && project.files.length > 0
        )
        return {
          data: filtered,
          isLoading,
        }
      },
    }
  )
  const [getFile, { isLoading: isFetchingFile }] = useGetFileMutation()

  const dispatch = useAppDispatch()

  const toNewProject = React.useCallback(() => {
    dispatch(reset())
    dispatch(reportReset())
    dispatch(inputMappingReset())
    navigate('/new_project')
  }, [navigate])

  const downloadFile = (pro: NewProjectResponse) => {
    const f = pro.files[0]
    if (pro.is_polygon) {
      const element = document.createElement('a')
      const polyJson = JSON.stringify(
        parsePolygonComp(pro.polygon_coordinates),
        null,
        2
      )
      const file = new Blob([polyJson], {
        type: 'text/plain',
      })
      element.href = URL.createObjectURL(file)
      const fileName = `${f.original_file_name}`.replaceAll(' ', '_')
      element.download = `${fileName}_input.json`
      document.body.appendChild(element) // Required for this to work in FireFox
      element.click()
      successToast('Import downloaded successfully')
      return
    }

    getFile(f.id?.toString())
      .unwrap()
      .then((response: any) => {
        // eslint-disable-next-line
        downloadCSVLines(response.trimmed_s3_path)
          .then((data) => {
            // eslint-disable-next-line
            const [d, csvData] = data
            const element = document.createElement('a')
            const file = new Blob([csvData.toString()], {
              type: 'text/plain',
            })
            element.href = URL.createObjectURL(file)
            const fileName = `${f.original_file_name}`.replaceAll(' ', '_')
            element.download = `${fileName}_input.csv`
            document.body.appendChild(element) // Required for this to work in FireFox
            element.click()
            successToast('Import downloaded successfully')
          })
          .catch((error) => {
            errorToast('Error downloading or parsing CSV')
            console.error('Error downloading or parsing CSV:', error)
          })
      })
      .catch((err: any) => {
        const errMsg = err != null && 'data' in err ? err.data : {}
        const msg = errMsg?.detail ?? 'Some error occurred'

        errorToast(`${msg}`)
      })
  }

  if (isLoading) {
    return (
      <Box
        display="flex"
        justifyContent="center"
        alignItems="center"
        className="h-screen"
      >
        <CircularProgress />
      </Box>
    )
  }

  return (
    <Box
      display="flex"
      flexDirection="column"
      className="h-screen"
      sx={{
        overflow: 'hidden',
        height: '93vh',
        px: {
          xs: '16px',
          sm: '24px',
          md: '32px',
          lg: '40px',
        },
      }}
    >
      <Box display="flex" flex={2}>
        <Box
          flex={{
            xs: 1,
            sm: 2,
            md: 3,
            lg: 5.8,
            xl: 5.8,
          }}
          display="flex"
          flexDirection="row"
          justifyContent="start"
        >
          <Typography
            sx={{
              fontSize: '24px',
              fontWeight: 400,
              lineHeight: '33.4px',
              color: 'white.200',
            }}
          >
            Imports
          </Typography>
        </Box>
        <Box
          flex={{
            xs: 1,
            sm: 1.8,
            md: 2.2,
            lg: 3,
            xl: 3,
          }}
          display="flex"
          flexDirection="column"
          alignContent="stretch"
        >
          <TextField
            placeholder="Search"
            variant="outlined"
            size="small"
            onChange={(event) => {
              setSearch(event.target.value)
            }}
            InputProps={{
              style: {
                backgroundColor: Colors.bg2,
              },
              startAdornment: (
                <InputAdornment position="start">
                  <img src={SearchIcon} />
                </InputAdornment>
              ),
            }}
          />
        </Box>
        <Box flex={1.4}>
          <Button
            variant="contained"
            onClick={() => {
              toNewProject()
            }}
          >
            Create Project
          </Button>
        </Box>
        <Box
          flex={0.5}
          display="flex"
          flexDirection="column"
          justifyContent="start"
          alignItems="center"
        >
          <IconButton
            sx={{
              borderRadius: '8px',
              backgroundColor: 'bg.200',
            }}
          >
            <img src={NotificationIcon} />
          </IconButton>
        </Box>
      </Box>

      <Box
        display="flex"
        flex={25}
        sx={{
          overflowY: 'scroll',
          maxHeight: '90vh',
        }}
      >
        {data?.length === 0 ? (
          <Box
            display="flex"
            flex={1}
            flexDirection="column"
            justifyContent="center"
          >
            <Box pt="8px">
              <Typography
                sx={{
                  fontSize: '24px',
                  fontWeight: 600,
                  lineHeight: '33.4px',
                }}
              >
                No Imports available
              </Typography>
            </Box>
            <Box pt="6px">
              <Typography
                sx={{
                  fontSize: '14px',
                  fontWeight: 400,
                  lineHeight: '19.6px',
                  color: 'textColor.300',
                }}
              >
                Create and run new project to see imports.
              </Typography>
            </Box>
            <Box pt="20px">
              <Button
                variant="contained"
                onClick={() => {
                  toNewProject()
                }}
              >
                Create Project
              </Button>
            </Box>
          </Box>
        ) : null}
        {data?.length !== undefined && data?.length > 0 ? (
          <TableContainer sx={{ flex: 95, borderRadius: '20px' }}>
            <Table stickyHeader size="small" aria-label="data-table">
              <TableHead>
                <TableRow
                  sx={{
                    '& th': {
                      backgroundColor: 'bg.200',
                      fontSize: '14px',
                      fontWeight: 400,
                      lineHeight: '19.6px',
                      py: '15px',
                      textAlign: 'start',
                      border: 0,
                    },
                  }}
                >
                  <TableCell>File Name</TableCell>
                  <TableCell>Date Uploaded</TableCell>
                  <TableCell>Format</TableCell>
                  <TableCell>Type</TableCell>
                  <TableCell></TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {data?.map((row) => {
                  return (
                    <TableRow
                      key={row.id}
                      sx={{
                        '& td': {
                          backgroundColor: 'bg.300',
                          fontSize: '14px',
                          fontWeight: 400,
                          lineHeight: '19.6px',
                          textAlign: 'start',
                          border: 0,
                          py: '12px',
                        },
                      }}
                    >
                      <TableCell
                        key={`${row.id}_id`}
                        sx={{ color: 'textColor.300' }}
                      >
                        {row.files[0].original_file_name}
                      </TableCell>
                      <TableCell
                        key={`${row.id}_name`}
                        sx={{ color: 'textColor.300' }}
                      >
                        {formatDate(row.files[0].created_at)}
                      </TableCell>
                      <TableCell
                        key={`${row.id}+created_at`}
                        sx={{
                          color: 'textColor.300',
                        }}
                      >
                        {row.is_polygon ? 'Polygon' : 'CSV'}
                      </TableCell>
                      <TableCell
                        key={`${row.id}+type`}
                        sx={{
                          color: 'textColor.300',
                        }}
                      >
                        {row.is_polygon
                          ? 'Input by polygon draw'
                          : 'Lat Long Input file'}
                      </TableCell>
                      <TableCell key={`${row.id}+download`}>
                        <IconButton
                          onClick={() => {
                            if (isFetchingFile) return
                            downloadFile(row)
                          }}
                        >
                          <DownloadIcon />
                        </IconButton>
                      </TableCell>
                    </TableRow>
                  )
                })}
              </TableBody>
            </Table>
          </TableContainer>
        ) : null}
      </Box>
    </Box>
  )
}

export const ImportsView: FC = () => {
  return (
    <ProtectedComponent showSidebarOptions>
      <Imports />
    </ProtectedComponent>
  )
}
