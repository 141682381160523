import React, { type FC } from 'react'
import { ProtectedComponent } from '../components/protected_route'
import { Box, Button, IconButton, Stack, Typography } from '@mui/material'
import C3SpectraAssetDetLogo from '../assets/svgs/spectra_asset_logo.svg'
import CloseIcon from '@mui/icons-material/Close'
import WestIcon from '@mui/icons-material/West'
import ProjectStep from '../components/project_step'
import { useNavigate } from 'react-router-dom'
import InputDrawIcon from '../assets/svgs/input_draw_icon.svg'
import InputUploadIcon from '../assets/svgs/input_upload_icon.svg'
import {
  clearCSVMapping,
  setSampleCSV,
  setSelectedFile,
} from '../store/new_project'
import { errorToast, successToast, warningToast } from '../utils/toast'
import { TextReader } from '../utils/text_reader'
import { useAppDispatch } from '../store/hooks'
import { useGetProjectQuery } from '../services/project_services'
import { useQuery } from '../utils/parsers'
import { reset as inputMappingReset } from '../store/input_mapping'
import { sampleCSV } from '../utils/constants'

const MaxLineToRead = 50

const NewProjectInput: FC = () => {
  const query = useQuery()
  const projectId = query.get('project')
  const navigate = useNavigate()
  const inputFile = React.useRef<any>(null)
  const dispatch = useAppDispatch()
  // eslint-disable-next-line
  const { data: project, isLoading: projectLoading } = useGetProjectQuery(
    projectId?.toString() ?? ''
  )

  React.useEffect(() => {
    if (projectId == null) {
      setTimeout(() => {
        navigate('/projects')
      }, 500)
    }
  }, [projectId])

  const downloadSample = () => {
    const element = document.createElement('a')
    const file = new Blob([sampleCSV], {
      type: 'text/plain',
    })
    element.href = URL.createObjectURL(file)
    element.download = 'SD-Sample-Input-File.csv'
    document.body.appendChild(element) // Required for this to work in FireFox
    element.click()
    successToast('Sample downloaded successfully')
  }

  const onUpload = async (file: File) => {
    if (file?.size <= 0) {
      errorToast('Invalid or File is empty')
      return
    }
    dispatch(setSelectedFile(file))
    const lines: string[][] = []
    const textReader = new TextReader(file)
    while (!textReader.endOfStream && lines.length <= MaxLineToRead) {
      const line = await textReader.readLine()
      if (line?.length > 0) {
        console.log(line, typeof line)
        lines.push(line.split(',') as string[])
      }
    }
    dispatch(setSampleCSV(lines))
    dispatch(inputMappingReset())
    dispatch(clearCSVMapping())
    setTimeout(() => {
      navigate(`/pro_input_mapping?project=${projectId}`)
    }, 100)
  }

  return (
    <Box display="flex" flexDirection="column" className="h-screen">
      <Box display="flex" flexDirection="row" px="18px" pt="15px" pb="25px">
        <Box
          flex={3}
          display="flex"
          flexDirection="row"
          justifyContent="start"
          alignItems="start"
        >
          <img src={C3SpectraAssetDetLogo} alt="Spectra Discovery" />
        </Box>
        <Box flex={12} display="flex" flexDirection="column" alignItems="start">
          <Typography
            sx={{
              fontSize: '24px',
              fontWeight: 600,
              lineHeight: '32.4px',
              color: 'white.main',
            }}
          >
            New project
          </Typography>
          <Box display="flex" flexDirection="row" pt="15px">
            <Typography
              sx={{
                fontSize: '14px',
                fontWeight: 300,
                lineHeight: '22.4px',
                color: 'textColor.300',
              }}
            >
              Need a sample CSV file?&nbsp;
            </Typography>
            <Typography
              onClick={() => {
                downloadSample()
              }}
              sx={{
                fontSize: '14px',
                fontWeight: 400,
                lineHeight: '18.9px',
                color: 'blue.100',
                textDecoration: 'underline',
                cursor: 'pointer',
              }}
            >
              Download one here.
            </Typography>
          </Box>
        </Box>
        <Box
          flex={1}
          display="flex"
          flexDirection="column"
          justifyContent="start"
          alignItems="end"
        >
          <IconButton
            sx={{
              borderRadius: '8px',
              backgroundColor: 'bg.200',
            }}
          >
            <CloseIcon sx={{ color: 'textColor.main', fontSize: '15px' }} />
          </IconButton>
        </Box>
      </Box>
      <Box display="flex" flexDirection="row" alignItems="start" flexGrow={1}>
        <Box flex={3} />
        <Box
          flex={10}
          display="flex"
          flexDirection="row"
          justifyItems="space-between"
        >
          <Stack
            flex={11}
            bgcolor="bg.200"
            borderRadius="20px"
            direction="column"
            justifyContent="space-between"
            alignItems="stretch"
            padding="32px"
            minHeight="280px"
          >
            <Box display="flex" alignContent="start">
              <img src={InputUploadIcon} alt="Draw Icon" />
            </Box>
            <Box>
              <Typography
                sx={{
                  fontSize: '16px',
                  fontWeight: 400,
                  lineHeight: '24px',
                  color: 'white.main',
                  textAlign: 'start',
                }}
              >
                Upload CSV
              </Typography>
            </Box>
            <Box>
              <Typography
                sx={{
                  color: 'textColor.300',
                  fontWeight: 300,
                  fontSize: '14px',
                  lineHeight: '22.4px',
                  textAlign: 'start',
                }}
              >
                Upload existing data of your assets with specific ID,
                Coordinates etc. Download the sample file for reference or
                select a folder.
              </Typography>
            </Box>
            <input
              type="file"
              id="file"
              ref={inputFile}
              accept=".csv"
              style={{ display: 'none' }}
              onChange={(e) => {
                if (e.target.files != null && e.target.files.length > 0) {
                  onUpload(e.target.files[0])
                    .then(() => {})
                    .catch(() => {})
                } else {
                  warningToast('No file selected')
                }
              }}
            />
            <Box display="flex" alignContent="start">
              <Button
                variant="contained"
                sx={{
                  bgcolor: 'bg.300',
                  textTransform: 'none',
                  borderRadius: '8px',
                  color: 'textColor.300',
                }}
                onClick={() => {
                  inputFile.current.click()
                }}
              >
                Upload
              </Button>
            </Box>
          </Stack>
          <Box flex={1} />
          <Stack
            flex={11}
            bgcolor="bg.200"
            borderRadius="20px"
            direction="column"
            justifyContent="space-between"
            alignItems="stretch"
            padding="32px"
            minHeight="280px"
          >
            <Box display="flex" alignContent="start">
              <img src={InputDrawIcon} alt="Draw Icon" />
            </Box>
            <Box>
              <Typography
                sx={{
                  fontSize: '16px',
                  fontWeight: 400,
                  lineHeight: '24px',
                  color: 'white.main',
                  textAlign: 'start',
                }}
              >
                Draw the area on the map
              </Typography>
            </Box>
            <Box>
              <Typography
                sx={{
                  color: 'textColor.300',
                  fontWeight: 300,
                  fontSize: '14px',
                  lineHeight: '22.4px',
                  textAlign: 'start',
                }}
              >
                If you do not have an Input file, you may draw an area on the
                Map for AI engine to discover Assets of the selected type.
              </Typography>
            </Box>
            <Box display="flex" alignContent="start">
              <Button
                variant="contained"
                sx={{
                  bgcolor: 'bg.300',
                  textTransform: 'none',
                  borderRadius: '8px',
                  color: 'textColor.300',
                }}
                onClick={() => {
                  navigate(`/draw_input?project=${projectId}`)
                }}
              >
                Draw
              </Button>
            </Box>
          </Stack>
        </Box>
        <Box flex={2} />
      </Box>
      <Box
        display="flex"
        flexDirection="row"
        justifyContent="stretch"
        mt="30px"
        minHeight="88px"
      >
        <Box
          flex={3}
          display="flex"
          flexDirection="row"
          justifyContent="center"
          alignItems="center"
        >
          <Button
            variant="contained"
            startIcon={<WestIcon sx={{ color: 'textColor.300' }} />}
            sx={{
              bgcolor: 'bg.300',
              textTransform: 'none',
              borderRadius: '8px',
              color: 'textColor.300',
              minWidth: '109px',
            }}
            onClick={() => {
              navigate(-1)
            }}
          >
            Back
          </Button>
        </Box>
        <Box
          flex={9}
          display="flex"
          flexDirection="row"
          justifyContent="stretch"
        >
          <ProjectStep step={2} />
        </Box>
        <Box flex={4} />
      </Box>
    </Box>
  )
}

export const NewProjectInputView: FC = () => {
  return (
    <ProtectedComponent noSidebar>
      <NewProjectInput />
    </ProtectedComponent>
  )
}
