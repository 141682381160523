import { Box, Typography, useTheme } from '@mui/material'
import React from 'react'
import {
  AssetDetectionIcon,
  PalSubleasingIcon,
  Six6GhzIcon,
  SharedSpectrumIcon,
} from './launchpad_icons'

export enum IconType {
  pole_detec = 'pole_detec',
  six_6ghz = 'six_6ghz',
  pale_subleasing = 'pale_subleasing',
  shared_spectrum = 'shared_spectrum',
}

interface Props {
  name: string
  icon: IconType
  selected?: boolean
}

const LauchpadTabButton: React.FC<Props> = ({ name, icon, selected }) => {
  const theme = useTheme()
  return (
    <Box
      display="flex"
      flexDirection="row-reverse"
      bgcolor="bg.300"
      border={selected === true ? '1px solid' : 'none'}
      borderRadius="12px"
      borderColor="blue.100"
      alignItems="center"
      justifyContent="start"
      pl={1}
      minHeight={'50px'}
    >
      <Typography
        sx={{
          fontSize: {
            xs: '10px',
            sm: '12px',
            md: '15px',
            lg: '18px',
            xl: '20px',
          },
          pr: {
            xs: 1,
            sm: 2,
          },
          fontWeight: 400,
          lineHeight: '24.3px',
          color: selected === true ? 'blue.100' : 'textColor.300',
          textTransform: 'capitalize',
        }}
      >
        {name}
      </Typography>
      <Box
        pr={1}
        pl={1}
        display="flex"
        flexDirection="column"
        justifyContent="center"
      >
        {
          {
            [IconType.pole_detec]: (
              <AssetDetectionIcon
                color={
                  selected === true
                    ? theme.palette.primary.main
                    : theme.palette.textColor.main
                }
              />
            ),
            [IconType.six_6ghz]: (
              <Six6GhzIcon
                color={
                  selected === true
                    ? theme.palette.primary.main
                    : theme.palette.textColor.main
                }
              />
            ),
            [IconType.pale_subleasing]: (
              <PalSubleasingIcon
                color={
                  selected === true
                    ? theme.palette.primary.main
                    : theme.palette.textColor.main
                }
              />
            ),
            [IconType.shared_spectrum]: (
              <SharedSpectrumIcon
                color={
                  selected === true
                    ? theme.palette.primary.main
                    : theme.palette.textColor.main
                }
              />
            ),
          }[icon]
        }
      </Box>
    </Box>
  )
}

export default LauchpadTabButton
