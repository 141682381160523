/* eslint-disable  */
import {
  Box,
  Button,
  CircularProgress,
  IconButton,
  InputAdornment,
  TextField,
  Typography,
} from '@mui/material'
import NotificationIcon from '../assets/svgs/notification.svg'
import React, { type FC } from 'react'
import { ProtectedComponent } from '../components/protected_route'
import { useNavigate } from 'react-router-dom'
import { useAppDispatch } from '../store/hooks'
import { reset } from '../store/new_project'
import Colors from '../utils/colors'
import SearchIcon from '../assets/svgs/search.svg'
import { reset as reportReset } from '../store/gen_report'
import { reset as inputMappingReset } from '../store/input_mapping'
import { SDCheckbox } from '../components/checkbox'
import {
  useConfigurationQuery,
  useUpdateConfigurationMutation,
} from '../services/user_service'
import type { Configuration } from '../types/user'
import { isGSVKeyValid } from '../utils/functions'
import { errorToast, successToast } from '../utils/toast'

const Setting: React.FC = () => {
  const navigate = useNavigate()
  const [setting, setSetting] = React.useState('GSV')
  const dispatch = useAppDispatch()
  const { data, isLoading, refetch } = useConfigurationQuery(null)
  const [setConfig, { isLoading: isSaving }] = useUpdateConfigurationMutation()
  const [validatingKey, setValidationKey] = React.useState(false)

  const toNewProject = React.useCallback(() => {
    dispatch(reset())
    dispatch(reportReset())
    dispatch(inputMappingReset())
    navigate('/new_project')
  }, [navigate])

  const onSaveKey = async (key: string) => {
    if (setting === 'GSV') {
      setValidationKey(true)
      const isValid = await isGSVKeyValid(key)
      setValidationKey(false)
      if (!isValid) {
        errorToast('Invalid GSV key')
        return
      }
    }
    const payload = {
      ...data,
      configuration: {
        ...data?.configuration,
        [setting]: {
          ...data?.configuration[setting],
          apiKey: key,
        },
      },
    }
    setConfig(payload)
      .unwrap()
      .then(() => {
        refetch()
        successToast(`API key for ${setting} saved successfully`)
      })
      .catch((err) => {
        const errMsg = err != null && 'data' in err ? err.data : {}
        const msg = errMsg?.detail ?? 'Unable to save API key'

        errorToast(`${msg}`)
      })
  }

  const onSaveSecret = async (secret: string) => {
    const payload = {
      ...data,
      configuration: {
        ...data?.configuration,
        [setting]: {
          ...data?.configuration[setting],
          secret: secret,
        },
      },
    }
    setConfig(payload)
      .unwrap()
      .then(() => {
        refetch()
        successToast(`API secret for ${setting} saved successfully`)
      })
      .catch((err) => {
        const errMsg = err != null && 'data' in err ? err.data : {}
        const msg = errMsg?.detail ?? 'Unable to save API secret'

        errorToast(`${msg}`)
      })
  }

  const onGSVAttributeChange = async (attribute: string, value: any) => {
    const payload = {
      ...data,
      configuration: {
        ...data?.configuration,
        GSV: {
          ...data?.configuration['GSV'],
          [attribute]: value,
        },
      },
    }
    setConfig(payload)
      .unwrap()
      .then(() => {
        refetch()
        successToast(`GSV setting saved successfully`)
      })
      .catch((err) => {
        const errMsg = err != null && 'data' in err ? err.data : {}
        const msg = errMsg?.detail ?? `Unable to save GSV setting`

        errorToast(`${msg}`)
      })
  }

  const gsvAttributesSavers: GSVSpecificSettingProps = {
    roadsEnabled: data?.configuration['GSV']?.roadsEnabled ?? false,
    roadsApiKey: data?.configuration['GSV']?.roadsApiKey ?? '',
    streetViewEnabled: data?.configuration['GSV']?.streetViewEnabled ?? false,
    streetViewApiKey: data?.configuration['GSV']?.streetViewApiKey ?? '',
    satelliteEnabled: data?.configuration['GSV']?.satelliteEnabled ?? false,
    satelliteApiKey: data?.configuration['GSV']?.satelliteApiKey ?? '',
    onSaveRoadsEnabled: (enabled: boolean) => {
      onGSVAttributeChange('roadsEnabled', enabled)
        .then(() => {})
        .catch(() => {})
    },
    onSaveRoadsApiKey: (key: string) => {
      onGSVAttributeChange('roadsApiKey', key)
        .then(() => {})
        .catch(() => {})
    },
    onSaveStreetViewEnabled: (enabled: boolean) => {
      onGSVAttributeChange('streetViewEnabled', enabled)
        .then(() => {})
        .catch(() => {})
    },
    onSaveStreetViewApiKey: (key: string) => {
      onGSVAttributeChange('streetViewApiKey', key)
        .then(() => {})
        .catch(() => {})
    },
    onSaveSatelliteEnabled: (enabled: boolean) => {
      onGSVAttributeChange('satelliteEnabled', enabled)
        .then(() => {})
        .catch(() => {})
    },
    onSaveSatelliteApiKey: (key: string) => {
      onGSVAttributeChange('satelliteApiKey', key)
        .then(() => {})
        .catch(() => {})
    },
  }

  if (isLoading) {
    return (
      <Box
        display="flex"
        justifyContent="center"
        alignItems="center"
        sx={{ height: '93vh' }}
      >
        <CircularProgress />
      </Box>
    )
  }

  return (
    <Box
      display="flex"
      flexDirection="column"
      sx={{ overflow: 'hidden', height: '93vh' }}
    >
      <Box display="flex" flex={2.2} alignItems="center">
        <Box
          flex={{
            xs: 1,
            sm: 2,
            md: 3,
            lg: 5.8,
            xl: 5.8,
          }}
          display="flex"
          flexDirection="row"
          justifyContent="start"
        >
          <Box flex={0.15} />
          <Box
            flex={3.8}
            display="flex"
            flexDirection="row"
            justifyContent="start"
            alignItems="center"
          >
            <Typography
              sx={{
                fontSize: '24px',
                fontWeight: 400,
                lineHeight: '33.4px',
                color: 'white.200',
                maxLines: 1,
                mr: {
                  xs: '1px',
                  sm: '1px',
                  md: '2px',
                  lg: '4px',
                },
              }}
            >
              Settings
            </Typography>
          </Box>
          <Box
            flex={12}
            display="flex"
            flexDirection="row"
            justifyContent="start"
            alignItems="center"
          >
            {['GSV', 'Cyclomedia', 'C3Spectra'].map((item) => (
              <Button
                key={item}
                variant="contained"
                sx={{
                  mr: {
                    xs: '5px',
                    sm: '8px',
                    md: '10px',
                    lg: '14px',
                    xl: '16px',
                  },
                  fontSize: '14px',
                  fontWeight: 400,
                  lineHeight: '19.6px',
                  color: setting === item ? 'white.main' : 'textColor.300',
                  bgcolor: setting === item ? 'bg.300' : 'transparent',
                  textTransform: 'none',
                  borderRadius: '8px',
                }}
                onClick={() => {
                  setSetting(item)
                }}
              >
                {item}
              </Button>
            ))}
          </Box>
        </Box>
        <Box
          flex={2.3}
          display="flex"
          flexDirection="column"
          alignItems="stretch"
        >
          <TextField
            placeholder="Search"
            variant="outlined"
            size="small"
            InputProps={{
              style: {
                backgroundColor: Colors.bg2,
              },
              startAdornment: (
                <InputAdornment position="start">
                  <img src={SearchIcon} alt="" />
                </InputAdornment>
              ),
            }}
          />
        </Box>
        <Box flex={1.4} display="flex" flexDirection="row" justifyContent="end">
          <Button
            variant="contained"
            onClick={() => {
              toNewProject()
            }}
          >
            Create Project
          </Button>
        </Box>
        <Box
          flex={0.5}
          display="flex"
          flexDirection="column"
          justifyContent="start"
          alignItems="center"
        >
          <IconButton
            sx={{
              borderRadius: '8px',
              backgroundColor: 'bg.200',
            }}
          >
            <img src={NotificationIcon} />
          </IconButton>
        </Box>
      </Box>
      <Box flex={0.3} />
      <Box
        display="flex"
        flex={25}
        sx={{ overflowY: 'scroll' }}
        flexDirection="row"
      >
        <Box
          display="flex"
          flex={6}
          flexDirection="column"
          alignItems="stretch"
        >
          <SpecificSetting
            key={setting}
            inputType={setting}
            data={data}
            validatingKey={validatingKey}
            onSaveKey={(key: string) => {
              onSaveKey(key)
                .then(() => {})
                .catch(() => {})
            }}
            onSaveSecret={(secret: string) => {
              onSaveSecret(secret)
                .then(() => {})
                .catch(() => {})
            }}
          />
          {setting === 'GSV' && <GSVSpecificSetting {...gsvAttributesSavers} />}
        </Box>
        <Box display="flex" flex={4} />
      </Box>
    </Box>
  )
}

interface SpecificSettingProps {
  inputType: string
  data: Configuration | undefined
  onSaveKey: (key: string) => void
  onSaveSecret: (secret: string) => void
  validatingKey?: boolean
}

const SpecificSetting: FC<SpecificSettingProps> = (
  props: SpecificSettingProps
) => {
  let { inputType, data } = props
  let inputLabel = inputType
  const [apiKey, setApiKey] = React.useState(
    data?.configuration[inputType]?.apiKey ?? ''
  )
  const [secret, setSecret] = React.useState(
    data?.configuration[inputType]?.secret ?? ''
  )
  if (inputType === 'GSV') {
    inputLabel = `Google street view`
  }

  return (
    <Box display="flex" flexDirection="column" flex={1}>
      <Box bgcolor="bg.200" px="24px" py="32px" borderRadius="20px">
        <Box display="flex" flexDirection="row" px="14px" py="6px">
          <Typography fontWeight={400} fontSize="18px" lineHeight="24.3px">
            Key
          </Typography>
        </Box>
        <Box display="flex" flexDirection="row" p="20px" alignItems="center">
          <TextField
            sx={{ flex: 7 }}
            label={`${inputLabel} API Key`}
            variant="outlined"
            size="small"
            value={apiKey}
            onChange={(e) => {
              setApiKey(e.target.value)
            }}
          />
          <Box
            flex={2}
            display="flex"
            flexDirection="column"
            alignItems="stretch"
            pl="8px"
          >
            {props.validatingKey ? (
              <CircularProgress />
            ) : (
              <Button
                variant="contained"
                onClick={() => props.onSaveKey(apiKey)}
                sx={{
                  bgcolor: 'bg.300',
                  textTransform: 'none',
                  borderRadius: '8px',
                  color: 'textColor.300',
                  borderColor: 'stroke.main',
                  border: '1px solid',
                }}
              >
                Validate & Save
              </Button>
            )}
          </Box>
        </Box>
        <Box display="flex" flexDirection="row" p="20px">
          <TextField
            sx={{ flex: 7 }}
            label={`${inputLabel} secret`}
            variant="outlined"
            size="small"
            value={secret}
            onChange={(e) => {
              setSecret(e.target.value)
            }}
          />
          <Box
            flex={2}
            display="flex"
            flexDirection="column"
            alignItems="stretch"
            pl="8px"
          >
            <Button
              variant="contained"
              onClick={() => props.onSaveSecret(secret)}
              sx={{
                bgcolor: 'bg.300',
                textTransform: 'none',
                borderRadius: '8px',
                color: 'textColor.300',
                borderColor: 'stroke.main',
                border: '1px solid',
              }}
            >
              Save
            </Button>
          </Box>
        </Box>
      </Box>
    </Box>
  )
}

interface GSVSpecificSettingProps {
  roadsEnabled: boolean
  roadsApiKey: string
  streetViewEnabled: boolean
  streetViewApiKey: string
  satelliteEnabled: boolean
  satelliteApiKey: string

  onSaveRoadsEnabled: (enabled: boolean) => void
  onSaveRoadsApiKey: (key: string) => void
  onSaveStreetViewEnabled: (enabled: boolean) => void
  onSaveStreetViewApiKey: (key: string) => void
  onSaveSatelliteEnabled: (enabled: boolean) => void
  onSaveSatelliteApiKey: (key: string) => void
}

const GSVSpecificSetting: FC<GSVSpecificSettingProps> = (props) => {
  return (
    <Box display="flex" flexDirection="column" flex={1} mt="15px">
      <Box bgcolor="bg.200" px="24px" py="32px" borderRadius="20px">
        <Box
          display="flex"
          flexDirection="column"
          px="14px"
          py="6px"
          alignItems="start"
        >
          <Typography
            fontWeight={400}
            fontSize="18px"
            lineHeight="24.3px"
            color="white.200"
          >
            API Key Configuration
          </Typography>
          <Typography
            fontWeight={300}
            fontSize="12px"
            lineHeight="16.8px"
            color="textColor.300"
          >
            Configure API keys for selected APIs
          </Typography>
        </Box>
        <Box display="flex" flexDirection="column" p="20px" alignItems="start">
          <Box display="flex" flexDirection="row" alignItems="center" mb="8px">
            <SDCheckbox
              checked={props.roadsEnabled}
              onChange={(e) => {
                props.onSaveRoadsEnabled(e.target.checked)
              }}
            />
            <Typography
              fontWeight={400}
              fontSize="14px"
              lineHeight="19.6px"
              color="white.200"
            >
              Roads API
            </Typography>
          </Box>
          <TextField
            label={`Roads API Key`}
            variant="outlined"
            size="small"
            fullWidth
            value={props.roadsApiKey}
            onChange={(e) => {
              props.onSaveRoadsApiKey(e.target.value)
            }}
          />
        </Box>
        <Box
          display="flex"
          flexDirection="column"
          p="10px 20px 20px 20px"
          alignItems="start"
        >
          <Box display="flex" flexDirection="row" alignItems="center" mb="8px">
            <SDCheckbox
              checked={props.streetViewEnabled}
              onChange={(e) => {
                props.onSaveStreetViewEnabled(e.target.checked)
              }}
            />
            <Typography
              fontWeight={400}
              fontSize="14px"
              lineHeight="19.6px"
              color="white.200"
            >
              StreetView API
            </Typography>
          </Box>
          <TextField
            fullWidth
            label={`StreetView API Key`}
            variant="outlined"
            size="small"
            value={props.streetViewApiKey}
            onChange={(e) => {
              props.onSaveStreetViewApiKey(e.target.value)
            }}
          />
        </Box>
        <Box
          display="flex"
          flexDirection="column"
          p="10px 20px 20px 20px"
          alignItems="start"
        >
          <Box display="flex" flexDirection="row" alignItems="center" mb="8px">
            <SDCheckbox
              checked={props.satelliteEnabled}
              onChange={(e) => {
                props.onSaveSatelliteEnabled(e.target.checked)
              }}
            />
            <Typography
              fontWeight={400}
              fontSize="14px"
              lineHeight="19.6px"
              color="white.200"
            >
              Satellite API
            </Typography>
          </Box>
          <TextField
            fullWidth
            label={`Satellite API Key`}
            variant="outlined"
            size="small"
            value={props.satelliteApiKey}
            onChange={(e) => {
              props.onSaveSatelliteApiKey(e.target.value)
            }}
          />
        </Box>
      </Box>
    </Box>
  )
}

export const SettingView: FC = () => {
  return (
    <ProtectedComponent showSidebarOptions>
      <Setting />
    </ProtectedComponent>
  )
}
