import React from 'react'
import {
  Bar,
  Tooltip,
  XAxis,
  YAxis,
  BarChart,
  PieChart,
  Pie,
  Legend,
} from 'recharts'
import Colors from '../utils/colors'
import { Box, IconButton, Typography, useMediaQuery } from '@mui/material'
import { ReactComponent as MaximizeIcon } from '../assets/svgs/maximize_icon.svg'
import { ReactComponent as DownloadIcon } from '../assets/svgs/download_icon.svg'
import { percentageOf } from '../utils/functions'
import { exportComponentAsPNG } from 'react-component-export-image'
import { errorToast, successToast } from '../utils/toast'

const barColors = [Colors.blue30, Colors.blue50, Colors.blue100]

export interface ChartProps {
  key: string
  data: any[]
  width: number
  height: number
  title: string
  name: string
  type: 'bar' | 'pie'
  isMaximized?: boolean
  onMaximize?: () => void
}

const SPBarChart: React.FC<ChartProps> = (props) => {
  const ref = React.useRef()

  // eslint-disable-next-line
  const matches = useMediaQuery('(min-width:700px)')
  const barData = React.useMemo(() => addFillColor(props.data), [props.data])

  return (
    <Box
      ref={ref}
      display="flex"
      flexDirection="column"
      bgcolor="bg.300"
      borderRadius="16px"
      maxWidth={props.width}
      px="24px"
      pt="22px"
      pb="10px"
    >
      <Box
        display="flex"
        flexDirection="row"
        justifyContent="space-between"
        alignItems="center"
        pb="5px"
      >
        <Typography
          variant="h5"
          sx={{
            fontSize: '18px',
            lineHeight: '24.3px',
            fontWeight: 400,
          }}
        >
          {props.title}
        </Typography>
        <Box data-html2canvas-ignore="true">
          {props?.isMaximized === true ? null : (
            <IconButton onClick={props.onMaximize}>
              <MaximizeIcon />
            </IconButton>
          )}
          <IconButton
            onClick={() => {
              // @ts-expect-error type mismatch
              exportComponentAsPNG(ref, {
                fileName: `${props.name}.png`,
                html2CanvasOptions: {
                  backgroundColor: null,
                },
              })
                .then(() => {
                  successToast(`Chart downloaded as ${props.name}.png`)
                })
                .catch(() => {
                  errorToast('Failed to download chart')
                })
            }}
          >
            <DownloadIcon />
          </IconButton>
        </Box>
      </Box>
      <Box display="flex" flexDirection="column" justifyContent="center">
        {props.type === 'bar' ? (
          <BarChart
            margin={{ left: -35 }}
            data={barData}
            width={percentageOf(props.width, 90)}
            height={props.height}
          >
            <defs>{renderStripes()}</defs>
            <XAxis
              dataKey="name"
              tickLine={false}
              axisLine={false}
              style={{
                fontSize: '13px',
                lineHeight: '16.8px',
                fontWeight: 300,
                fontFamily: 'Spline Sans',
                color: Colors.textTertiary,
              }}
            />
            <YAxis
              tickLine={false}
              axisLine={false}
              allowDecimals={false}
              style={{
                fontSize: '13px',
                lineHeight: '16.8px',
                fontWeight: 300,
                fontFamily: 'Spline Sans',
                color: Colors.textTertiary,
              }}
            />
            <Tooltip cursor={{ radius: 10 }} />
            <Bar
              dataKey="value"
              fill="#8884d8"
              radius={10}
              barSize={60}
              unit="m"
              background={{ fill: 'url(#stripes)', radius: 10 }}
            />
          </BarChart>
        ) : null}
        {props.type === 'pie' ? (
          <PieChart width={percentageOf(props.width, 90)} height={props.height}>
            <Tooltip cursor={{ radius: 10 }} />
            <Pie data={barData} dataKey="value" nameKey="name" />
            <Legend verticalAlign="bottom" height={36} />
          </PieChart>
        ) : null}
      </Box>
    </Box>
  )
}

function addFillColor(data: any[]) {
  if (data.length === 0) return data
  // eslint-disable-next-line @typescript-eslint/no-unsafe-argument
  const maxValue = Math.max(...data.map((item) => item.value))
  // eslint-disable-next-line @typescript-eslint/no-unsafe-argument
  const minValue = Math.min(...data.map((item) => item.value))
  const mediumValue = (maxValue + minValue) / 2
  return data.map((item) => {
    let category = 0
    if (item.value > minValue && item.value < mediumValue) {
      category = 1
    } else if (item.value > mediumValue && item.value <= maxValue) {
      category = 2
    }
    const fill = barColors[category]
    return {
      ...item,
      fill,
    }
  })
}

const renderStripes = () => (
  <pattern
    id="stripes"
    patternUnits="userSpaceOnUse"
    width="8"
    height="8"
    rx={5}
    patternTransform="rotate(45)"
  >
    <rect width="4" height="8" transform="translate(0,0)" fill={Colors.bg2} />
  </pattern>
)

export default SPBarChart
