import React from 'react'
import { Typography, Container, Button } from '@mui/material'
import { useNavigate } from 'react-router-dom'

export const NotImplementedPage: React.FC = () => {
  const navigate = useNavigate()

  return (
    <Container maxWidth="sm">
      <Typography variant="h4" align="center" gutterBottom>
        Page Not Implemented
      </Typography>
      <Typography variant="body1" align="center">
        Sorry, this page is not yet implemented.
      </Typography>
      <Button
        onClick={() => {
          navigate(-1)
        }}
      >
        Go Back
      </Button>
    </Container>
  )
}
