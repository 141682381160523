import * as React from 'react'
import { useNavigate, useLocation } from 'react-router-dom'
import { authSelector, login, logout, setSidebarState } from '../store/auth'
import { useAppDispatch, useAppSelector } from '../store/hooks'
import { validValue } from '../utils/functions'
import { styled } from '@mui/material/styles'
import {
  Avatar,
  Box,
  CircularProgress,
  CssBaseline,
  Drawer,
  IconButton,
  Typography,
  useMediaQuery,
} from '@mui/material'
import DrawerIcons from '../assets/svgs/drawer_icon.svg'
import { useLazyCurrentUserQuery } from '../services/user_service'
import {
  SBHomeIcon,
  SBImportIcon,
  SBReportIcon,
  SBSettingsIcon,
} from './sidebar_icons'
import Colors from '../utils/colors'
import { SDLogo } from './sd_logo'
import { warningToast } from '../utils/toast'

// const drawerWidth = 260
// const drawerWidthClose = 70
const drawerWidth = { xs: 80, sm: 120, md: 200, lg: 260, xl: 260 }
const drawerWidthClose = { xs: 30, sm: 50, md: 60, lg: 70, xl: 70 }
const drawerWidthCloseHalf = {
  xs: `-${drawerWidthClose.xs / 2 - 15}px`,
  sm: `-${drawerWidthClose.sm / 2 - 15}px`,
  md: `-${drawerWidthClose.md / 2 - 15}px`,
  lg: `-${drawerWidthClose.lg / 2 - 15}px`,
  xl: `-${drawerWidthClose.xl / 2 - 15}px`,
}

const Main = styled('main', { shouldForwardProp: (prop) => prop !== 'open' })<{
  open?: boolean
}>(({ theme, open }) => ({
  flexGrow: 1,
  padding: theme.spacing(3),
  transition: theme.transitions.create('margin', {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  [theme.breakpoints.down('sm')]: {
    marginLeft: drawerWidthCloseHalf.sm,
  },
  [theme.breakpoints.down('md')]: {
    marginLeft: drawerWidthCloseHalf.md,
  },
  [theme.breakpoints.down('lg')]: {
    marginLeft: drawerWidthCloseHalf.lg,
  },
  ...(open === true && {
    transition: theme.transitions.create('margin', {
      easing: theme.transitions.easing.easeOut,
      duration: theme.transitions.duration.enteringScreen,
    }),
    marginLeft: 0,
  }),
}))

const DrawerHeader = styled('div')(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  padding: theme.spacing(0, 1),
  // necessary for content to be below app bar
  ...theme.mixins.toolbar,
  justifyContent: 'space-between',
  marginLeft: '10px',
  marginTop: '12px',
  flex: 2,
}))

const SideBarMenuOptions = [
  {
    name: 'Projects',
    icon: SBHomeIcon,
    path: '/projects',
    alternative: ['/running_project'],
  },
  { name: 'Imports', icon: SBImportIcon, path: '/import' },
  { name: 'Reports', icon: SBReportIcon, path: '/reports' },
  { name: 'Settings', icon: SBSettingsIcon, path: '/settings' },
]

interface Props {
  children?: JSX.Element | undefined
  noSidebar?: boolean
  showSidebarOptions?: boolean
}

function ProtectedComponent({
  children,
  noSidebar,
  showSidebarOptions,
}: Props): JSX.Element {
  const auth = useAppSelector(authSelector)
  const dispatch = useAppDispatch()
  const [trigger, result] = useLazyCurrentUserQuery()
  const navigate = useNavigate()
  const location = useLocation()
  const [open, setOpen] = React.useState(true)
  // @ts-expect-error unknown type
  const isSM = useMediaQuery((theme) => theme.breakpoints.up('sm'))
  // @ts-expect-error unknown type
  const isMD = useMediaQuery((theme) => theme.breakpoints.up('md'))
  // @ts-expect-error unknown type
  const isLG = useMediaQuery((theme) => theme.breakpoints.up('lg'))

  const handleDrawerOpen = () => {
    dispatch(setSidebarState(!open))
    setOpen((prev) => !prev)
  }
  const unAuth = () => {
    dispatch(logout())
    navigate('/login')
    setTimeout(() => {
      warningToast('Session expired, please login again')
    }, 500)
  }

  React.useEffect(() => {
    if (validValue(auth.token)) {
      trigger(null)
        .then((res) => {
          if (res.isSuccess) {
            dispatch(
              login({
                access_token: auth.token,
                userData: res.data,
              })
            )
          } else {
            // @ts-expect-error ts isses
            if (res.error.status === 401) {
              unAuth()
            }
          }
        })
        .catch((err) => {
          if (err.error?.status === 401 || err.data?.status === 401) {
            unAuth()
          }
        })
    } else {
      navigate('/')
    }
  }, [auth.token])

  if (result?.isLoading) {
    return (
      <Box
        className="h-screen"
        display="flex"
        width="100vw"
        flexDirection="column"
        justifyContent="center"
        alignItems="center"
        alignContent="center"
      >
        <CircularProgress />
      </Box>
    )
  }

  if (noSidebar === true) {
    return children ?? <></>
  }

  return (
    <Box sx={{ display: 'flex' }}>
      <CssBaseline />
      <Drawer
        sx={{
          display: 'flex',
          flexDirection: 'column',
          width: open ? drawerWidth : drawerWidthClose,
          flexShrink: 0,
          '& .MuiDrawer-paper': {
            width: open ? drawerWidth : drawerWidthClose,
            boxSizing: 'border-box',
          },
        }}
        variant="persistent"
        anchor="left"
        open={true}
      >
        <DrawerHeader>
          {open ? (
            <Box>
              <SDLogo
                width={!isSM ? 40 : !isMD ? 70 : !isLG ? 120 : undefined}
                height={!isSM ? 20 : !isMD ? 26 : !isLG ? 38 : undefined}
              />
            </Box>
          ) : null}
          <IconButton onClick={handleDrawerOpen}>
            <img src={DrawerIcons} alt="Open" />
          </IconButton>
        </DrawerHeader>
        <Box
          display="flex"
          flexDirection="column"
          justifyContent="start"
          mx={open ? '0px' : '7px'}
          flex={16}
        >
          {showSidebarOptions === true ? (
            <Box
              display="flex"
              flexDirection="column"
              justifyContent="center"
              alignItems={open ? 'stretch' : 'center'}
              bgcolor="bg.200"
              py="10px"
              mx={open ? '10px' : '2px'}
              borderRadius="16px"
            >
              {SideBarMenuOptions.map((item, index) => {
                const isSelected =
                  (location.pathname === item.path ||
                    item.alternative?.includes(location.pathname)) ??
                  false
                return (
                  <Box
                    display="flex"
                    flexDirection="row"
                    alignItems="center"
                    minHeight="40px"
                    key={index}
                    sx={{
                      cursor: 'pointer',
                      '&:hover': {
                        bgcolor: 'bg.300',
                      },
                      margin: '1px 10px',
                      borderRadius: '12px',
                    }}
                    bgcolor={isSelected ? 'bg.300' : 'bg.200'}
                    onClick={() => {
                      navigate(item.path)
                    }}
                  >
                    <Box
                      px="10px"
                      display="flex"
                      flexDirection="column"
                      justifyContent="center"
                    >
                      <item.icon
                        color={isSelected ? 'white' : Colors.textTertiary}
                      />
                    </Box>
                    {open ? (
                      <Typography
                        sx={{
                          fontSize: '14px',
                          fontWeight: 400,
                          lineHeight: '19.6px',
                          color: isSelected ? 'white.main' : 'textColor.200',
                          textAlign: 'start',
                        }}
                      >
                        {item.name}
                      </Typography>
                    ) : null}
                  </Box>
                )
              })}
            </Box>
          ) : null}
        </Box>
        <Box
          display="flex"
          flex={2}
          flexDirection="column"
          onClick={() => {
            navigate('/profile')
          }}
        >
          {open ? (
            <Box
              display="flex"
              flexDirection="row"
              bgcolor="bg.200"
              py="10px"
              px="15px"
              mx="10px"
              border={location.pathname === '/profile' ? '1px solid' : 'none'}
              borderColor="blue.100"
              borderRadius="16px"
            >
              <Avatar
                sx={{
                  bgcolor: 'bg.300',
                  borderRadius: '12px',
                  fontSize: '12px',
                  fontWeight: 300,
                  lineHeight: '16.8px',
                  color: 'white.main',
                }}
                variant="square"
              >
                {auth.userData != null
                  ? `${auth.userData?.first_name.charAt(0)}${auth.userData?.last_name.charAt(0)}`
                  : 'NA'}
              </Avatar>
              <Box
                display="flex"
                flexDirection="column"
                justifyContent="center"
                ml="10px"
                sx={{ color: 'textColor.main', overflow: 'hidden' }}
              >
                <Typography
                  sx={{
                    fontSize: '12px',
                    fontWeight: 300,
                    lineHeight: '16.8px',
                    color: 'white.main',
                    overflow: 'hidden',
                    textOverflow: 'ellipsis',
                  }}
                  textAlign="left"
                >
                  {auth.userData?.email ?? 'N/A'}
                </Typography>
                <Typography
                  sx={{
                    fontSize: '12px',
                    fontWeight: 300,
                    lineHeight: '16.8px',
                    color: 'blue.200',
                  }}
                  textAlign="left"
                >
                  {auth.userData != null
                    ? `${auth.userData?.first_name} ${auth.userData?.last_name}`
                    : 'NA'}
                </Typography>
              </Box>
            </Box>
          ) : (
            <Box
              display="flex"
              flexDirection="row"
              justifyContent="center"
              bgcolor="bg.200"
              py="10px"
              mx="10px"
              border={location.pathname === '/profile' ? '1px solid' : 'none'}
              borderColor="blue.100"
              borderRadius="16px"
            >
              <Avatar
                sx={{
                  bgcolor: 'bg.300',
                  borderRadius: '12px',
                  fontSize: '12px',
                  fontWeight: 300,
                  lineHeight: '16.8px',
                  color: 'white.main',
                }}
                variant="square"
              >
                {auth.userData != null
                  ? `${auth.userData?.first_name.charAt(0)}${auth.userData?.last_name.charAt(0)}`
                  : 'NA'}
              </Avatar>
            </Box>
          )}
        </Box>
      </Drawer>
      <Main open={open}>{children ?? <></>}</Main>
    </Box>
  )
}

export { ProtectedComponent }
