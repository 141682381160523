import React, { type FC } from 'react'
import { Route, Routes } from 'react-router-dom'
import { LoginView } from './views/Login'
import { SplashView } from './views/splash'
import { SignupView } from './views/signup'
import { LauchpadView } from './views/launchpad'
import { ProjectView } from './views/projects'
import { ProfileView } from './views/profile'
import { NotImplementedPage } from './components/comp_404'
import { NewProjectView } from './views/new_project'
import { NewProjectInputView } from './views/project_input'
import { ProInputMappingView } from './views/project_input_mapping'
import { RuningProjectView } from './views/running_project'
import { RunAnalysisView } from './views/run_analysis'
import { DrawInputView } from './views/draw_input'
import { SettingView } from './views/setting_page'
import { ReportView } from './views/reports'
import { InsightsView } from './views/insights'
import { ImportsView } from './views/imports'

export const AppRoutes: FC = () => {
  return (
    <Routes>
      <Route path="/" element={<SplashView />} />
      <Route path="/login" element={<LoginView />} />
      <Route path="/signup" element={<SignupView />} />
      <Route path="/home" element={<LauchpadView />} />
      <Route path="/projects" element={<ProjectView />} />
      <Route path="/profile" element={<ProfileView />} />
      <Route path="/new_project" element={<NewProjectView />} />
      <Route path="/project_input" element={<NewProjectInputView />} />
      <Route path="/draw_input" element={<DrawInputView />} />
      <Route path="/pro_input_mapping" element={<ProInputMappingView />} />
      <Route path="/running_project" element={<RuningProjectView />} />
      <Route path="/run_analysis" element={<RunAnalysisView />} />
      <Route path="/settings" element={<SettingView />} />
      <Route path="/reports" element={<ReportView />} />
      <Route path="/insights" element={<InsightsView />} />
      <Route path="/import" element={<ImportsView />} />
      <Route path="*" element={<NotImplementedPage />} />
    </Routes>
  )
}
