import React, { type FC } from 'react'
import Spline from '@splinetool/react-spline'
import { motion } from 'framer-motion'
import SpectraDiscovery from '../assets/svgs/spectra_disc.svg'
import { Box, Button, Typography, useMediaQuery } from '@mui/material'
import { Navigate, useNavigate } from 'react-router-dom'
import { authSelector } from '../store/auth'
import { useAppSelector } from '../store/hooks'
import { validValue } from '../utils/functions'

export const SplashView: FC = () => {
  const auth = useAppSelector(authSelector)
  const navigate = useNavigate()
  // @ts-expect-error unknown type
  const isSM = useMediaQuery((theme) => theme.breakpoints.up('sm'))
  // @ts-expect-error unknown type
  const isMD = useMediaQuery((theme) => theme.breakpoints.up('md'))
  // @ts-expect-error unknown type
  const isLG = useMediaQuery((theme) => theme.breakpoints.up('lg'))
  // eslint-disable-next-line
  const isExistingUser = window.localStorage.getItem('isUser') === 'yes'

  function onLoad(spline: any) {
    let zoom = 0.68
    if (!isSM) {
      zoom = 0.2
    } else if (!isMD) {
      zoom = 0.6
    } else if (!isLG) {
      zoom = 0.7
    }
    spline.setZoom(zoom)
  }

  if (validValue(auth.token)) {
    return <Navigate to="/home" />
  }

  return (
    <Box display="flex" flexDirection="row" bgcolor="background">
      <motion.div
        className="h-screen"
        initial={{ width: '80%' }}
        animate={{ width: '60%' }}
        transition={{ duration: 0.5, delay: 3 }}
      >
        <div className="h-screen" style={{ pointerEvents: 'none' }}>
          <Spline
            onLoad={onLoad}
            scene="https://prod.spline.design/VB3dtUmFvmLibQl7/scene.splinecode"
          />
        </div>
      </motion.div>
      <motion.div
        className="h-screen"
        initial={{ width: '0%' }}
        animate={{ width: '40%' }}
        transition={{
          duration: 0.5,
          delay: 3.5,
        }}
      >
        <motion.div
          initial={{ opacity: 0 }}
          animate={{ opacity: 1 }}
          transition={{
            duration: 0.5,
            delay: 5,
          }}
          className="h-screen ml-10"
        >
          <Box
            display="flex"
            flexDirection="column"
            justifyContent="center"
            className="h-screen"
          >
            <Box display={'flex'} pb={3}>
              <img src={SpectraDiscovery} alt="Spectra Discovery" />
            </Box>
            <Box display="flex" flexDirection="row" justifyContent={'start'}>
              <Typography variant="h5" gutterBottom color="#fff">
                Mapping the Future:
              </Typography>
            </Box>
            <Box display="flex" flexDirection="row" pt={1} pb={4}>
              <Typography
                variant="h6"
                gutterBottom
                color="primary.main"
                fontWeight="bold"
              >
                Geospatial AI&nbsp;
              </Typography>
              <Typography variant="h6" gutterBottom color="#fff">
                at Your Fingertips
              </Typography>
            </Box>
            <Box display="flex">
              <Button
                variant="contained"
                onClick={() => {
                  navigate('/login')
                }}
                sx={{
                  textTransform: 'none',
                  paddingLeft: '24px',
                  paddingRight: '24px',
                  fontWeight: 300,
                  fontSize: '12px',
                }}
              >
                Get started
              </Button>
            </Box>
          </Box>
        </motion.div>
      </motion.div>
    </Box>
  )
}
