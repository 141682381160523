import { Cartesian3 } from 'cesium'
import * as Cesium from 'cesium'
import type { NewProjectResponse } from '../types/new_pro_request'

export function isValidEmail(email: string): boolean {
  return /^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(email)
}

export function notNull(value: any): boolean {
  return value !== null && value !== undefined
}

export function validValue(value: any): boolean {
  return (
    value !== undefined &&
    value !== null &&
    (Object.prototype.hasOwnProperty.call(value, 'length')
      ? value.length > 0
      : true)
  )
}

export function extractPoints(data: any[], mapping: any[]): any[] {
  if (data.length === 0) return []
  if (mapping.length === 0) {
    const header = data[0]
    mapping = [
      { header: 'input_lat', column: header.indexOf('input_lat') },
      { header: 'input_long', column: header.indexOf('input_long') },
    ]
  }
  const ids = ['user_id', 'cell_id']
  const lat = mapping.find((item: any) => item.header === 'input_lat')?.column
  const lon = mapping.find((item: any) => item.header === 'input_long')?.column
  // eslint-disable-next-line
  const id = mapping.find((item: any) => ids.includes(item.header))?.column
  if (lat === undefined || lon === undefined) return []
  return data
    .slice(1)
    .filter((item) => item.length > 0)
    .map((point: any) => ({
      lat: point[lat],
      lon: point[lon],
      id: Number.isNaN(id) ? undefined : point[id],
    }))
}

export function formatDate(dateString: string): string {
  // Create a Date object from the input string
  const date = new Date(dateString)

  // Extract year, month, and day from the Date object
  const year = date.getUTCFullYear()
  const month = String(date.getUTCMonth() + 1).padStart(2, '0') // Months are zero-based
  const day = String(date.getUTCDate()).padStart(2, '0')

  // Format the date as YYYY-MM-DD
  return `${year}-${month}-${day}`
}

function eofType(data: string): string {
  if (data.includes('\r\n')) {
    return '\r\n'
  } else {
    return '\n'
  }
}

export async function downloadAndParseCSV(url: string, limit = -1) {
  const response = await fetch(url)
  const data = await response.text()
  const rows = data.split(eofType(data))
  const headers = rows[0].split(',')
  const jsonData = []

  limit = limit === -1 ? rows.length : limit
  for (let i = 1; i < limit; i++) {
    const values = rows[i].split(',')
    if (values.length === headers.length) {
      // eslint-disable-next-line
      const rowObject: { [key: string]: string } = {} // Add index signature
      for (let j = 0; j < headers.length; j++) {
        rowObject[headers[j]] = values[j]
      }
      jsonData.push(rowObject)
    }
  }

  return [jsonData, data]
}

export async function downloadCSVLines(url: string, limit = -1) {
  const response = await fetch(url)
  const data = await response.text()
  const rows = data.split(eofType(data))
  const splittedRows = rows.map((line) => line.split(','))
  return [splittedRows, data]
}

export function parsePolygon(polygon: string) {
  const polygons = []
  let sanitized = polygon.replaceAll('(', '[').replaceAll(')', ']')
  sanitized = JSON.parse(sanitized)
  for (const p of sanitized) {
    const array = []
    for (const ltlng of p) {
      array.push(Cartesian3.fromDegrees(Number(ltlng[0]), Number(ltlng[1]), 0))
    }
    polygons.push(array)
  }
  return polygons.length > 0 ? polygons[0] : []
}
export function parsePolygonComp(polygon: string) {
  const polygons = []
  let sanitized = polygon.replaceAll('(', '[').replaceAll(')', ']')
  sanitized = JSON.parse(sanitized)
  for (const p of sanitized) {
    const array = []
    for (const ltlng of p) {
      array.push(Cartesian3.fromDegrees(Number(ltlng[0]), Number(ltlng[1]), 0))
    }
    polygons.push(array)
  }
  return polygons
}

export async function parseCSV(url: string) {
  const response = await fetch(url)
  const data = await response.text()
  const rows = data.split(eofType(data))
  const totalRows = rows.length - 1
  const extractedRows: string[][] = []
  const rowToExtract = totalRows > 50 ? 50 : totalRows
  for (let i = 0; i < rowToExtract; i++) {
    extractedRows.push(rows[i].split(','))
  }

  return extractedRows
}

export function toDegrees(cartesian3Pos: Cartesian3) {
  const pos = Cesium.Cartographic.fromCartesian(cartesian3Pos)
  return `${(pos.longitude / Math.PI) * 180},${(pos.latitude / Math.PI) * 180}`
}

// Function to calculate distance between two points
function distance(point1: Cartesian3, point2: Cartesian3): number {
  const dx = point2.x - point1.x
  const dy = point2.y - point1.y
  const dz = point2.z - point1.z
  return Math.sqrt(dx * dx + dy * dy + dz * dz)
}

// Function to calculate perimeter of the polygon
export function calculatePerimeter(points: Cartesian3[]): number {
  let perimeter = 0
  const numPoints = points.length
  for (let i = 0; i < numPoints; i++) {
    const startPoint = points[i]
    const endPoint = points[(i + 1) % numPoints] // Wrap around for the last point
    perimeter += distance(startPoint, endPoint)
  }
  return perimeter
}

// Function to calculate area of the polygon using Shoelace formula
export function calculateArea(points: Cartesian3[]): number {
  let area = 0
  const numPoints = points.length
  for (let i = 0; i < numPoints; i++) {
    const startPoint = points[i]
    const endPoint = points[(i + 1) % numPoints] // Wrap around for the last point
    area += startPoint.x * endPoint.y - endPoint.x * startPoint.y
  }
  return Math.abs(area) / 2
}

export async function isGSVKeyValid(key: string) {
  const result = await fetch(
    `https://maps.googleapis.com/maps/api/streetview?size=400x400&location=47.5763831,-122.4211769&key=${key}`
  )
  return result.status === 200
}

export function percentageOf(total: number, percentage: number) {
  return (total / 100) * percentage
}

export function humanize(string: string) {
  const breaked = string.replace(/[A-Z]/g, ' $&')
  return breaked.charAt(0).toUpperCase() + breaked.slice(1)
}

export function prepareMeta(project: NewProjectResponse) {
  const title = document.createElement('h1')
  title.textContent = 'Spectra Discovery'
  title.style.textAlign = 'center'
  title.style.color = '#000'

  // Create a row container
  const row = document.createElement('div')
  row.style.display = 'flex'
  row.style.marginTop = '20px'
  row.style.flexWrap = 'wrap'
  row.style.marginLeft = '10px'
  row.style.marginRight = '10px'

  const data = {
    'Project Name': project.name,
    'Project Created At': formatDate(project.created_at),
    Country: project.country,
    State: validValue(project.state) ? project.state : 'N/A',
    'Asset Type': 'Joint Utility Pole',
  }
  if (validValue(project.market)) {
    // @ts-expect-error type
    data.Market = project.market
  } else if (validValue(project.files[0].market)) {
    // @ts-expect-error type
    data.Market = project.files[0].market
  } else {
    // @ts-expect-error type
    data.Market = 'N/A'
  }

  for (const key in data) {
    // Create label
    const label = document.createElement('div')
    label.textContent = `${key}:`
    label.style.marginRight = '10px'
    label.style.color = '#000'
    row.appendChild(label)

    // Create property
    const property = document.createElement('div')
    if (key === 'Source File') {
      // row.appendChild(data[key])
    } else {
      // @ts-expect-error type
      property.textContent = data[key]
      property.style.fontWeight = 'bold'
      property.style.color = '#000'
      property.style.marginRight = '20px'
      property.style.textTransform = 'capitalize'
      row.appendChild(property)
    }
  }

  return [title, row]
}

export function toFixedDown(num: number, digits: number) {
  let n = num - Math.pow(10, -digits) / 2
  n += n / Math.pow(2, 53) // added 1360765523: 17.56.toFixedDown(2) === "17.56"
  return n.toFixed(digits)
}

export function openInStreetView(at: { latitude: number; longitude: number }) {
  const url = `https://www.google.com/maps/@?api=1&map_action=pano&viewpoint=${at.latitude}%2C${at.longitude}&pitch=20&q=${at.latitude}%2C${at.longitude}`

  const win = window.open(url, '_blank')
  if (win != null) win.focus()
}
