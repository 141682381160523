import { Box, Typography } from '@mui/material'
import React from 'react'

interface Props {
  step: number
}

const ProjectStep: React.FC<Props> = ({ step }) => {
  return (
    <Box
      display="flex"
      flexDirection="row"
      justifyContent="center"
      alignItems="center"
      alignContent="center"
      flexGrow={1}
    >
      <Box
        bgcolor={step === 1 ? 'bg.300' : 'transparent'}
        borderRadius="8px"
        padding="8px 24px"
      >
        <Typography
          sx={{
            fontSize: '14px',
            fontWeight: 500,
            lineHeight: '19.6px',
            color: step === 1 ? 'white.200' : 'textColor.300',
          }}
        >
          1. Define the project
        </Typography>
      </Box>
      <Box
        bgcolor={step === 2 ? 'bg.300' : 'transparent'}
        borderRadius="8px"
        padding="8px 24px"
      >
        <Typography
          sx={{
            fontSize: '14px',
            fontWeight: 500,
            lineHeight: '19.6px',
            color: step === 2 ? 'white.200' : 'textColor.300',
          }}
        >
          2. Provide inputs
        </Typography>
      </Box>
    </Box>
  )
}

export default ProjectStep
