import {
  type GeneratedChartData,
  type OutputObject,
} from '../types/output_object'
import { type ChartData } from '../types/misc'

// Function to generate chart data for distance
function generateDistanceChartData(data: OutputObject[]): ChartData[] {
  const distanceChartData: Record<string, number> = {
    '<2': 0,
    '2-3': 0,
    '3-5': 0,
    '5-9': 0,
    '>=10': 0,
  }

  data.forEach((item) => {
    const distance = parseFloat(item.distance)
    if (distance < 2) {
      distanceChartData['<2'] += 1
    } else if (distance >= 2 && distance < 3) {
      distanceChartData['2-3'] += 1
    } else if (distance >= 3 && distance < 5) {
      distanceChartData['3-5'] += 1
    } else if (distance >= 5 && distance < 9) {
      distanceChartData['5-9'] += 1
    } else if (distance >= 10) {
      distanceChartData['>=10'] += 1
    }
  })

  const toObject = Object.entries(distanceChartData)
  return toObject.map((item) => ({ name: item[0], value: item[1] }))
}

// Function to generate chart data for confidence intervals
function generateCIChartData(data: OutputObject[]): ChartData[] {
  const ciChartData: Record<string, number> = {
    '[0,5]': 0,
    '[6,10]': 0,
    '[11,15]': 0,
    '[16,20]': 0,
    '[21,15]': 0,
    '[26,30]': 0,
    '[31,35]': 0,
    '[36,40]': 0,
    '[41,45]': 0,
    '[46,50]': 0,
    '[51,55]': 0,
    '[56,60]': 0,
    '[61,65]': 0,
    '[66,70]': 0,
    '[71,75]': 0,
    '[76,80]': 0,
    '[81,85]': 0,
    '[86,90]': 0,
    '[91,95]': 0,
    '[96,100]': 0,
  }

  data.forEach((item) => {
    // eslint-disable-next-line no-useless-escape
    const confidence = parseInt(item.confidence.replace(/[^\d\.]*/g, ''))
    for (const key in ciChartData) {
      const [lower, upper] = key.replace('[', '').replace(']', '').split(',')
      if (confidence >= parseInt(lower) && confidence <= parseInt(upper)) {
        ciChartData[key] += 1
        break
      }
    }
  })

  return Object.entries(ciChartData).map((item) => ({
    name: item[0],
    value: item[1],
  }))
}

// Function to generate chart data for poles detected vs not detected
function generatePolesChartData(data: OutputObject[]): ChartData[] {
  let detected = 0
  let notDetected = 0

  data.forEach((item) => {
    if (
      item.pole_height_meter !== 'N/A' ||
      !isNaN(parseFloat(item.pole_height_meter))
    ) {
      detected++
    } else {
      notDetected++
    }
  })

  return [
    { name: 'Detected', value: detected },
    { name: 'Not Detected', value: notDetected },
  ]
}

// Function to generate chart data for average pole height
function generateAvgPoleHeightChartData(data: OutputObject[]): ChartData[] {
  const avgPoleHeightChartData: Record<string, number> = {
    '5': 0,
    '10': 0,
    '15': 0,
    '20': 0,
    '25': 0,
  }

  let totalPoles = 0
  let totalHeight = 0

  data.forEach((item) => {
    const heigt = parseFloat(item.pole_height_meter)
    if (item.pole_height_meter !== 'N/A' && !isNaN(heigt)) {
      totalPoles++
      totalHeight += heigt
    }
  })

  const avgHeight = totalHeight / totalPoles

  for (const key in avgPoleHeightChartData) {
    const height = parseInt(key)
    if (avgHeight < height) {
      avgPoleHeightChartData[key] += 1
    }
  }

  return Object.entries(avgPoleHeightChartData).map((item) => ({
    name: item[0],
    value: item[1],
  }))
}

// Function to generate chart data for poles with strands
function generatePolesWithStrandsChartData(data: OutputObject[]): ChartData[] {
  const polesWithStrandsChartData: Record<string, number> = {
    '1': 0,
    '2': 0,
    '3': 0,
    '4': 0,
    '5': 0,
    '>=6': 0,
  }

  data.forEach((item) => {
    const strands = parseInt(item.number_of_strands)
    if (strands >= 6) {
      polesWithStrandsChartData['>=6'] += 1
    } else if (!Number.isNaN(strands)) {
      polesWithStrandsChartData[strands.toString()] += 1
    }
  })

  return Object.entries(polesWithStrandsChartData).map((item) => ({
    name: item[0],
    value: item[1],
  }))
}

// Function to generate chart data for poles with equipments
function generatePolesWithEquipmentsChartData(
  data: OutputObject[]
): ChartData[] {
  let withEquipments = 0
  let withoutEquipments = 0

  data.forEach((item) => {
    if (
      item.equipment_distance_LC1_feet !== 'N/A' ||
      item.equipment_distance_LC2_feet !== 'N/A' ||
      item.equipment_distance_RC1_feet !== 'N/A' ||
      item.equipment_distance_RC2_feet !== 'N/A'
    ) {
      withEquipments++
    } else {
      withoutEquipments++
    }
  })

  return [
    { name: 'With Equipment', value: withEquipments },
    { name: 'Without Equipment', value: withoutEquipments },
  ]
}

// Example usage
export function generateChartData(data: OutputObject[]): GeneratedChartData {
  const distanceChartData = generateDistanceChartData(data)
  const ciChartData = generateCIChartData(data)
  const polesChartData = generatePolesChartData(data)
  const avgPoleHeightChartData = generateAvgPoleHeightChartData(data)
  const polesWithStrandsChartData = generatePolesWithStrandsChartData(data)
  const polesWithEquipmentsChartData =
    generatePolesWithEquipmentsChartData(data)

  console.log('Distance Chart Data:', distanceChartData)
  console.log('CI Chart Data:', ciChartData)
  console.log('Poles Chart Data:', polesChartData)
  console.log('Average Pole Height Chart Data:', avgPoleHeightChartData)
  console.log('Poles with Strands Chart Data:', polesWithStrandsChartData)
  console.log('Poles with Equipments Chart Data:', polesWithEquipmentsChartData)
  return {
    distanceChartData,
    ciChartData,
    polesChartData,
    avgPoleHeightChartData,
    polesWithStrandsChartData,
    polesWithEquipmentsChartData,
  }
}
