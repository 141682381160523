import { createSlice } from '@reduxjs/toolkit'
import type { PayloadAction } from '@reduxjs/toolkit'
import type { RootState } from './store'

// Define a type for the slice state
interface GenerateReportState {
  selectedProject: string
  includeCharts: boolean
  chartType: string[]
  reportFormat: string
  insetCsvLink: boolean
}

// Define the initial state using that type
const initialState: GenerateReportState = {
  selectedProject: '',
  includeCharts: false,
  chartType: [],
  reportFormat: '',
  insetCsvLink: false,
}

export const genReportSlice = createSlice({
  name: 'generateReport',
  initialState,
  reducers: {
    setSelectedProject: (state, action: PayloadAction<string>) => {
      state.selectedProject = action.payload
    },
    setIncludeCharts: (state, action: PayloadAction<boolean>) => {
      state.includeCharts = action.payload
    },
    setChartType: (state, action: PayloadAction<string>) => {
      state.chartType.push(action.payload)
    },
    setReportFormat: (state, action: PayloadAction<string>) => {
      state.reportFormat = action.payload
    },
    setInsetCsvLink: (state, action: PayloadAction<boolean>) => {
      state.insetCsvLink = action.payload
    },
    reset: (state) => {
      Object.assign(state, initialState)
    },
  },
})

export const {
  setSelectedProject,
  setIncludeCharts,
  setChartType,
  setReportFormat,
  setInsetCsvLink,
  reset,
} = genReportSlice.actions
export const genReportSelector = (state: RootState) => state.genReportReducer

export default genReportSlice.reducer
