const Colors = {
  primary100: '#2850BF',
  secondary100: '#915EFF',
  secondary200: '#AE89FF',
  blue100: '#0094FF',
  blue50: '#59B9FF',
  blue30: '#B7E1FF',
  greyBlue: '#7E8998',
  white1: '#FFFFFF',
  white2: '#FAFAFA',
  textSecondary: '#F8F8F8',
  textTertiary: '#989899',
  stroke: '#2A2B2E',
  bg3: '#212325',
  bg2: '#191A1D',
  bg1: '#121315',
  cancel: '#B20C09',
  warning: '#F8DD57',
  success: '#28BF74',
  background: '#080809',
  textSecondary3: '#ACAEB0',
}

export default Colors
