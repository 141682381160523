/* eslint-disable */
// if `"proxy": "http://localhost:8000",` added in package.json then it will /api/v1
const localBaseUrl = '/api/v1/'

const prodBaseUrl = '/api/v1/'

const mapboxData = {
  styleId: 'navigation-night-v1',
  accessToken:
    'pk.eyJ1IjoibXNhbG1hbjI0MSIsImEiOiJjbHRlOHF1N20wY2hlMmlydGs4MHZod3ptIn0.S4nR4Ekejr2-YoZ1L0J0og',
}

export const baseUrl = localBaseUrl

export const mapboxConfig = mapboxData

export const sampleCSV = `date,cell_id,input_long,input_lat
10-15-2022,COAX_1,-80.83964084,35.1687003
10-15-2022,COAX_2,-80.85736789,35.22887874
10-15-2022,COAX_3,-80.90979154,35.14373742
10-15-2022,COAX_4,-80.85747338,35.220587569
10-15-2022,COAX_5,-80.78619682,35.15432434
10-15-2022,COAX_6,-80.80323787,35.224322729
10-15-2022,COAX_7,-80.77506029,35.21698305
10-15-2022,COAX_8,-80.8109722,35.13111875
10-15-2022,COAX_9,-80.81198459,35.168568789
10-15-2022,COAX_10,-80.77843166,35.1989542
10-15-2022,COAX_11,-80.83485712,35.03584942
10-15-2022,COAX_12,-80.70294142,35.08907258
10-15-2022,COAX_13,-80.868519499,35.19698041
10-15-2022,COAX_14,-80.79358177,35.11672314
10-15-2022,COAX_15,-80.77922263,35.19048172
10-15-2022,COAX_16,-80.89451722,35.17213561
10-15-2022,COAX_17,-80.79090546,35.20844236
10-15-2022,COAX_18,-80.8193089,35.193824059
10-15-2022,COAX_19,-80.90052242,35.16714309
10-15-2022,COAX_20,-80.86600176,35.218045139
10-15-2022,COAX_21,-80.81913306,35.18232713
10-15-2022,COAX_22,-80.81884685,35.22669398
10-15-2022,COAX_23,-80.80851356,35.37744741
10-15-2022,COAX_24,-80.83525281,35.10721117
10-15-2022,COAX_25,-80.85076736,35.33075779
10-15-2022,COAX_26,-80.8164134,35.20107819
10-15-2022,COAX_27,-80.80409227,35.13055285
10-15-2022,COAX_28,-80.88369389,35.17917697
10-15-2022,COAX_29,-81.02732377,35.12684203
10-15-2022,COAX_30,-80.798447359,35.18816458
10-15-2022,COAX_31,-80.75944986,35.19372251
10-15-2022,COAX_32,-80.78435202,35.14133231
10-15-2022,COAX_33,-80.77032898,35.17256006
10-15-2022,COAX_34,-80.88062256,35.1923119
10-15-2022,COAX_35,-80.76514691,35.17128168
10-15-2022,COAX_36,-80.86796885,35.17602154
10-15-2022,COAX_37,-80.89747386,35.15433171
10-15-2022,COAX_38,-80.87265494,35.203826
10-15-2022,COAX_39,-80.785605099,35.10101066
10-15-2022,COAX_40,-80.83588919,35.14674753
10-15-2022,COAX_41,-80.88665744,35.10758667
10-15-2022,COAX_42,-80.81169593,35.15796897
10-15-2022,COAX_43,-80.85429195,35.20388363
10-15-2022,COAX_44,-80.9257328,35.15711344
10-15-2022,COAX_45,-80.80149198,35.136565789
10-15-2022,COAX_46,-80.86052702,35.167027239
10-15-2022,COAX_47,-80.76744741,35.1317626
10-15-2022,COAX_48,-80.85601975,35.21305745
10-15-2022,COAX_49,-80.78000723,35.12384013
10-15-2022,COAX_50,-80.81511116,35.30636091
10-15-2022,COAX_51,-80.78937884,35.22033749
10-15-2022,COAX_52,-80.85604574,35.21601618
10-15-2022,COAX_53,-80.78578308,35.1300367
10-15-2022,COAX_54,-80.82149251,35.19547021
10-15-2022,COAX_55,-80.77908947,35.28896289
10-15-2022,COAX_56,-80.8343441,35.20839505
10-15-2022,COAX_57,-80.92444421,35.14835067
10-15-2022,COAX_58,-80.79011882,35.20663426
10-15-2022,COAX_59,-80.85743871,35.19315627
10-15-2022,COAX_60,-80.7856794,35.37767867
10-15-2022,COAX_61,-80.71484693,35.3106578
10-15-2022,COAX_62,-80.8105045,35.2247305
10-15-2022,COAX_63,-80.69491805,35.13025687
10-15-2022,COAX_64,-80.83131027,35.19252099
10-15-2022,COAX_65,-80.865943279,35.13780425
10-15-2022,COAX_66,-80.80213862,35.18268065
10-15-2022,COAX_67,-81.05059379,35.06388377
10-15-2022,COAX_68,-81.0092731,35.06769096
10-15-2022,COAX_69,-80.83007786,35.32282304
10-15-2022,COAX_70,-80.82082698,35.227749319
10-15-2022,COAX_71,-80.816345,35.045141679
10-15-2022,COAX_72,-80.78892491,35.12225008
10-15-2022,COAX_73,-80.84450702,35.12601779
10-15-2022,COAX_74,-80.954700451,35.13041109
10-15-2022,COAX_75,-80.80955215,35.16255143
10-15-2022,COAX_76,-80.82360806,35.23378039
10-15-2022,COAX_77,-80.886065529,35.11148918
10-15-2022,COAX_78,-80.8735032,35.22622511
10-15-2022,COAX_79,-80.79677236,35.22798802
10-15-2022,COAX_80,-80.8222897,35.21981963
10-15-2022,COAX_81,-80.89618183,35.18297666
10-15-2022,COAX_82,-80.8637347,35.201718639
10-15-2022,COAX_83,-80.80871822,35.22569422
10-15-2022,COAX_84,-80.74517447,35.12689915
10-15-2022,COAX_85,-80.83357924,35.10116829
10-15-2022,COAX_86,-80.7155935,35.14598915
10-15-2022,COAX_87,-80.815445249,35.17315717
10-15-2022,COAX_88,-80.78412647,35.21356175
10-15-2022,COAX_89,-80.86882564,35.19179435
10-15-2022,COAX_90,-80.7696767,35.04362842
10-15-2022,COAX_91,-80.77060131,35.17054704
10-15-2022,COAX_92,-80.765859279,35.07613926
10-15-2022,COAX_93,-80.82616071,35.124162009
10-15-2022,COAX_94,-80.845444631,35.13178862
10-15-2022,COAX_95,-80.80428146,35.22414196
10-15-2022,COAX_96,-80.86439662,35.1587187
10-15-2022,COAX_97,-80.82660689,35.10282171
10-15-2022,COAX_98,-80.85990252,35.16349756
10-15-2022,COAX_99,-80.76463566,35.20510809
10-15-2022,COAX_100,-80.80788071,35.13602477
`
