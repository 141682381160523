/* eslint-disable */
import React, { useEffect, type FC } from 'react'
import { ProtectedComponent } from '../components/protected_route'
import {
  Stack,
  Box,
  Typography,
  IconButton,
  Button,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  TableContainer,
  Table,
  TableCell,
  TableBody,
  TableRow,
  TableHead,
} from '@mui/material'
import C3SpectraAssetDetLogo from '../assets/svgs/spectra_asset_logo.svg'
import CloseIcon from '@mui/icons-material/Close'
import WestIcon from '@mui/icons-material/West'
import ProjectStep from '../components/project_step'
import { useNavigate } from 'react-router-dom'
import { useAppDispatch, useAppSelector } from '../store/hooks'
import {
  mappingSelector,
  reset as inputMappingReset,
  updateClusterColumn,
  updateCountryColumn,
  updateLatColumn,
  updateLongColumn,
  updateMarketColumn,
  updateNameColumn,
  updateRegionColumn,
} from '../store/input_mapping'
import {
  addCSVMapping,
  clearCSVMapping,
  newProjectSelector,
  setSampleCSV,
  setSelectedFile,
} from '../store/new_project'
import {
  useGetProjectQuery,
  useUploadFileMutation,
} from '../services/project_services'
import { errorToast, successToast, warningToast } from '../utils/toast'
import { useQuery } from '../utils/parsers'
import { TextReader } from '../utils/text_reader'

const MaxLineToRead = 50

const ProjectInputMapping: FC = () => {
  const query = useQuery()
  const projectId = query.get('project')
  const newProject = useAppSelector(newProjectSelector)
  const mappingData = useAppSelector(mappingSelector)
  const [uploadCall] = useUploadFileMutation()
  const dispatch = useAppDispatch()
  const inputFile = React.useRef<any>(null)
  const navigate = useNavigate()
  // eslint-disable-next-line
  const { data: project, isLoading: projectLoading } = useGetProjectQuery(
    projectId?.toString() ?? ''
  )

  React.useEffect(() => {
    if (projectId == null) {
      setTimeout(() => {
        navigate('/projects')
      }, 500)
    }
  }, [projectId])

  useEffect(() => {
    if (newProject.sampleRows == null || newProject.sampleRows.length === 0) {
      setTimeout(() => {
        navigate('/projects')
      }, 500)
    }
  }, [newProject.sampleRows])

  const onSubmit = async () => {
    if (newProject.selectedFile == null) {
      errorToast('No file selected')
      return
    } else if (newProject.csvMapping.length < 3) {
      errorToast('Please map all the required fields')
      return
    } else if (newProject.csvMapping.some((m) => !m.isCorrect)) {
      errorToast('Please map all the required fields correctly')
      return
    }
    try {
      uploadCall({
        file: newProject.selectedFile,
        project_id: projectId,

        column_mapping: newProject.csvMapping.map((m) => ({
          [[m.header][0]]: newProject.sampleRows[0][parseInt(m.column)].trim(),
        })),
      })
        .unwrap()
        .then((res) => {
          successToast('File uploaded successfully')
          setTimeout(() => {
            navigate(`/running_project?project=${projectId}`)
          }, 200)
        })
        .catch((err) => {
          const errMsg = err != null && 'data' in err ? err.data : {}
          const msg = errMsg?.detail ?? 'Error uploading file'

          errorToast(`${msg}`)
        })
    } catch (err) {
      errorToast('Error uploading file')
    }
  }

  const onUpload = async (file: File) => {
    if (file?.size <= 0) {
      errorToast('Invalid or File is empty')
      return
    }
    dispatch(setSelectedFile(file))
    dispatch(clearCSVMapping())
    dispatch(inputMappingReset())
    const lines: string[][] = []
    const textReader = new TextReader(file)
    while (!textReader.endOfStream && lines.length <= MaxLineToRead) {
      const line = await textReader.readLine()
      if (line?.length > 0) {
        lines.push(line.split(',') as string[])
      }
    }
    dispatch(setSampleCSV(lines))
    successToast(`File ${file.name} selected, Please map the fields`)
  }

  return (
    <Box
      display="flex"
      flexDirection="column"
      className="h-screen"
      sx={{ overflow: 'hidden' }}
    >
      <Box flex={15} display="flex" flexDirection="column" alignItems="stretch">
        <Box flex={1} />
        <Box
          flex={8}
          display="flex"
          flexDirection="row"
          px="18px"
          alignContent="space-around"
        >
          <Box
            flex={3}
            display="flex"
            flexDirection="row"
            justifyContent="start"
            alignItems="start"
          >
            <img src={C3SpectraAssetDetLogo} alt="Spectra Discovery" />
          </Box>
          <Box
            flex={12}
            display="flex"
            flexDirection="column"
            alignItems="start"
          >
            <Typography
              sx={{
                flex: 2,
                fontSize: '24px',
                fontWeight: 600,
                lineHeight: '32.4px',
                color: 'white.main',
              }}
            >
              New project
            </Typography>
            <Box
              flex={1}
              display="flex"
              flexDirection="row"
              alignItems="center"
            >
              <Typography
                sx={{
                  fontSize: '14px',
                  fontWeight: 300,
                  lineHeight: '22.4px',
                  color: 'textColor.300',
                  pr: '6px',
                }}
              >
                Made a mistake? Re-upload the correct file to replace it.&nbsp;
              </Typography>
              <Button
                variant="contained"
                sx={{
                  bgcolor: 'bg.300',
                  textTransform: 'none',
                  borderRadius: '8px',
                  color: 'textColor.300',
                }}
                onClick={() => {
                  inputFile.current.click()
                }}
              >
                Upload
              </Button>
              <input
                type="file"
                id="file"
                ref={inputFile}
                accept=".csv"
                style={{ display: 'none' }}
                onChange={(e) => {
                  if (e.target.files != null && e.target.files.length > 0) {
                    onUpload(e.target.files[0])
                      .then(() => {})
                      .catch(() => {})
                  } else {
                    warningToast('No file selected')
                  }
                }}
              />
            </Box>
          </Box>
          <Box
            flex={1}
            display="flex"
            flexDirection="column"
            justifyContent="start"
            alignItems="end"
          >
            <IconButton
              sx={{
                borderRadius: '8px',
                backgroundColor: 'bg.200',
              }}
            >
              <CloseIcon sx={{ color: 'textColor.main', fontSize: '15px' }} />
            </IconButton>
          </Box>
        </Box>
        <Box flex={2} />
      </Box>
      <Box
        display="flex"
        flex={74}
        flexDirection="row"
        alignItems="stretch"
        maxHeight="75vh"
      >
        <Box flex={2.5} />
        <Box
          flex={10.5}
          display="flex"
          flexDirection="row"
          justifyItems="space-between"
          mr="20px"
        >
          <Stack
            flex={8}
            bgcolor="bg.200"
            borderRadius="20px"
            direction="column"
            justifyContent="start"
            alignItems="stretch"
            padding="24px 32px"
            spacing="11px"
            sx={{
              overflowY: 'scroll',
              maxHeight: '90vh',
            }}
          >
            <Typography
              sx={{
                fontSize: '18px',
                fontWeight: 400,
                lineHeight: '24.3px',
                textAlign: 'start',
              }}
            >
              CSV file mapping
            </Typography>
            <Typography
              sx={{
                fontSize: '14px',
                fontWeight: 400,
                lineHeight: '19.6px',
              }}
              textAlign="start"
              pb="5px"
            >
              Mandatory
            </Typography>
            <FormControl
              error={
                newProject.csvMapping.find((m) => m.header === 'cell_id')
                  ?.isCorrect === false
              }
              fullWidth
              size="small"
            >
              <InputLabel id="name-id-label">Select Name ID column</InputLabel>
              <Select
                labelId="name-id-label"
                id="name-id"
                label="Select Name ID column"
                defaultValue={''}
                value={mappingData?.nameColumn}
                onChange={(event) => {
                  dispatch(
                    addCSVMapping({
                      header: 'cell_id',
                      column: event?.target?.value ?? '',
                      isCorrect: true,
                    })
                  )
                  dispatch(updateNameColumn(event.target.value))
                }}
              >
                {newProject.sampleRows.length <= 0
                  ? null
                  : newProject.sampleRows[0].map((row, index) => (
                      <MenuItem key={`id_${index}`} value={index}>
                        {row}
                      </MenuItem>
                    ))}
              </Select>
            </FormControl>
            <FormControl
              error={
                newProject.csvMapping.find((m) => m.header === 'lat')
                  ?.isCorrect === false
              }
              fullWidth
              size="small"
            >
              <InputLabel id="latitue-label">Select Latitude column</InputLabel>
              <Select
                labelId="latitude-label"
                id="latitude"
                label="Select Latitude column"
                defaultValue={''}
                value={mappingData?.latColumn}
                onChange={(event) => {
                  const isCorrect = !Number.isNaN(
                    parseFloat(
                      newProject.sampleRows[1][parseInt(event.target.value)]
                    )
                  )
                  dispatch(
                    addCSVMapping({
                      header: 'input_lat',
                      column: event.target.value,
                      isCorrect,
                    })
                  )
                  dispatch(updateLatColumn(event.target.value))
                }}
              >
                {newProject.sampleRows.length <= 0
                  ? null
                  : newProject.sampleRows[0].map((row, index) => (
                      <MenuItem key={`late_${index}`} value={index}>
                        {row}
                      </MenuItem>
                    ))}
              </Select>
            </FormControl>
            <FormControl
              error={
                newProject.csvMapping.find((m) => m.header === 'lon')
                  ?.isCorrect === false
              }
              fullWidth
              size="small"
            >
              <InputLabel id="longitude-label">
                Select Longitude column
              </InputLabel>
              <Select
                labelId="longitude-label"
                id="longitude"
                label="Select Longitude column"
                defaultValue=""
                value={mappingData?.longColumn}
                onChange={(event) => {
                  const isCorrect = !Number.isNaN(
                    parseFloat(
                      newProject.sampleRows[1][parseInt(event.target.value)]
                    )
                  )
                  dispatch(
                    addCSVMapping({
                      header: 'input_long',
                      column: event.target.value,
                      isCorrect,
                    })
                  )
                  dispatch(updateLongColumn(event.target.value))
                }}
              >
                {newProject.sampleRows.length <= 0
                  ? null
                  : newProject.sampleRows[0].map((row, index) => (
                      <MenuItem key={`lon_${index}`} value={index}>
                        {row}
                      </MenuItem>
                    ))}
              </Select>
            </FormControl>

            <Typography textAlign="start" pb="5px" mt="15px">
              Optional
            </Typography>
            <FormControl
              // error={newProject.isError.has('country')}
              fullWidth
              size="small"
            >
              <InputLabel id="cluster-label">Select Cluster column</InputLabel>
              <Select
                labelId="cluster-label"
                id="cluster"
                label="Select Cluster column"
                defaultValue=""
                onChange={(event) => {
                  dispatch(updateClusterColumn(event.target.value))
                }}
              >
                <MenuItem value={'any'}>Any</MenuItem>
              </Select>
            </FormControl>
            <FormControl
              // error={newProject.isError.has('country')}
              fullWidth
              size="small"
            >
              <InputLabel id="market-label">Select Market column</InputLabel>
              <Select
                labelId="market-label"
                id="market"
                label="Select Market column"
                defaultValue=""
                onChange={(event) => {
                  dispatch(updateMarketColumn(event.target.value))
                }}
              >
                <MenuItem value={'charlotte'}>Charlotte</MenuItem>
              </Select>
            </FormControl>
            <FormControl
              // error={newProject.isError.has('country')}
              fullWidth
              size="small"
            >
              <InputLabel id="region-label">Select Region colum</InputLabel>
              <Select
                labelId="region-label"
                id="region"
                label="Select Region column"
                defaultValue=""
                onChange={(event) => {
                  dispatch(updateRegionColumn(event.target.value))
                }}
              >
                <MenuItem value={'downtown'}>Down Town</MenuItem>
              </Select>
            </FormControl>
            <FormControl
              // error={newProject.isError.has('country')}
              fullWidth
              size="small"
            >
              <InputLabel id="state-label">Select State column</InputLabel>
              <Select
                labelId="state-label"
                id="state"
                label="Select State column"
                defaultValue=""
                onChange={(event) => {
                  dispatch(updateCountryColumn(event.target.value))
                }}
              >
                <MenuItem value={'nc'}>North Carolina</MenuItem>
              </Select>
            </FormControl>
          </Stack>
          <Box flex={1} />
          <Stack
            flex={16}
            bgcolor="bg.200"
            borderRadius="20px"
            direction="column"
            justifyContent="space-between"
            alignItems="stretch"
            minHeight="280px"
            sx={{ borderRadius: '20px' }}
          >
            <TableContainer sx={{ flex: 95, borderRadius: '20px' }}>
              <Table stickyHeader size="small" aria-label="data-table">
                <TableHead>
                  <TableRow
                    sx={{
                      '& th': {
                        backgroundColor: 'bg.200',
                        borderTopRightRadius: '20px',
                        borderTopLeftRadius: '20px',
                        fontSize: '14px',
                        fontWeight: 400,
                        lineHeight: '19.6px',
                        py: '15px',
                        textAlign: 'start',
                        border: 0,
                      },
                    }}
                  >
                    <TableCell>System ID</TableCell>
                    <TableCell>Name</TableCell>
                    <TableCell>Lat</TableCell>
                    <TableCell>Long</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {newProject.sampleRows
                    .filter((_, i) => i !== 0)
                    .map((row, index) => {
                      const id = newProject.csvMapping.find(
                        (m) => m.header === 'cell_id'
                      )?.column
                      const late = newProject.csvMapping.find(
                        (m) => m.header === 'input_lat'
                      )
                      const lon = newProject.csvMapping.find(
                        (m) => m.header === 'input_long'
                      )
                      return (
                        <TableRow
                          key={index}
                          sx={{
                            '& td': {
                              backgroundColor: 'bg.300',
                              fontSize: '14px',
                              fontWeight: 400,
                              lineHeight: '19.6px',
                              textAlign: 'start',
                              border: 0,
                              py: '12px',
                            },
                          }}
                        >
                          <TableCell
                            key={`index_t_${index}`}
                            sx={{ color: 'textColor.300' }}
                          >
                            {index + 1}
                          </TableCell>
                          <TableCell
                            key={`id_t_${index}`}
                            sx={{ color: 'textColor.300' }}
                          >
                            {id !== undefined ? row[parseInt(id)] : ''}
                          </TableCell>
                          <TableCell
                            key={`lat_t_${index}`}
                            sx={{
                              color:
                                late?.isCorrect === false
                                  ? 'error.main'
                                  : 'textColor.300',
                            }}
                          >
                            {late?.column !== undefined
                              ? row[parseInt(late.column)]
                              : ''}
                          </TableCell>
                          <TableCell
                            key={`lon_t_${index}`}
                            sx={{
                              color:
                                lon?.isCorrect === false
                                  ? 'error.main'
                                  : 'textColor.300',
                            }}
                          >
                            {lon?.column !== undefined
                              ? row[parseInt(lon.column)]
                              : ''}
                          </TableCell>
                        </TableRow>
                      )
                    })}
                </TableBody>
              </Table>
            </TableContainer>
            <Box
              flex={5}
              display="flex"
              flexDirection="column"
              justifyContent="center"
            >
              <Typography
                sx={{
                  fontSize: '12px',
                  fontWeight: 300,
                  lineHeight: '16.8px',
                  color: 'textColor.300',
                }}
              >
                {newProject.sampleRows.length - 1} Rows
              </Typography>
            </Box>
          </Stack>
        </Box>
      </Box>
      <Box
        flex={13}
        display="flex"
        flexDirection="column"
        alignContent="stretch"
        justifyContent="center"
      >
        <Box flex={2} />
        <Box
          display="flex"
          flexDirection="row"
          justifyContent="stretch"
          flexGrow={1}
        >
          <Box
            flex={3}
            display="flex"
            flexDirection="row"
            justifyContent="center"
            alignItems="center"
          >
            <Button
              variant="contained"
              startIcon={<WestIcon sx={{ color: 'textColor.300' }} />}
              sx={{
                bgcolor: 'bg.300',
                textTransform: 'none',
                borderRadius: '8px',
                color: 'textColor.300',
                minWidth: '109px',
              }}
              onClick={() => {
                navigate(-1)
              }}
            >
              Back
            </Button>
          </Box>
          <Box
            flex={9}
            display="flex"
            flexDirection="row"
            justifyContent="stretch"
          >
            <ProjectStep step={2} />
          </Box>
          <Box
            flex={4}
            display="flex"
            flexDirection="row"
            justifyContent="end"
            alignItems="center"
            pr="20px"
          >
            <Button
              variant="contained"
              sx={{
                bgcolor: 'primary.main',
                textTransform: 'none',
                borderRadius: '8px',
                minWidth: '109px',
              }}
              onClick={() => {
                onSubmit()
                  .then(() => {})
                  .catch(() => {})
              }}
            >
              Create Project
            </Button>
          </Box>
        </Box>
        <Box flex={1} />
      </Box>
    </Box>
  )
}

export const ProInputMappingView: FC = () => {
  return (
    <ProtectedComponent noSidebar>
      <ProjectInputMapping />
    </ProtectedComponent>
  )
}
