import React from 'react'
import { Box, Typography, LinearProgress, IconButton } from '@mui/material'
import ShowLogsIcon from '../assets/svgs/view_logs_icon.svg'
import PauseIcon from '../assets/svgs/pause_icon.svg'
import RestartIcon from '../assets/svgs/restart_icon.svg'

interface TaskItemProps {
  taskId: string
  gpuId: string
  percentage: number
  onShowLogs: (task: string) => void
  onPause: (task: string) => void
  onRestart: (task: string) => void
}

const TaskItem: React.FC<TaskItemProps> = ({
  taskId,
  gpuId,
  percentage,
  onShowLogs,
  onPause,
  onRestart,
}) => {
  const handleShowLogs = React.useCallback(() => {
    onShowLogs(taskId)
  }, [taskId])

  const handleOnPause = React.useCallback(() => {
    onPause(taskId)
  }, [taskId])

  const handleOnRestart = React.useCallback(() => {
    onRestart(taskId)
  }, [taskId])

  return (
    <Box display="flex" flexDirection="row">
      <Box
        display="flex"
        flex={6}
        flexDirection="column"
        justifyContent="space-around"
      >
        <Box flexDirection="row" display="flex" justifyContent="space-between">
          <Box flexDirection="row" display="flex">
            <Typography
              sx={{
                fontSize: '14px',
                fontWeight: 400,
                lineHeight: '19.6px',
                color: 'white.200',
              }}
            >
              Task {taskId}/&nbsp;
            </Typography>
            <Typography
              sx={{
                fontSize: '14px',
                fontWeight: 400,
                lineHeight: '19.6px',
                color: 'textColor.200',
              }}
            >
              GPU: {gpuId}
            </Typography>
          </Box>
          <Typography
            sx={{
              fontSize: '14px',
              fontWeight: 400,
              lineHeight: '19.6px',
              color: 'white.200',
            }}
          >
            {percentage}%
          </Typography>
        </Box>
        <LinearProgress
          variant="determinate"
          sx={{
            backgroundColor: 'stroke.main',
            '& .MuiLinearProgress-bar': {
              backgroundColor: 'blue.100',
            },
          }}
          value={percentage}
        />
      </Box>
      <Box flex={4} display="flex" flexDirection="row">
        <Box flex={1} />
        <Box flex={2}>
          <IconButton onClick={handleShowLogs}>
            <img src={ShowLogsIcon} alt="Show Logs" />
          </IconButton>
        </Box>
        <Box flex={2}>
          <IconButton onClick={handleOnPause}>
            <img src={PauseIcon} alt="Pause" />
          </IconButton>
        </Box>
        <Box flex={2} onClick={handleOnRestart}>
          <IconButton>
            <img src={RestartIcon} alt="Restart" />
          </IconButton>
        </Box>
      </Box>
    </Box>
  )
}

export default TaskItem
