import * as React from 'react'
import Button from '@mui/material/Button'
import { styled } from '@mui/material/styles'
import Dialog from '@mui/material/Dialog'
import DialogTitle from '@mui/material/DialogTitle'
import DialogContent from '@mui/material/DialogContent'
import DialogActions from '@mui/material/DialogActions'
import Typography from '@mui/material/Typography'
import Slide from '@mui/material/Slide'
import { type TransitionProps } from '@mui/material/transitions'
import { SelectIcon, SelectIconOp } from './select_icon'
import {
  Box,
  FormControl,
  Grid,
  IconButton,
  InputLabel,
  MenuItem,
  Select,
} from '@mui/material'
import { SDSwitch } from './custom_switch'
import { SDCheckbox } from './checkbox'
import { useAppDispatch, useAppSelector } from '../store/hooks'
import {
  genReportSelector,
  setChartType,
  setIncludeCharts,
  setInsetCsvLink,
  setReportFormat,
  setSelectedProject,
} from '../store/gen_report'
import { chartTypesArray } from '../types/chartTypes'
import CloseIcon from '@mui/icons-material/Close'
import { validValue } from '../utils/functions'
import { errorToast } from '../utils/toast'

const Transition = React.forwardRef(function Transition(
  props: TransitionProps & {
    children: React.ReactElement<any, any>
  },
  ref: React.Ref<unknown>
) {
  return <Slide direction="down" ref={ref} {...props} />
})

const TransitionUp = React.forwardRef(function Transition(
  props: TransitionProps & {
    children: React.ReactElement<any, any>
  },
  ref: React.Ref<unknown>
) {
  return <Slide direction="up" ref={ref} {...props} />
})

const BootstrapDialog = styled(Dialog)(({ theme }) => ({
  '& .MuiDialogContent-root': {
    padding: theme.spacing(2),
    minWidth: '512px',
  },
  '& .MuiDialogActions-root': {
    padding: theme.spacing(1),
  },
}))

interface GenerateReportDialogProps {
  open: boolean
  onGenerateReport: () => void
  projectName: string
  projectId: string
  onCancel: () => void
}

interface ReportGeneratedDialogProps {
  open: boolean
  onDownload: () => void
}
const GenerateReportDialog: React.FC<GenerateReportDialogProps> = (props) => {
  // Implement your component logic here
  const { open, onGenerateReport, onCancel } = props
  const [showChartTypeList, setShowChartTypeList] = React.useState(false)
  // const project = useAppSelector(newProjectSelector)
  const reportConfig = useAppSelector(genReportSelector)
  const dispatch = useAppDispatch()

  const handleClose = () => {
    onCancel()
  }
  const submit = () => {
    if (!validValue(reportConfig.reportFormat)) {
      errorToast('Please select report type')
      return
    }
    if (reportConfig.reportFormat === 'pdf') {
      if (reportConfig.chartType.length < 1) {
        errorToast('Please select some charts')
        return
      }
    }
    onGenerateReport()
  }

  const chartList = React.useCallback(() => {
    return chartTypesArray.map((chartType) => {
      return (
        <Grid
          container
          justifyContent="space-between"
          alignItems="center"
          key={chartType}
        >
          <Grid item md={1}>
            <SDCheckbox
              onChange={(event) => {
                dispatch(setChartType(chartType))
              }}
            />
          </Grid>
          <Grid
            item
            container
            md={11}
            justifyContent="space-between"
            direction="row"
            onClick={() => {
              dispatch(setChartType(chartType))
            }}
          >
            <Typography
              sx={{
                fontSize: '14px',
                fontWeight: 400,
                lineHeight: '19.6px',
              }}
            >
              {chartType}
            </Typography>
          </Grid>
        </Grid>
      )
    })
  }, [])

  return (
    <BootstrapDialog
      onClose={handleClose}
      aria-labelledby="gen-rep-dialog-title"
      TransitionComponent={Transition}
      scroll="body"
      PaperProps={{
        elevation: 0,
        sx: { borderRadius: '20px' },
      }}
      slotProps={{
        backdrop: { sx: { background: 'rgba(0, 0, 0, 0.7)' } },
      }}
      open={open}
    >
      <DialogTitle
        sx={{
          mx: '32px',
          mt: '24px',
          p: 0,
          fontWeight: 600,
          fontSize: '24px',
        }}
        id="gen-rep-dialog-title"
      >
        Generate Report
      </DialogTitle>
      <DialogContent dividers sx={{ mx: '24px', mb: '24p' }}>
        <FormControl error={false} fullWidth size="small" sx={{ my: '16px' }}>
          <InputLabel id="project-label">Project</InputLabel>
          <Select
            labelId="project-label"
            id="project-type"
            label="Project"
            defaultValue=""
            value={props.projectId}
            onChange={(event) => {
              dispatch(setSelectedProject(props.projectId))
            }}
          >
            <MenuItem value={props.projectId}>{props.projectName}</MenuItem>
          </Select>
        </FormControl>
        <Grid my="16px">
          <Typography
            sx={{
              fontSize: '18px',
              fontWeight: 400,
              lineHeight: '24.3px',
            }}
          >
            Charts
          </Typography>
          <Grid container justifyContent="space-between" alignItems="center">
            <Grid item md={1}>
              <SDCheckbox
                onChange={(event) => {
                  dispatch(setIncludeCharts(event.target.checked))
                  if (!event.target.checked) {
                    setShowChartTypeList(false)
                    dispatch(setChartType(''))
                  } else {
                    setShowChartTypeList(true)
                  }
                }}
              />
            </Grid>
            <Grid
              item
              container
              md={11}
              justifyContent="space-between"
              direction="row"
              onClick={() => {
                setShowChartTypeList((prev) => !prev)
              }}
            >
              <Typography
                sx={{
                  fontSize: '14px',
                  fontWeight: 400,
                  lineHeight: '19.6px',
                }}
              >
                Include charts
              </Typography>
              {showChartTypeList ? <SelectIconOp /> : <SelectIcon />}
            </Grid>
          </Grid>
        </Grid>
        {!showChartTypeList ? null : (
          <Grid container justifyContent="space-between">
            <Grid item md={12} height="1px" bgcolor="stroke.main"></Grid>
            {chartList()}
          </Grid>
        )}
        <Grid my="16px">
          <Typography
            sx={{
              fontSize: '18px',
              fontWeight: 400,
              lineHeight: '24.3px',
            }}
          >
            Report Format
          </Typography>
          <FormControl error={false} fullWidth size="small" sx={{ mt: '8px' }}>
            <Select
              labelId="file-format-label"
              id="file-format"
              label="File format"
              defaultValue={''}
              onChange={(event) => {
                dispatch(setReportFormat(event.target.value))
              }}
            >
              <MenuItem value={'csv'}>CSV</MenuItem>
              <MenuItem value={'pdf'}>PDF</MenuItem>
            </Select>
          </FormControl>
        </Grid>
        <Grid item container direction="row" my="20px">
          <SDSwitch
            onChange={(event) => {
              dispatch(setInsetCsvLink(event.target.checked))
            }}
          />
          <Typography
            sx={{
              pl: '8px',
              fontWeight: 400,
              fontSize: '18px',
              lineHeight: '24.3px',
            }}
          >
            Insert Link to CSV
          </Typography>
        </Grid>
        <Grid container direction="row" justifyContent="flex-end" mt="16px">
          <Button variant="contained" onClick={submit}>
            Generate Report
          </Button>
        </Grid>
      </DialogContent>
      <DialogActions></DialogActions>
    </BootstrapDialog>
  )
}

const ReportGeneratedDialog: React.FC<ReportGeneratedDialogProps> = (props) => {
  const { open, onDownload } = props
  const handleClose = () => {
    // setOpen(false)
  }

  return (
    <Dialog
      onClose={handleClose}
      aria-labelledby="rep-gen-dialog-title"
      TransitionComponent={TransitionUp}
      scroll="body"
      PaperProps={{
        elevation: 0,
        sx: { borderRadius: '20px' },
      }}
      slotProps={{
        backdrop: { sx: { background: 'rgba(0, 0, 0, 0.7)' } },
      }}
      open={open}
    >
      <DialogContent
        dividers
        sx={{
          pt: 0,
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'stretch',
          width: '308px',
        }}
      >
        <Box height="100px">
          <Box display="flex" flexDirection="row" justifyContent="flex-end">
            <IconButton>
              <CloseIcon
                sx={{
                  color: 'textColor.300',
                  fontSize: '18px',
                  mr: '8px',
                  mt: '8px',
                }}
              />
            </IconButton>
          </Box>
          <Box
            flex={1}
            flexGrow={1}
            display="flex"
            flexDirection="row"
            justifyContent="center"
            alignItems="center"
          >
            <Typography
              sx={{
                fontSize: '18px',
                fontWeight: 400,
              }}
            >
              Report is Generated!
            </Typography>
          </Box>
        </Box>
        <Box
          display="flex"
          flexDirection="row"
          justifyContent="center"
          alignItems="center"
        >
          <Button
            variant="contained"
            onClick={onDownload}
            sx={{
              width: '208px',
              textTransform: 'none',
              borderRadius: '8px',
            }}
          >
            Download
          </Button>
        </Box>
      </DialogContent>
    </Dialog>
  )
}

export default GenerateReportDialog
export { ReportGeneratedDialog }
