/* eslint-disable */
import {
  Box,
  Button,
  CircularProgress,
  IconButton,
  Toolbar,
  Typography,
} from '@mui/material'
import React, { forwardRef, useImperativeHandle } from 'react'
import NoRunIcon from '../assets/svgs/no_run_icon.svg'
import { useNavigate } from 'react-router-dom'
import {
  Cartesian3,
  Color,
  MapboxStyleImageryProvider,
  type Viewer as CesiumView,
} from 'cesium'
import {
  BillboardGraphics,
  Camera,
  CameraFlyTo,
  type CesiumComponentRef,
  Entity,
  ImageryLayer,
  PolygonGraphics,
  Viewer,
} from 'resium'
import SPBarChart, { ChartProps } from './barchart'
import {
  newProjectSelector,
  setChartData,
  setOutputData,
  setPolygonPoints,
} from '../store/new_project'
import { useAppDispatch, useAppSelector } from '../store/hooks'
import {
  useGetFileMutation,
  useGetProjectQuery,
  useTriggerFileMutation,
} from '../services/project_services'
import { errorToast, successToast, warningToast } from '../utils/toast'
import {
  downloadAndParseCSV,
  formatDate,
  humanize,
  notNull,
  openInStreetView,
  parsePolygon,
  percentageOf,
  prepareMeta,
  toFixedDown,
  validValue,
} from '../utils/functions'
import { generateChartData } from '../utils/chart_data_fun'
import { mapboxConfig } from '../utils/constants'
import { useQuery } from '../utils/parsers'
import Dialog from '@mui/material/Dialog'
import CloseIcon from '@mui/icons-material/Close'
import { authSelector, logout } from '../store/auth'
import type { GeneratedChartData } from '../types/output_object'
import html2canvas from 'html2canvas'
import jsPDF from 'jspdf'
import { genReportSelector, reset } from '../store/gen_report'
import { realMapping } from '../types/chartTypes'
import ReplayIcon from '@mui/icons-material/Replay'

export type OutputHandle = {
  downloadReport: () => void
}

type Props = {}

const OutputTab: React.FC = forwardRef<OutputHandle, Props>((props, ref) => {
  const query = useQuery()
  const projectId = query.get('project')
  const [isCesiumInit, setIsCesiumInit] = React.useState<boolean>(false)
  const viewer = React.useRef<CesiumView>()
  const auth = useAppSelector(authSelector)
  const reportConfig = useAppSelector(genReportSelector)
  const newProject = useAppSelector(newProjectSelector)
  const container = React.useRef<HTMLDivElement>(null)
  const chartContainer = React.useRef<HTMLDivElement>(null)
  const cesiumContainer = React.useRef<HTMLDivElement>()
  const [selectedPoint, setSelectedPoint] = React.useState<any>(null)
  const {
    // eslint-disable-next-line
    data: projectInfo,
    // eslint-disable-next-line
    error,
    // eslint-disable-next-line
    isLoading,
    // eslint-disable-next-line
    refetch,
  } = useGetProjectQuery(projectId?.toString() ?? '')
  const navigate = useNavigate()
  const dispatch = useAppDispatch()
  const [getFile, { isLoading: isFetchingFile, data: file }] =
    useGetFileMutation()
  const effectCalled = React.useRef(false)
  const [fullScreenChart, setFullScreenChart] = React.useState<{
    open: boolean
    props: ChartProps | null
  }>({ open: false, props: null })
  const [charPreparedData, setCharPreparedData] = React.useState<ChartProps[]>(
    []
  )
  const [containerSize, setContainerSize] = React.useState({
    width: container.current?.offsetWidth ?? 0,
    height: container.current?.offsetHeight ?? 0,
  })
  const [isFileIssue, setIsFileIssue] = React.useState(false)
  const imgProvider = React.useMemo(
    () => new MapboxStyleImageryProvider(mapboxConfig),
    []
  )
  const summary = [
    {
      title: 'Project created',
      val: validValue(projectInfo?.created_at)
        ? formatDate(projectInfo?.created_at ?? '')
        : 'N/A',
    },
    {
      title: 'Market',
      val: validValue(projectInfo?.market) ? projectInfo?.market : 'N/A',
    },
    { title: 'Asset type ', val: 'Joint Utility Pole' },
    { title: 'Input Rows', val: projectInfo?.files[0]?.num_of_rows ?? '' },
    {
      title: 'Source file',
      val: projectInfo?.files[0]?.original_file_name ?? '',
    },
    { title: 'Number of GPUs', val: projectInfo?.gpu_count ?? '1' },
    { title: 'Output file', val: 'download' },
  ]
  const handleChartClose = () => {
    setFullScreenChart({ open: false, props: null })
  }

  const downloadFile = (showDownload = false) => {
    if (
      projectInfo !== undefined &&
      projectInfo?.files.length > 0 &&
      projectInfo?.files[0].output_file_s3_path !== null
    ) {
      setIsFileIssue(false)
      getFile(projectInfo?.files[0]?.id?.toString())
        .unwrap()
        .then((response: any) => {
          if (response?.status === 401 || response.data?.status === 401) {
            dispatch(logout())
            navigate('/login')
            setTimeout(() => {
              warningToast('Session expired, please login again')
            }, 500)
            return
          }
          // eslint-disable-next-line
          downloadAndParseCSV(response.output_file_s3_path)
            .then((data) => {
              const [jsonData, csvData] = data
              // console.log(jsonData) // Process the parsed JSON data here
              if (showDownload) {
                const element = document.createElement('a')
                const file = new Blob([csvData.toString()], {
                  type: 'text/plain',
                })
                element.href = URL.createObjectURL(file)
                const fileName =
                  `${projectInfo.name}_${projectInfo?.files[0].id}`.replaceAll(
                    ' ',
                    '_'
                  )
                element.download = `${fileName}_output.csv`
                document.body.appendChild(element) // Required for this to work in FireFox
                element.click()
                successToast('File downloaded successfully')
              } else {
                // @ts-expect-error csv to ts interface
                dispatch(setChartData(generateChartData(jsonData)))
                // no lim load all point on map
                // if (jsonData.length > 50) {
                //   sample = jsonData.slice(0, 50)
                // }
                dispatch(setOutputData(jsonData))
                setIsFileIssue(false)
                successToast('Fetched output successfully')
              }
            })
            .catch((error) => {
              errorToast('Error downloading or parsing CSV')
              console.error('Error downloading or parsing CSV:', error)
              setIsFileIssue(true)
            })
        })
        .catch((err: any) => {
          if (err.error?.status === 401 || err.data?.status === 401) {
            dispatch(logout())
            navigate('/login')
            setTimeout(() => {
              warningToast('Session expired, please login again')
            }, 500)
            return
          }
          const errMsg = err != null && 'data' in err ? err.data : {}
          const msg = errMsg?.detail ?? 'Some error occurred'

          errorToast(`${msg}`)
          setIsFileIssue(true)
        })
    } else {
      errorToast('Unable to download the file')
      setIsFileIssue(true)
      // for investigation
      console.info(`
        projectInfo: ${projectInfo !== undefined},
        files: ${projectInfo?.files != undefined && projectInfo?.files?.length > 0},
        output_file_s3_path: ${projectInfo?.files[0].output_file_s3_path !== null}
        whole: (${
          projectInfo !== undefined &&
          projectInfo?.files.length > 0 &&
          projectInfo?.files[0].output_file_s3_path !== null
        })
      `)
    }
  }

  const prepareChartsProps = (data: GeneratedChartData | null) => {
    if (data == null) return []
    let props = Object.entries(data).map((item: any): ChartProps => {
      const [key, value] = item
      return {
        key,
        type: ['polesChartData', 'polesWithEquipmentsChartData'].includes(key)
          ? 'pie'
          : 'bar',
        data: value,
        width: percentageOf(containerSize.width, 38),
        height: 213,
        title: humanize(key),
        name: `[${projectInfo?.files[0]?.id}]-SD-${key}`,
      }
    })
    props = props.map((item: ChartProps) => {
      item.onMaximize = () => {
        if (item.data == null) return
        setFullScreenChart({
          open: true,
          props: {
            ...item,
            isMaximized: true,
            width: percentageOf(document.documentElement.offsetWidth, 90),
            height: percentageOf(document.documentElement.offsetHeight, 70),
          },
        })
      }
      return item
    })

    return props
  }

  const downloadReport = () => {
    if (projectInfo === undefined) {
      return
    }
    if (reportConfig.reportFormat === 'csv') {
      downloadFile(true)
      return
    }
    const list = Array.from(new Set(reportConfig.chartType))
    // @ts-expect-error "any" is not defined
    const chartIds = list.map((item) => realMapping[item])

    const hiddenDiv = document.createElement('div')
    hiddenDiv.style.position = 'absolute'
    hiddenDiv.style.left = '-99999px'
    // // @ts-expect-error "html2canvas" is not defined
    // hiddenDiv.firstChild.style.height = '2080px'

    // @ts-expect-error "html2canvas" is not defined
    const content = prepareMeta(projectInfo)

    content.forEach((item) => {
      hiddenDiv.appendChild(item)
    })
    const table = document.createElement('table')
    table.style.width = '100%'
    //table.style.width = '210mm'
    table.style.height = '300mm'
    table.style.marginLeft = '10px'
    table.style.marginRight = '10px'
    table.style.marginBottom = '20px'
    let newRow = document.createElement('tr')
    chartIds.forEach((id, i) => {
      const cell = document.createElement('td')
      const div = document.createElement('div')
      div.style.display = 'flex'
      div.style.flexDirection = 'column'
      div.style.justifyContent = 'center'
      div.style.alignItems = 'center'
      // @ts-expect-error "html2canvas" is not defined
      div.appendChild(document.getElementById(id).cloneNode(true))
      cell.appendChild(div)
      if (i === 0) {
        cell.style.paddingTop = '20px'
      }
      newRow.appendChild(cell)
      if (i % 2 !== 0 && i !== 0) {
        table.appendChild(newRow)
        newRow = document.createElement('tr')
      }
    })
    hiddenDiv.appendChild(table)
    // hiddenDiv.firstChild.style.height = '2080px'
    document.body.appendChild(hiddenDiv)

    html2canvas(hiddenDiv).then((canvas) => {
      const imgData = canvas.toDataURL('image/png')
      const pdf = new jsPDF({
        orientation: 'p', // landscape
        unit: 'pt', // points, pixels won't work properly
        format: [canvas.width, canvas.height], // set needed dimensions for any element
      })
      pdf.addImage(imgData, 'JPEG', 0, 0, canvas.width, canvas.height)
      if (reportConfig.insetCsvLink) {
        pdf.setFontSize(15)
        pdf.textWithLink('Output CsvFile', 10, canvas.height - 10, {
          url:
            file?.output_file_s3_path ??
            projectInfo?.files[0].output_file_s3_path,
          fontSize: 15,
          textAlignment: 'center',
        })
      }
      dispatch(reset())
      pdf.save(`SD-Report [${projectInfo.files[0].id}] ${projectInfo.name}.pdf`)
    })
  }

  React.useEffect(() => {
    if (newProject.chartData == null || containerSize.width == 0) return
    setCharPreparedData(prepareChartsProps(newProject.chartData))
  }, [newProject.chartData, containerSize.width])

  // To Prevent the React.StrictMode Effect on cesium
  React.useEffect(() => {
    if (!isCesiumInit) {
      setTimeout(() => {
        setIsCesiumInit(true)
      }, 1000)
    }
  }, [])

  React.useEffect(() => {
    setContainerSize({
      width: 0,
      height: 0,
    })
    setTimeout(() => {
      setContainerSize({
        width: container.current?.offsetWidth ?? 0,
        height: container.current?.offsetHeight ?? 0,
      })
    }, 1000)
  }, [auth.isSidebarOpen])

  React.useEffect(() => {
    if (
      projectInfo !== undefined &&
      projectInfo?.files.length > 0 &&
      projectInfo?.files[0].process_status === 'Processing'
    ) {
      setTimeout(() => {
        navigate(`/run_analysis?project=${projectId}`)
      }, 100)
    }
  }, [projectInfo?.files[0]?.process_status])

  React.useEffect(() => {
    if (
      projectInfo?.files[0]?.output_file_s3_path !== null &&
      !isFetchingFile
    ) {
      if (!effectCalled.current || newProject.chartData == null) {
        effectCalled.current = true
      } else {
        return
      }
      downloadFile()
    }
  }, [projectInfo?.files[0]?.output_file_s3_path])

  if (
    newProject.polygonPoints.length < 1 &&
    projectInfo?.is_polygon === true &&
    newProject.polygonPoints.length < 1
  ) {
    // @ts-expect-error "html2canvas" is not defined
    dispatch(setPolygonPoints(parsePolygon(projectInfo.polygon_coordinates)))
  }

  useImperativeHandle(ref, () => ({
    downloadReport,
  }))

  const initCesium = (e: CesiumComponentRef<CesiumView> | null) => {
    if (e != null) {
      if (e.cesiumElement !== viewer.current && e.cesiumElement != null) {
        viewer.current = e.cesiumElement
      }
    }
  }

  if (isLoading) {
    return (
      <Box
        display="flex"
        flex={1}
        justifyContent="center"
        alignItems="center"
        className="h-screen"
      >
        <CircularProgress />
      </Box>
    )
  }

  if (
    projectInfo !== undefined &&
    projectInfo?.files.length > 0 &&
    (projectInfo?.files[0].process_status === 'Pending' ||
      projectInfo?.files[0].output_file_s3_path === null)
  ) {
    return (
      <StartRunButton
        fileId={projectInfo?.files[0].id}
        projectId={projectId ?? ''}
      />
    )
  }

  return (
    <Box
      display="flex"
      flex={1}
      flexDirection="column"
      bgcolor="bg.200"
      borderRadius="20px"
      p="20px"
      sx={{
        overflow: 'hidden',
      }}
      ref={container}
    >
      <Box flex={12} display="flex" flexDirection="column">
        <Box flex={1.2}>
          <Typography
            sx={{
              fontSize: '18px',
              fontWeight: 400,
              lineHeight: '24.3px',
              textAlign: 'start',
            }}
          >
            Output summary
          </Typography>
        </Box>
        <Box flex={1} display="flex" flexDirection="row">
          {summary.map((item, index) => {
            const isLast = index === summary.length - 1
            const sx = item.title === 'Source file' ? { maxWidth: '40ch' } : {}
            return (
              <React.Fragment key={index}>
                <Box
                  flex={1}
                  display="flex"
                  flexDirection="column"
                  alignItems="start"
                  sx={{
                    cursor: isLast ? 'pointer' : 'default',
                  }}
                  onClick={() => {
                    if (!isLast) return
                    downloadFile(true)
                  }}
                >
                  <Typography
                    sx={{
                      flex: 1.2,
                      fontSize: '12px',
                      fontWeight: 300,
                      lineHeight: '16.8px',
                      color: 'textColor.300',
                    }}
                  >
                    {item.title}
                  </Typography>
                  <Typography
                    sx={{
                      flex: 1,
                      fontSize: '14px',
                      fontWeight: 400,
                      lineHeight: '19.6px',
                      textDecoration: isLast ? 'underline' : 'none',
                      ...sx,
                    }}
                  >
                    {item.val}
                  </Typography>
                </Box>
                {!isLast ? (
                  <Box
                    flex={0.4}
                    display="flex"
                    flexDirection="column"
                    justifyContent="center"
                    alignItems="center"
                  >
                    <Box
                      sx={{
                        width: '2px',
                        bgcolor: 'stroke.main',
                        height: '40%',
                      }}
                    />
                  </Box>
                ) : null}
              </React.Fragment>
            )
          })}
        </Box>
      </Box>
      <Box flex={6} />
      <Box
        flex={77}
        display="flex"
        flexDirection="row"
        alignItems="stretch"
        sx={{
          overflow: 'hidden',
          borderRadius: '20px',
        }}
      >
        <Box
          ref={cesiumContainer}
          sx={{
            ...(containerSize.width === 0
              ? {
                  flex: 6,
                }
              : { width: percentageOf(containerSize.width, 58) }),
          }}
          display="flex"
          flexDirection="column"
          justifyContent="stretch"
          alignItems="stretch"
        >
          <div
            style={{
              flex: 1,
              borderRadius: '20px',
              overflow: 'hidden',
            }}
          >
            {isCesiumInit ? (
              <Viewer
                ref={initCesium}
                fullscreenButton={false}
                homeButton={false}
                sceneModePicker={true}
                selectionIndicator={true}
                timeline={false}
                animation={false}
                geocoder={false}
                infoBox={false}
                onSelectedEntityChange={(e) => {
                  if (notNull(e?.description)) {
                    // @ts-expect-error type
                    const [type, index] = e.description.toString().split('_')
                    const data = newProject.outputSample[parseInt(index)]
                    const id = notNull(data?.user_id)
                      ? data.user_id
                      : data?.cell_id
                    if (notNull(id)) {
                      setSelectedPoint({ ...data, isIn: type === 'in', id })
                    }
                  }
                }}
                onTrackedEntityChange={(e) => {}}
                style={{
                  height: '100%',
                }}
              >
                <ImageryLayer imageryProvider={imgProvider} />
                <Camera />
                {newProject.outputSample != null &&
                newProject.outputSample.length > 0 &&
                !Number.isNaN(newProject.outputSample[0].calculated_long) &&
                !Number.isNaN(newProject.outputSample[0].calculated_lat) ? (
                  <>
                    <CameraFlyTo
                      destination={Cartesian3.fromDegrees(
                        +newProject.outputSample[0].calculated_long,
                        +newProject.outputSample[0].calculated_lat,
                        6000
                      )}
                      duration={2}
                      once
                    />
                    {newProject.outputSample.map((point: any, i: any) => {
                      if (
                        !notNull(point?.input_lat) ||
                        !notNull(point?.input_long)
                      )
                        return <></>
                      return (
                        <Entity
                          key={point.user_id}
                          position={Cartesian3.fromDegrees(
                            +point.input_long,
                            +point.input_lat,
                            2
                          )}
                          description={`in_${i}`}
                        >
                          <BillboardGraphics
                            image={'assets/input_marker.svg'}
                          />
                        </Entity>
                      )
                    })}
                    {newProject.outputSample.map((point: any, i: any) => {
                      if (
                        !notNull(point?.calculated_lat) ||
                        !notNull(point?.calculated_long)
                      )
                        return <></>
                      return (
                        <Entity
                          key={`${point.user_id}-out`}
                          position={Cartesian3.fromDegrees(
                            +point.calculated_long,
                            +point.calculated_lat,
                            2
                          )}
                          description={`out_${i}`}
                        >
                          <BillboardGraphics
                            image={'assets/output_marker.svg'}
                          />
                        </Entity>
                      )
                    })}
                    {newProject.polygonPoints.map((point, index) => (
                      <Entity
                        key={index}
                        position={point}
                        point={{
                          color: Color.BLUE,
                          pixelSize: 8,
                          outlineColor: Color.WHITE,
                          outlineWidth: 1,
                        }}
                      />
                    ))}
                    {newProject.polygonPoints.length > 1 ? (
                      <Entity
                        name="PolygonInputOut"
                        description="PolygonInputOut"
                      >
                        <PolygonGraphics
                          hierarchy={newProject.polygonPoints}
                          outlineColor={Color.BLUE}
                          outlineWidth={5}
                          outline={true}
                          show={true}
                          material={Color.fromCssColorString(
                            'rgba(0, 0, 255, 0.3)'
                          )}
                        />
                      </Entity>
                    ) : null}
                  </>
                ) : null}
              </Viewer>
            ) : (
              <CircularProgress />
            )}
          </div>
        </Box>
        <Box flex={0.15} />
        <Box
          display="flex"
          flexDirection="column"
          alignItems="center"
          maxHeight="70vh"
          ref={chartContainer}
          sx={{
            overflowY: 'scroll',
            // scrollbarWidth: 'none',
            // '&::-webkit-scrollbar': {
            //   display: 'none',
            // },
            // '&-ms-overflow-style:': {
            //   display: 'none',
            // },
            ...(containerSize.width === 0
              ? {
                  flex: 4,
                }
              : { width: percentageOf(containerSize.width, 42) }),
          }}
        >
          {newProject.chartData == null || containerSize.width === 0 ? (
            isFileIssue ? (
              <IconButton onClick={() => downloadFile()}>
                <ReplayIcon />
              </IconButton>
            ) : (
              <CircularProgress />
            )
          ) : (
            <>
              {charPreparedData.map((item, i) => (
                <Box
                  id={item.key}
                  key={item.key}
                  flex={1}
                  mt={i === 0 ? undefined : '20px'}
                >
                  <SPBarChart {...item} />
                </Box>
              ))}
            </>
          )}
        </Box>
      </Box>
      <Dialog fullScreen open={fullScreenChart.open} onClose={handleChartClose}>
        <Box>
          <Toolbar>
            <IconButton
              edge="start"
              color="inherit"
              onClick={handleChartClose}
              aria-label="close"
            >
              <CloseIcon />
            </IconButton>
            <Typography sx={{ ml: 2, flex: 1 }} variant="h6" component="div">
              Project: {projectInfo?.name ?? ''}
            </Typography>
          </Toolbar>
          <Box display="flex" flexDirection="column" alignItems="center">
            {fullScreenChart.props != null ? (
              <SPBarChart {...fullScreenChart.props} />
            ) : null}
          </Box>
        </Box>
      </Dialog>
      {selectedPoint != null ? (
        <Box
          sx={{
            position: 'absolute',
            top: cesiumContainer.current?.getBoundingClientRect().top,
            left: cesiumContainer.current?.getBoundingClientRect().left,
            right: cesiumContainer.current?.getBoundingClientRect().right,
            minHeight: cesiumContainer.current?.getBoundingClientRect().height,
            minWidth: cesiumContainer.current?.getBoundingClientRect().width,
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'center',
            alignItems: 'center',
          }}
        >
          <Box
            sx={{
              borderRadius: '8px',
              bgcolor: 'bg.200',
              display: 'flex',
              flexDirection: 'column',
              minWidth: '312px',
              px: '16px',
              py: '16px',
            }}
          >
            <Box
              display="flex"
              flexDirection="row"
              justifyContent="space-between"
              alignItems="center"
            >
              <Typography
                sx={{
                  fontSize: '18px',
                  fontWeight: 400,
                  lineHeight: '24.3px',
                  color: 'white.200',
                }}
              >
                {selectedPoint.id}
              </Typography>

              <IconButton
                sx={{ p: 0 }}
                onClick={() => {
                  setSelectedPoint(null)
                }}
              >
                <CloseIcon
                  sx={{
                    color: 'textColor.300',
                    fontSize: '18px',
                  }}
                />
              </IconButton>
            </Box>
            <Box
              display="flex"
              flexDirection="row"
              justifyContent="space-between"
              alignItems="center"
              mt={'16px'}
            >
              <Box display="flex" flexDirection="column">
                <Typography
                  sx={{
                    fontSize: '12px',
                    fontWeight: 300,
                    lineHeight: '16.8px',
                    color: 'blue.200',
                    mb: '8px',
                    textAlign: 'start',
                  }}
                >
                  ID
                </Typography>
                <Typography
                  sx={{
                    fontSize: '14px',
                    fontWeight: 400,
                    lineHeight: '19.6px',
                    color: 'white.200',
                    textAlign: 'start',
                  }}
                >
                  {selectedPoint.id}
                </Typography>
              </Box>
              <Box display="flex" flexDirection="column">
                <Typography
                  sx={{
                    fontSize: '12px',
                    fontWeight: 300,
                    lineHeight: '16.8px',
                    color: 'blue.200',
                    mb: '8px',
                    textAlign: 'start',
                  }}
                >
                  Latitude
                </Typography>
                <Typography
                  sx={{
                    fontSize: '14px',
                    fontWeight: 400,
                    lineHeight: '19.6px',
                    color: 'white.200',
                    textAlign: 'start',
                  }}
                >
                  {toFixedDown(
                    selectedPoint.isIn
                      ? +selectedPoint.input_lat
                      : +selectedPoint.calculated_lat,
                    6
                  )}
                </Typography>
              </Box>
              <Box display="flex" flexDirection="column">
                <Typography
                  sx={{
                    fontSize: '12px',
                    fontWeight: 300,
                    lineHeight: '16.8px',
                    color: 'blue.200',
                    mb: '8px',
                    textAlign: 'start',
                  }}
                >
                  Longitude
                </Typography>
                <Typography
                  sx={{
                    fontSize: '14px',
                    fontWeight: 400,
                    lineHeight: '19.6px',
                    color: 'white.200',
                    textAlign: 'start',
                  }}
                >
                  {toFixedDown(
                    selectedPoint?.isIn
                      ? selectedPoint.input_long
                      : selectedPoint.calculated_long,
                    6
                  )}
                </Typography>
              </Box>
            </Box>
            <Box
              display="flex"
              flexDirection="row"
              justifyContent="space-between"
              alignItems="center"
              mt={'16px'}
            >
              <Box display="flex" flexDirection="column">
                <Typography
                  sx={{
                    fontSize: '12px',
                    fontWeight: 300,
                    lineHeight: '16.8px',
                    color: 'blue.200',
                    mb: '8px',
                    textAlign: 'start',
                  }}
                >
                  Processed Date
                </Typography>
                <Typography
                  sx={{
                    fontSize: '14px',
                    fontWeight: 400,
                    lineHeight: '19.6px',
                    color: 'white.200',
                    textAlign: 'start',
                  }}
                >
                  {formatDate(projectInfo.created_at)}
                </Typography>
              </Box>
              <Box display="flex" flexDirection="column">
                <Typography
                  sx={{
                    fontSize: '12px',
                    fontWeight: 300,
                    lineHeight: '16.8px',
                    color: 'blue.200',
                    mb: '8px',
                    textAlign: 'start',
                  }}
                >
                  Estimated Pole Height
                </Typography>
                <Typography
                  sx={{
                    fontSize: '14px',
                    fontWeight: 400,
                    lineHeight: '19.6px',
                    color: 'white.200',
                    textAlign: 'start',
                  }}
                >
                  {toFixedDown(selectedPoint?.pole_height_meter ?? 0, 4)}m
                </Typography>
              </Box>
            </Box>
            <Box
              display="flex"
              flexDirection="row"
              justifyContent="space-between"
              alignItems="center"
              mt={'16px'}
            >
              <Box display="flex" flexDirection="column">
                <Typography
                  sx={{
                    fontSize: '12px',
                    fontWeight: 300,
                    lineHeight: '16.8px',
                    color: 'blue.200',
                    mb: '8px',
                    textAlign: 'start',
                  }}
                >
                  Number of Strands
                </Typography>
                <Typography
                  sx={{
                    fontSize: '14px',
                    fontWeight: 400,
                    lineHeight: '19.6px',
                    color: 'white.200',
                    textAlign: 'start',
                  }}
                >
                  {selectedPoint?.number_of_strands}
                </Typography>
              </Box>
            </Box>
            <Box
              mt={{
                md: '12px',
                lg: '24px',
              }}
              display="flex"
              flexDirection="column"
              alignItems="stretch"
            >
              <Button
                variant="contained"
                sx={{
                  height: '100%',
                  borderRadius: '8px',
                  backgroundColor: 'bg.300',
                  color: 'textColor.300',
                  textTransform: 'none',
                  fontSize: {
                    md: '12px',
                    lg: '14px',
                  },
                  fontWeight: 400,
                  lineHeight: '19.6px',
                  py: '8px',
                }}
                onClick={() => {
                  const cord = {
                    latitude: selectedPoint?.isIn
                      ? +selectedPoint?.input_lat
                      : +selectedPoint?.calculated_lat,
                    longitude: selectedPoint?.isIn
                      ? +selectedPoint?.input_long
                      : +selectedPoint?.calculated_long,
                  }
                  openInStreetView(cord)
                }}
              >
                Open in Street View
              </Button>
            </Box>
          </Box>
        </Box>
      ) : null}
    </Box>
  )
})

interface StartRunButtonProps {
  fileId: number
  projectId?: string
}

const StartRunButton: React.FC<StartRunButtonProps> = (
  props: StartRunButtonProps
) => {
  const navigate = useNavigate()
  const [triggerCall, { isLoading }] = useTriggerFileMutation()

  return (
    <Box display="flex" flex={1} flexDirection="column" justifyContent="center">
      <Box>
        <img src={NoRunIcon} />
      </Box>
      <Box pt="8px">
        <Typography
          sx={{
            fontSize: '24px',
            fontWeight: 600,
            lineHeight: '33.4px',
          }}
        >
          No Runs Executed Yet
        </Typography>
      </Box>
      <Box pt="6px">
        <Typography
          sx={{
            fontSize: '14px',
            fontWeight: 400,
            lineHeight: '19.6px',
            color: 'textColor.300',
          }}
        >
          Start a run to view results.
        </Typography>
      </Box>
      <Box pt="20px">
        {isLoading ? (
          <CircularProgress />
        ) : (
          <Button
            variant="contained"
            onClick={() => {
              triggerCall(+props?.fileId ?? -1)
                .unwrap()
                .then(() => {
                  successToast('File queued for processing')
                  setTimeout(() => {
                    navigate(`/run_analysis?project=${props?.projectId}`)
                  }, 500)
                })
                .catch((err) => {
                  const errMsg = err != null && 'data' in err ? err.data : {}
                  const msg = errMsg?.detail ?? 'Some error occurred'

                  errorToast(`${msg}`)
                })
            }}
          >
            Start Run
          </Button>
        )}
      </Box>
    </Box>
  )
}

export default OutputTab
