/* eslint-disable */
import React from 'react'

export interface IconProps {
  color: string
}

export const SBHomeIcon: React.FC<IconProps> = ({ color }) => {
  return (
    <svg
      width="20"
      height="20"
      viewBox="0 0 20 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M12.5 17.5H7.5V12.5C7.5 11.0833 8.58333 10 10 10C11.4167 10 12.5 11.0833 12.5 12.5V17.5Z"
        stroke={color}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M10.0002 2.5L16.6668 8.33333V15.8333C16.6668 16.75 15.9168 17.5 15.0002 17.5H5.00016C4.0835 17.5 3.3335 16.75 3.3335 15.8333V8.33333L10.0002 2.5Z"
        stroke={color}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  )
}

export const SBImportIcon: React.FC<IconProps> = ({ color }) => {
  return (
    <svg
      width="20"
      height="20"
      viewBox="0 0 20 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M2.5 14.1665V15.8332C2.5 16.7498 3.25 17.4998 4.16667 17.4998H15.8333C16.75 17.4998 17.5 16.7498 17.5 15.8332V14.1665"
        stroke={color}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M10 12.5V2.5L13.5 6"
        stroke={color}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M10 2.5L6.5 6"
        stroke={color}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  )
}

export const SBReportIcon: React.FC<IconProps> = ({ color }) => {
  return (
    <svg
      width="20"
      height="20"
      viewBox="0 0 20 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M17.5 5.83333L17.5 14.1667C17.5 16 16 17.5 14.1667 17.5L5.83333 17.5C4 17.5 2.5 16 2.5 14.1667L2.5 5.83333C2.5 4 4 2.5 5.83333 2.5L14.1667 2.5C16 2.5 17.5 4 17.5 5.83333Z"
        stroke={color}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M11.6668 6.25L8.3335 6.25"
        stroke={color}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M12.5 13.75L7.5 13.75"
        stroke={color}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M14.1668 10L5.8335 10"
        stroke={color}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  )
}

export const SBInsightIcon: React.FC<IconProps> = ({ color }) => {
  return (
    <svg
      width="20"
      height="20"
      viewBox="0 0 20 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M10 2.5V5.83333"
        stroke={color}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M10 14.1665V17.4998"
        stroke={color}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M2.5 10H5.83333"
        stroke={color}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M14.1665 10H17.4998"
        stroke={color}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M15.3332 4.6665L12.9165 7.08317"
        stroke={color}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M7.08317 12.9165L4.6665 15.3332"
        stroke={color}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M4.6665 4.6665L7.08317 7.08317"
        stroke={color}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M12.9165 12.9165L15.3332 15.3332"
        stroke={color}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M9.99984 10.8332C10.4601 10.8332 10.8332 10.4601 10.8332 9.99984C10.8332 9.5396 10.4601 9.1665 9.99984 9.1665C9.5396 9.1665 9.1665 9.5396 9.1665 9.99984C9.1665 10.4601 9.5396 10.8332 9.99984 10.8332Z"
        stroke={color}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  )
}

export const SBSettingsIcon: React.FC<IconProps> = ({ color }) => {
  return (
    <svg
      width="20"
      height="20"
      viewBox="0 0 20 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M16.0001 10C16.0001 8.75 17.0835 7.25 16.5001 6.25C15.9168 5.25 14.0001 5.41667 13.0001 4.83333C12.0001 4.25 11.2501 2.5 10.0001 2.5C8.75012 2.5 8.00012 4.25 7.00012 4.83333C6.00012 5.41667 4.08345 5.25 3.50012 6.25C2.91679 7.25 4.00012 8.75 4.00012 10C4.00012 11.25 2.91679 12.75 3.50012 13.75C4.08345 14.75 6.00012 14.5833 7.00012 15.1667C8.00012 15.75 8.75012 17.5 10.0001 17.5C11.2501 17.5 12.0001 15.75 13.0001 15.1667C14.0001 14.5833 15.9168 14.75 16.5001 13.75C17.0835 12.75 16.0001 11.25 16.0001 10Z"
        stroke={color}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M10 12.5C11.3807 12.5 12.5 11.3807 12.5 10C12.5 8.61929 11.3807 7.5 10 7.5C8.61929 7.5 7.5 8.61929 7.5 10C7.5 11.3807 8.61929 12.5 10 12.5Z"
        stroke={color}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  )
}
