import React from 'react'
import ReactDOM from 'react-dom/client'
import './index.css'
import App from './App'
import reportWebVitals from './reportWebVitals'
import { BrowserRouter } from 'react-router-dom'
import '@fontsource/spline-sans/300.css'
import '@fontsource/spline-sans/400.css'
import '@fontsource/spline-sans/500.css'
import '@fontsource/spline-sans/700.css'
import 'react-toastify/dist/ReactToastify.css'
import 'react-responsive-carousel/lib/styles/carousel.min.css' // requires a loader
import { CssBaseline, ThemeProvider } from '@mui/material'
import { Provider } from 'react-redux'
import theme from './theme'
import { store } from './store/store'
import { ToastContainer } from 'react-toastify'
import 'cesium/Build/CesiumUnminified/Widgets/widgets.css'

const isDev = process.env.REACT_APP_IS_DEV === 'true'
const root = ReactDOM.createRoot(
  // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
  document.getElementById('root')!
)
root.render(
  isDev ? (
    <React.StrictMode>
      <BrowserRouter>
        <ThemeProvider theme={theme}>
          <CssBaseline />
          <Provider store={store}>
            <App />
            <ToastContainer />
          </Provider>
        </ThemeProvider>
      </BrowserRouter>
    </React.StrictMode>
  ) : (
    <BrowserRouter>
      <ThemeProvider theme={theme}>
        <CssBaseline />
        <Provider store={store}>
          <App />
          <ToastContainer />
        </Provider>
      </ThemeProvider>
    </BrowserRouter>
  )
)

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals()
